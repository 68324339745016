import React, { Component } from 'react';
import 'antd/dist/antd.min.css'; // or 'antd/dist/antd.less'
import { Provider } from 'react-redux';
import configureStore from './redux/configStore';
import './App.css';
import Layout from './pages/cores/layout';
const reduxStore = configureStore();

interface Props {
  showLoading?: any
}
interface State {

}

class App extends Component<Props, State> {
    render() {
        return (
            <Provider store={reduxStore as any}>
                <Layout />
            </Provider >
        );
    }
}

export default App;
