import React, { useEffect, useState } from 'react';
import { Typography, notification, Button, Modal, Table, Tag } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../redux/modules/course';

import DrawerForm from '../../components/basic/drawer';
import GroupdForm from './components/groupForm';
import { FormattedMessage, useIntl } from 'react-intl';
import 'antd/dist/antd.min.css';
import Common from '../../lib/common';
import CONSTANTS from '../../lib/constants';
// import Group from 'antd/lib/input/Group';
import APIServices from '../../lib/APIServices';

const { ACTION_KEY } = CONSTANTS;
const { getAllLesson } = actions;
const { Title } = Typography;
const { confirm } = Modal;
const { getPageTitleName } = Common;

interface Pagination {
    current: number
    pageSize: number
    showSizeChanger?: boolean
    total?: number
}
interface DefaultRootState {
    user: {
        userActions: Array<number>
    }
    course: {
        allLesson: Array<any>
        words: Array<any>
    }
}

interface DrawerInfo {
    // isCreate: boolean
    title: any,
    type: string
    initialValues?: any | undefined
}

interface Props {
    courseId: string
}

const Groups = (props: Props) => {
    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const [drawer, setDrawer] = useState<DrawerInfo>({
        // isCreate: true,
        title: '',
        type: '',
    });
    const [visible, setVisible] = useState<boolean>(false);
    const [groups, setGroups] = useState<Array<any>>();
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();
    const { userActions, allLesson } = useSelector((state: DefaultRootState) => ({
        userActions: state.user.userActions,
        allLesson: state.course.allLesson,
    }));
    const dispatch = useDispatch();

    // config table title
    const titleData = ['lessonName', 'deleteTip', 'action'];
    const titleName = getPageTitleName(titleData, intl.formatMessage);

    const refreshList = async () => {
        // dispatch(getAllWord());
        const { data } = await APIServices.getAllGroup();
        setGroups(data);
    };

    const showDrawer = (item?: { _id: string }) => {
        const data = item && item._id
            ? {
                title: 'Update S',
                type: 'update',
                initialValues: item,
            }
            : {
                title: 'Create S',
                type: 'create',
            };
        setDrawer(data);
        setVisible(true);
    };

    const deleteList = async (id: string) => {
        // dispatch(deleteWord(id));
        await APIServices.deleteGroup(id);
        notification.success({
            message: 'Delete Success',
            description: '删除成功',
            placement: 'topRight',
        });
        refreshList();
    };

    const onClose = () => {
        setDrawer({ ...drawer, initialValues: '' });
        setVisible(false);
    };

    const onSubmit = async (values: any) => {
        if (values._id) {
            // dispatch(updateWord(values));
            await APIServices.updateGroup(values._id, values);
        } else {
            // dispatch(createWord(values));
            await APIServices.createGroup(values);
        }
        onClose();
        refreshList();
    };

    const setPaginationParams = (paginationInfo: any) => {
        setPagination(paginationInfo);
    };

    const columns = [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '序号',
            dataIndex: 'order',
            key: 'order',
        },
        {
            title: '分类',
            dataIndex: 'type',
            key: 'type',
            // eslint-disable-next-line react/display-name
            render: (text: {}, formValues: any) => <Tag key={formValues._id} color='success'>{formValues.type ? '体验课' : '普通课'}</Tag>,
        },
        {
            title: '可购买',
            dataIndex: 'visible',
            render: (text: {}, formValues: any) => formValues.visible ? '是' : '否',
        },
        {
            title: '操作',
            key: 'action',
            // fixed: 'right',
            // eslint-disable-next-line react/display-name
            render: (text: {}, formValues: { order: number, _id: string }) =>
                <span>
                    <Button
                        icon={<EditOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.EDIT)}
                        onClick={() => showDrawer(formValues)}
                    ></Button>
                    <Button
                        icon={<DeleteOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.DELETE)}
                        onClick={() => {
                            confirm({
                                title: `Are you sure you want to delete ${formValues.order}?`,
                                icon: <ExclamationCircleOutlined />,
                                okType: 'danger',
                                onOk() {
                                    deleteList(formValues._id);
                                },
                            });
                        }}
                    >
                    </Button>
                </span>,
        },
    ];

    useEffect(() => {
        if (!allLesson.length) {
            dispatch(getAllLesson({ page: 1, pageSize: 100 }));
        }
        refreshList();
    }, [allLesson, pagination.current, pagination.pageSize]);

    return (
        <div>
            <Title>S级别</Title>
            <Button
                shape='circle'
                size='large'
                icon={<PlusOutlined />}
                onClick={() => showDrawer()}
                type='primary'
                disabled={!userActions.includes(ACTION_KEY.CREATE)}
                className='plusIcon'
            >
            </Button>
            <Table
                rowKey={(data: { _id: string }) => data._id}
                columns={columns as Array<object>}
                dataSource={groups as Array<{ _id: string }>}
                pagination={pagination}
                onChange={paginationInfo => setPaginationParams(paginationInfo)}
            />

            {
                visible &&
                <DrawerForm
                    {...drawer}
                    visible
                    onClose={onClose}
                >
                    <GroupdForm
                        onClose={onClose}
                        initialValues={drawer.initialValues}
                        onSubmit={onSubmit}
                    />
                </DrawerForm>
            }
        </div>
    );
};

export default Groups;
