import React, { Component } from 'react';
import {
    Form,
    Input,
    Tooltip,
    Select,
    Checkbox,
    Button,
    Row,
    Col,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { actions as authActions } from '../../../redux/modules/auth';
import APIServices from '../../../lib/APIServices';
import config from '../../../config/config';
import '../styles/login.css';
const { phoneSignUp } = authActions;
const { Option } = Select;

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

interface UserInfo {
    auth: {
        userInfo: {
            userId: string
        }
    }
}

const mapStateToProps = (state: UserInfo) => ({
    userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {
    phoneSignUp,
};
interface Props {
    history: Array<string>
    phoneSignUp: (data: object) => void
}
interface State {
    email?: string
}
class Register extends Component<Props, State>{
    onFinish = (values: object) => {
        this.props.phoneSignUp({
            ...values,
            'client_id': config.client_id,
            'client_secret': config.client_secret,
        });
        this.props.history.push('/login');
    };

    prefixSelector = (
        <Form.Item name='prefix' noStyle>
            <Select>
                <Option value='86'>+86</Option>
                <Option value='87'>+87</Option>
            </Select>
        </Form.Item>
    );

    getCaptcha = () => {
        APIServices.getCaptcha(this.state);
    }

    onChange = (changedFields: any) => {
        if (changedFields[0] && !changedFields[0].errors.length && changedFields[0].name.includes('email')) {
            this.setState({
                email: changedFields[0].value,
            });
        }
    }

    render() {
        return (
            <div className='login-bg'>
                <div className='bg-box'></div>
                <div className='form-box'>
                    <Form
                        name='register'
                        onFinish={this.onFinish}
                        onFieldsChange={(changedFields, allFields) => {
                            this.onChange(changedFields);
                        }}
                        initialValues={{
                            prefix: '86',
                        }}
                        scrollToFirstError
                    >
                        <Form.Item
                            name='email'
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your E-mail!',
                                },
                            ]}
                        >
                            <Input placeholder='E-mail' />
                        </Form.Item>

                        <Form.Item
                            name='password'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password placeholder='Password' />
                        </Form.Item>

                        <Form.Item
                            name='confirmPassword'
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder='Confirm Password' />
                        </Form.Item>

                        <Form.Item
                            name='nickname'
                            rules={[{ required: true, message: 'Please input your nickname!', whitespace: true }]}
                        >
                            <Input
                                prefix={
                                    <span>
                                        <Tooltip title='What do you want others to call you?'>
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </span>
                                }
                                placeholder='Nickname'
                            />
                        </Form.Item>

                        <Form.Item
                            name='phone'
                            rules={[{ required: true, message: 'Please input your phone number!' }]}
                        >
                            <Input addonBefore={this.prefixSelector} placeholder='Phone Number' />
                        </Form.Item>

                        <Form.Item extra='We must make sure that your are a human.'>
                            <Row gutter={8}>
                                <Col span={12}>
                                    <Form.Item
                                        name='code'
                                        noStyle
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input the captcha you got!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (getFieldValue('email')) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject('Please enter E-mail first!');
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Button onClick={this.getCaptcha}>Get captcha</Button>
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item name='agreement' valuePropName='checked'>
                            <Checkbox>
                                I have read the <a href=''>agreement</a>
                            </Checkbox>
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type='primary' htmlType='submit'>
                                Register
                            </Button>
                        </Form.Item>
                    </Form >
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
