import React from 'react';
import 'antd/dist/antd.min.css';
import { connect } from 'react-redux';
import { actions } from '../../redux/modules/user';
import { Row, Col, notification } from 'antd';
import UserBasicInfo from './components/userBasicInfo';
import UserDetailInfo from './components/userDetailInfo';

import { UserProfileModel, UserStudyTimeModel } from '../../models/user';

import './styles/index.css';

const { getUserProfile, getUserStudyTime, updateUser } = actions;

interface Props {
    match: {
        params: {
            userId: string
        }
    }
    userStudyTime: UserStudyTimeModel
    userProfile: UserProfileModel
    getUserProfile: (id: string) => object
    getUserStudyTime: (userId: string) => object
    edit: (values: UserProfileModel) => object
    updateUser: (values: UserProfileModel) => void
}

const mapStateToProps = (state: any) => ({
    userProfile: state.user.userProfile,
    userStudyTime: state.user.userStudyTime,
});

const mapDispatchToProps = {
    getUserProfile,
    getUserStudyTime,
    updateUser,
};

class UserDetail extends React.Component<Props> {
    userId = this.props.match.params.userId;

    getUserInfo = () => {
        this.props.getUserProfile(this.userId);
        this.props.getUserStudyTime(this.userId);
    }

    edit = async (values: UserProfileModel) => {
        await this.props.updateUser(values);
        notification.success({
            message: 'Delete Success',
            description: '更新成功',
            placement: 'topRight',
        });
    }

    async componentDidMount() {
        this.getUserInfo();
    }

    render() {
        return (
            <Row>
                <Col span={1} />
                <Col span={6}>
                    {
                        this.props.userProfile.user &&
                        <UserBasicInfo
                            userProfile={this.props.userProfile}
                            edit={this.edit} />

                    }
                </Col>
                <Col span={1} />
                <Col span={15} className='text-left'>
                    <UserDetailInfo
                        id={this.props.match.params.userId}
                        userStudyTime={this.props.userStudyTime}
                    />
                </Col>
                <Col span={1} />
            </Row>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
