export default {
    // header
    webName: '妈妈美语',
    setting: '个人设置',
    logout: '退出登录',

    // public
    action: '操作',
    cancel: '取消',
    submit: '提交',
    create: '创建',
    update: '更新',
    deleteTip: '你确定删除',
    deleteSuccess: '删除成功',

    // menu tab
    Books: '书本',
    Course: '单元',
    Client: '客户端',
    User: '用户',
    Mall: '商城',
    Order: '订单',
    Role: '角色',
    Menu: '菜单',
    Action: '操作',

    // lesson
    lessonModuleName: '单元',
    lessonName: '单元名',
    create: '新建单元',
    updateLesson: '更新单元',
    lessonNameRequired: '单元名字不能为空',
    lessonNamePlaceholder: '请输入单元名字',
    price: '价钱',
    pricePlaceholder: '请输入价钱',
    category: '分类',
    categoryPlaceholder: '请输入分类',
    author: '作者',
    authorPlaceholder: '请输入作者',

    // section
    sectionModuleName: '课程',

    // course
    courseModules: '单元',
    selectBook: '请选择单元',
    createCourse: '新建单元',
    updateCourse: '更新单元',
    courseTitle: '单元标题',
    courseTitlePlaceholder: '请输入单元标题',
    courseTip: '本书没有相关单元',

    // client
    clientModuleName: '客户端',
    clientName: '名称',
    clientType: '类型',
    clientSecret: '密钥',
    create: '新建客户端',
    updateClient: '更新客户端',
    clientNamePlaceholder: '请输入客户端名称',
    clientTypePlaceholder: '请输入客户端类型',
    clientNameRequired: '客户端名称不能为空',
    clientId: '客户端 ID',
    clientIdPlaceholder: '请输入客户端ID',
    clientIdRequired: '客户端ID不能为空',

    // name
    nameModuleName: '名字',
    englishName: '英文名',
    englishCode: '英文名编码',
    translation: '译文',

    // user
    userName: '用户名',
    email: '邮箱',
    phone: '电话',
    nickname: '昵称',
    gender: '性别',
    secret: '保密',
    man: '男人',
    woman: '女人',
    birthday: '生日',
    location: '地区',
    studyReport: '学习报告',
    order: '我的订单',
    address: '我的地址',

    // mall
    mallModuleName: '商城',
    create: '新建商品',
    updateGood: '更新商品',
    goodTitle: '商品名字',
    goodTitlePlaceholder: '请输入商品名字',
    actualPrice: '商品成交价',
    actualPricePlaceholder: '请输入商品成交价',
    originalPrice: '商品原价',
    originalPricePlaceholder: '请输入商品原价',
    commodityKind: '货品类别',
    commodityKindPlaceholder: '请输入货品类别',
    commodityItem: '货品名称',
    commodityItemPlaceholder: '请输入货品名称',
    goodImage: '商品图片',
    bigImage: '大图',
    smallImage: '小图',
    coverImage: '封面',
    summary: '简介',
    summaryPlaceholder: '请输入简介',
    detail: '详情',
    detailPlaceholder: '请输入详情',

    // order
    orderModuleName: '订单',
    updateOrder: '更新订单',
    orderId: '订单号',
    orderPrice: '价钱',
    orderNumber: '数量',

    // role
    roleModuleName: '角色',
    roleNumber: '角色编号',
    roleName: '角色名称',
    roleNamePlaceholder: '请输入角色名称',
    rolePower: '权限字符',
    rolePowerPlaceholder: '请输入权限字符',
    roleStatu: '状态',
    createTime: '创建时间',
    create: '创建角色',
    updateRole: '更新角色',
    disable: '停用',
    normal: '正常',
    menuPower: '菜单权限',
    remark: '备注',
    remarkPlaceholder: '请输入备注',

    // menu
    menuModuleName: '菜单',
    menuName: '菜单名称',
    menuIcon: '菜单图标',
    menuVisible: '可见',
    create: '新建菜单',
    updateMenu: '更新菜单',
    topMenu: '上级菜单',
    topMenuPlaceholder: '请输入上级菜单',
    menuType: '菜单类型',
    menuIconPlaceholder: '请选择图标',
    menuRouterName: '路由名称',
    menuRouterNamePlaceholder: '请输入路由名称',
    menuComponentPath: '组件路径',
    menuComponentPathPlaceholder: '请输入组件路径',
    menuComponentRouter: '路由地址',
    menuComponentRouterPlaceholder: '请输入路由地址',
    menuStatus: '菜单状态',
    show: '显示',
    hidden: '隐藏',

    // new User
    userModule: '用户',
    create: '新建用户',
    updateUser: '更新用户',

    // action
    actionModuleName: '操作',
    create: '新建操作',
    updateAction: '更新操作',
    // resource
    resourceModuleName: '资源',
    create: '新建资源',
    updateResource: '更新资源',

    // file
    fileModuleName: '文件',
};