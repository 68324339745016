import React, { Component } from 'react';
import 'antd/dist/antd.min.css';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { actions } from '../../redux/modules/user';
import UserList from './components/list';
import DrawerForm from '../../components/basic/drawer';
import UserForm from './components/userForm';

import { UserModel, UserProfileModel } from '../../models/user';

import './styles/index.css';

const { createUser, getUsers, deleteUser, updateUser } = actions;

interface Columns {
    _id: string
    title: string
    key: string
    dataIndex?: string
    width?: number
    fixed?: string
    render: (user: UserModel) => void
}

interface Props {
    history: Array<string>
    users: Columns[]
    getUsers: (pagination: { page: number, pageSize: number }) => void
    createUser: (data: UserModel) => void
    deleteUser: (id: string) => void
    updateUser: (user: UserProfileModel) => void
}
interface State {
    visible?: boolean
    initialValues?: UserModel
    detail?: boolean
    _id?: string
}

const mapStateToProps = (state: any) => ({
    users: state.user.users,
});

const mapDispatchToProps = {
    createUser,
    getUsers,
    deleteUser,
    updateUser,
};

class User extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    async componentDidMount() {
        this.refreshList();
    }

    refreshList = async () => {
        await this.props.getUsers({ page: 1, pageSize: 1000 });
    };

    deleteList = async (id: string) => {
        await this.props.deleteUser(id);
        notification.success({
            message: 'Delete Success',
            description: '删除成功',
            placement: 'topRight',
        });
        this.refreshList();
    }

    showDrawer = (e: React.MouseEvent<HTMLElement, MouseEvent>, item?: UserModel) => {
        e.stopPropagation();
        const data = item && item._id
            ? {
                title: 'Update User',
                type: 'update',
                visible: true,
                initialValues: item,
            }
            : {
                title: 'Create User',
                type: 'create',
                visible: true,
            };
        this.setState(data);
    }

    onClose = () => {
        this.setState({ visible: false, initialValues: undefined });
    }

    onSubmit = async (values: UserModel) => {
        await this.props.createUser(values);
        this.onClose();
        this.refreshList();
    }


    goToDetail = (_id: string) => {
        this.props.history.push(`/home/user/${_id}`);
    }

    render() {
        if (this.state.visible) {
            return (
                <DrawerForm
                    {...this.state}
                    onClose={this.onClose}
                >
                    <UserForm
                        onClose={this.onClose}
                        initialValues={this.state.initialValues}
                        onSubmit={this.onSubmit}
                    />
                </DrawerForm>
            );
        }
        return (
            <UserList
                dataSource={this.props.users}
                deleteList={this.deleteList}
                goToDetail={this.goToDetail}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(User);
