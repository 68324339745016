import React, { Component, MouseEvent } from 'react';
import 'antd/dist/antd.min.css';
import { connect } from 'react-redux';
import { actions } from '../../redux/modules/order';
import OrderList from './components/orderList';
import DrawerForm from '../../components/basic/drawer';
import OrderForm from './components/orderForm';
import { Typography, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { OrderModel } from '../../models/order';

import './styles/index.css';

const { Title } = Typography;
const { getOrders, setRefund, updateOrder, deleteOrder } = actions;

interface Props {
    orders: Array<OrderModel>
    history?: any
    currentOrder: OrderModel
    getOrders: () => void
    createOrder: (data: OrderModel) => void
    deleteOrder: (id: string) => void
    updateOrder: (data: OrderModel) => void
}
interface State {
    orders?: Array<OrderModel>
    visible?: boolean
    initialValues?: OrderModel | undefined
}

interface Order {
    order: {
        orders: Array<OrderModel>
        currentOrder: OrderModel
    }
}

const mapStateToProps = (state: Order) => ({
    orders: state.order.orders,
    currentOrder: state.order.currentOrder,
});

const mapDispatchToProps = {
    setRefund,
    getOrders,
    updateOrder,
    deleteOrder,
};

class Order extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            orders: [],
        };
    }

    refreshList = async () => {
        await this.props.getOrders();
        this.setState({
            orders: this.props.orders,
        });
    };

    showDrawer = (e: MouseEvent, item?: OrderModel) => {
        e.stopPropagation();
        const data = item && item._id
            ? {
                title: <FormattedMessage id='updateOrder' />,
                type: 'update',
                visible: true,
                initialValues: item,
            }
            : {
                title: 'Create Order',
                type: 'create',
                visible: true,
            };
        this.setState(data);
    }

    onClose = () => {
        this.setState({ visible: false, initialValues: undefined });
    }

    onSubmit = async (values: OrderModel) => {
        values._id && await this.props.updateOrder(values);

        this.onClose();
        this.refreshList();
    }

    deleteList = async (id: string) => {
        await this.props.deleteOrder(id);
        notification.success({
            message: <FormattedMessage id='deleteSuccess' />,
            placement: 'topRight',
        });
        this.refreshList();
    }

    async componentDidMount() {
        this.refreshList();
    }

    goToDetail = (_id: string) => {
        this.props.history.push(`/home/order/${_id}`);
    }

    render() {
        return (
            <div>
                <Title> <FormattedMessage id='orderModuleName' /></Title>
                <OrderList
                    dataSource={this.props.orders}
                    deleteList={() => this.deleteList}
                    goToDetail={this.goToDetail}
                    edit={(e: MouseEvent, order: OrderModel) => this.showDrawer(e, order)}
                />

                {
                    this.state.visible &&
                    <DrawerForm
                        {...this.state}
                        onClose={this.onClose}
                    >
                        <OrderForm
                            onClose={this.onClose}
                            initialValues={this.state.initialValues}
                            onSubmit={this.onSubmit}
                        />
                    </DrawerForm>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);
