import React, { useEffect, useState } from 'react';
import { Button, Select, Tag } from 'antd';

const { Option } = Select;
interface Props {
    title?: string
    selectOptions: Array<any>,
    onSelect: (selectIds: Array<any>) => void
    allOptions: Array<any> | []
    type: string
}


const DynamicTag = (props: Props) => {
    const { selectOptions, allOptions } = props;
    const [selectedItems, setSelectedItems] = useState<Array<any>>(selectOptions || []);
    const [currentItems, setCurrentItems] = useState<Array<any>>([]);
    const [notSelectItems, setNoSelectItems] = useState<Array<any>>([]);
    const [wordSelectVisible, setWordSelectVisible] = useState(false);


    const onSelectOptions = (selectIds: any) => {
        const newSelectedItems = selectedItems.concat(selectIds);
        const newSelectedItemsIds: Array<any> = newSelectedItems.map(item => item.value || item._id);
        setCurrentItems(selectIds);
        const filteredOptions = allOptions.filter(o => ![...new Set(newSelectedItemsIds)].includes(o._id));
        setNoSelectItems(filteredOptions);
    };

    const openSelect = () => {
        setWordSelectVisible(true);
        setCurrentItems([]);
    };

    const onCloseTag = (item: any) => {
        setWordSelectVisible(false);
        const newSelectedItems = selectedItems.filter((o: any) => o && o._id !== item._id);
        setSelectedItems(newSelectedItems);
        setNoSelectItems([...notSelectItems, item]);
    };

    const onBlurSelect = () => {
        setWordSelectVisible(false);
        const newSelectedItems = selectedItems.concat(currentItems);
        const newSelectedItemsIds = newSelectedItems.map((select: any) => select.value || select._id);
        const newSelectedOptions = allOptions.filter(option => [... new Set(newSelectedItemsIds)].includes(option._id));
        setSelectedItems(newSelectedOptions);
    };

    useEffect(() => {
        // get last select id
        if (selectedItems) {
            const selectedIds = selectedItems.map(item => item._id);
            props.onSelect(selectedIds);
        }
    }, [selectedItems]);

    const formatData = (data: Array<any>) => {
        const selectedIds = selectOptions.map((select: any) => select.value || select._id);
        const filteredOptions = data.filter(o => ![...new Set(selectedIds)].includes(o._id));
        setNoSelectItems(filteredOptions);
        setSelectedItems(selectOptions);
    };

    useEffect(() => {
        formatData(allOptions);
    }, [selectOptions]);

    return (
        <>
            {
                selectedItems.map((item: any) => <Tag key={item._id} closable onClose={() => onCloseTag(item)}>{item.title || item.content}</Tag>)
            }
            {
                !wordSelectVisible && <Button onClick={openSelect} > + 选择{props.title || '资源'}</Button>
            }
            {
                wordSelectVisible
                &&
                <Select
                    mode='multiple'
                    placeholder='Inserted are removed'
                    // value={currentItemLabel}
                    onChange={onSelectOptions}
                    onBlur={onBlurSelect}
                    style={{ width: '100%' }}
                    labelInValue
                >
                    {notSelectItems.length && notSelectItems.map((item: any) =>
                        <Option key={item._id} value={item._id}>
                            {item.title || item.content}
                        </Option>
                    )}
                </Select>
            }
        </>
    );
};

export default DynamicTag;
