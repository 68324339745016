import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../redux/modules/role';
import { actions as menuAction } from '../../redux/modules/menu';
import DrawerForm from '../../components/basic/drawer';
import RoleForm from './components/roleForm';
import { Typography, notification, Button, Modal, Switch, Table } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { RoleModel } from '../../models/role';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/constants';
import Common from '../../lib/common';
import './styles/index.css';

const { getPageTitleName } = Common;
const { ACTION_KEY } = CONSTANTS;
const { Title } = Typography;
const { confirm } = Modal;
const { getRoles, updateRole, deleteRole } = actions;
const { getMenusData, getMenusDataByID } = menuAction;

interface Pagination {
    current: number
    pageSize: number
    showSizeChanger?: boolean
    total?: number
}
interface DefaultRootState {
    role: {
        roles: Array<any>
    }
    menu: {
        menuData: any
    }
    user: {
        userActions: Array<number>
    }
    auth: {
        loginUser: any
    }
}

interface DrawerInfo {
    isCreate: boolean
    title: any,
    type: string
    roleId?: string
}

const Role = () => {
    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const [drawer, setDrawer] = useState<DrawerInfo>({
        isCreate: true,
        title: '',
        type: '',
    });
    const [visible, setVisible] = useState<boolean>(false);
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();
    const { roles, menuData, userActions, userInfo } = useSelector((state: DefaultRootState) => ({
        roles: state.role.roles,
        menuData: state.menu.menuData,
        userActions: state.user.userActions,
        userInfo: state.auth.loginUser,
    }));
    const dispatch = useDispatch();

    // config table title
    const titleData = ['deleteTip', 'roleNumber', 'roleName', 'roleNamePlaceholder', 'action'];
    const titleName = getPageTitleName(titleData, intl.formatMessage);


    const refreshList = () => {
        dispatch(getRoles({ page: pagination.current, pageSize: pagination.pageSize }));
        dispatch(getMenusData());
        dispatch(getMenusDataByID(userInfo._id));
    };

    const showDrawer = (item?: RoleModel) => {
        const data = item && item._id
            ? {
                title: intl.formatMessage({ id: 'updateRole' }),
                type: 'update',
                roleId: item._id,
                isCreate: false,
            }
            : {
                title: intl.formatMessage({ id: 'createRole' }),
                type: 'create',

                isCreate: true,
            };
        setDrawer(data);
        setVisible(true);
    };

    const onClose = () => {
        setDrawer({ ...drawer, roleId: '' });
        setVisible(false);
    };

    const onSubmit = async (values: RoleModel, isCreate: boolean) => {
        // create
        if (isCreate) {
            const role = (await APIServices.createRole(values)).data;
            // get role id, roleMenu = {menu, role}, create roleMenu
            if (role._id) {
                const menus = values.menus.map((menu: any) => ({
                    role: role._id,
                    menu,
                }));
                const createActions = values.actions.map((action: any) => ({
                    role: role._id,
                    action,
                }));
                // create role menu and role action
                APIServices.createRoleMenu(menus);
                APIServices.createRoleAction(createActions);
            }
        } else {
            // update
            dispatch(updateRole(values));
            // update roleMenu
            const menus = values.menus.map((menu: any) => ({
                role: values._id,
                menu,
            }));
            const updateActions: Array<any> = values.actions.map((action: any) => ({
                role: values._id,
                action,
            }));
            // update role menu and role action
            await APIServices.updateRoleMenu({ _id: values._id, menus });
            await APIServices.updateRoleAction({ _id: values._id, updateActions });
        }
        onClose();
        refreshList();
    };

    const deleteList = (id: string) => {
        dispatch(deleteRole(id));
        notification.success({
            message: 'Delete Success',
            description: '删除成功',
            placement: 'topRight',
        });
        refreshList();
    };

    const changeRoleStatu = (status: number, id?: string) => {
        // change role status api
        if (id) {
            dispatch(updateRole({ status, '_id': id }));
        }
        refreshList();
    };

    const setPaginationParams = (paginationInfo: any) => {
        setPagination(paginationInfo);
    };

    const columns = [
        {
            title: titleName.roleName,
            dataIndex: 'name',
            key: 'name',
        },
        {
            // title: '状态',
            // dataIndex: 'status',
            // key: 'status',
            // // eslint-disable-next-line react/display-name
            // render: (text: {}, formValues: RoleModel) =>
            //     <span>
            //         <Switch
            //             defaultChecked={Boolean(formValues.status)}
            //             disabled={!userActions.includes(ACTION_KEY.EDIT)}
            //             onClick={() => {
            //                 confirm({
            //                     title: `Are you sure you want to change ${formValues.name} status?`,
            //                     icon: <ExclamationCircleOutlined />,
            //                     okType: 'danger',
            //                     onOk() {
            //                         changeRoleStatu(formValues.status, formValues._id);
            //                     },
            //                     onCancel() {
            //                         changeRoleStatu(formValues.status);
            //                     },
            //                 });
            //             }} />
            //     </span>,
        },
        // {
        //     title: '创建时间',
        //     dataIndex: 'createdAt',
        //     key: 'createdAt',
        // },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            // eslint-disable-next-line react/display-name
            render: (text: {}, formValues: RoleModel) =>
                <span>
                    <Button
                        icon={<EditOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.EDIT)}
                        onClick={() => showDrawer(formValues)}
                    ></Button>
                    <Button
                        icon={<DeleteOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.DELETE)}
                        onClick={() => {
                            confirm({
                                title: `Are you sure you want to delete ${formValues.name}?`,
                                icon: <ExclamationCircleOutlined />,
                                okType: 'danger',
                                onOk() {
                                    deleteList(formValues._id);
                                },
                            });
                        }}
                    >
                    </Button>
                </span>,
        },
    ];

    useEffect(() => {
        refreshList();
    }, [pagination.current, pagination.pageSize]);
    return (
        <>
            <Title><FormattedMessage id='roleModuleName' /></Title>
            <Button
                shape='circle'
                size='large'
                icon={<PlusOutlined />}
                onClick={() => showDrawer()}
                type='primary'
                disabled={!userActions.includes(ACTION_KEY.CREATE)}
                className='plusIcon'
            >
            </Button>
            <Table
                // className='orderTable'
                rowKey={(data: { _id: string }) => data._id}
                columns={columns as Array<object>}
                dataSource={roles as Array<{ _id: string }>}
                pagination={pagination}
                onChange={paginationInfo => setPaginationParams(paginationInfo)}
            />
            {
                visible &&
                <DrawerForm
                    {...drawer}
                    visible
                    onClose={onClose}
                >
                    <RoleForm
                        onClose={onClose}
                        isCreate={drawer.isCreate}
                        roleId={drawer.roleId}
                        onSubmit={onSubmit}
                        treeData={menuData}
                    />
                </DrawerForm>
            }
        </>
    );
};

export default Role;
