import { createAction, handleActions } from 'redux-actions';
import { MallModel } from '../../models/mall';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/constants';
const {
    CREATE_GOOD,
    UPDATE_GOOD,
    DELETE_GOOD,
    ALL_GOOD,
    MALL_SELECT_GOOD,
    USER_ALL_ORDERS,
    USER_SELECT_ORDER,
} = CONSTANTS;

// initialState
const initialState = {
    goods: [],
    allOrders: [],
    currentOrder: {},
};

interface Action {
    type: any
    payload: any
}


export default handleActions({
    [ALL_GOOD](state, action: Action) {
        return {
            ...state,
            goods: action.payload,
        };
    },
    [MALL_SELECT_GOOD](state, action) {
        const current = state.goods.find((item: MallModel) => item._id === action.payload);
        return {
            ...state,
            current,
        };
    },
    [USER_ALL_ORDERS](state, action) {
        return {
            ...state,
            allOrders: action.payload,
        };
    },
    [USER_SELECT_ORDER](state, action) {
        const currentOrder = state.allOrders.find((item: any) => item._id === action.payload);
        return {
            ...state,
            currentOrder,
        };
    },
}, initialState);

/**
 * action creators
 * */
export const actions = {
    // good
    createGood: createAction(CREATE_GOOD, (good: MallModel) => APIServices.createGood(good)),
    updateGood: createAction(UPDATE_GOOD, async (good: MallModel) => (await APIServices.updateGood(good)).data),
    deleteGood: createAction(DELETE_GOOD, async (id: string) => await APIServices.deleteGood(id)),
    // getGoods: createAction(ALL_GOOD, async () => (await APIServices.getGoods()).data),
    selectGood: createAction(MALL_SELECT_GOOD),
    getAllOrders: createAction(USER_ALL_ORDERS, async (userPhone: object) => (await APIServices.getAllOrders(userPhone)).data),
    selectOrder: createAction(USER_SELECT_ORDER),
};
