import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Input, Button, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import APIServices from '../../../lib/APIServices';
import Common from '../../../lib/common';
import { ActionModel, AllResourcesModel } from '../../../models/action';
import '../styles/index.css';
const { getPageTitleName } = Common;
const { Option } = Select;
interface Props {
    actionId?: string
    isCreate: boolean
    onSubmit: (data: any, isCreate: boolean) => void
    onClose: () => void
}

const ActionForm = (props: Props) => {
    const [form] = Form.useForm();
    const [resources, setResources] = useState<Array<any>>([]);
    const [options, setOptions] = useState<Array<any>>([]);
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();

    const titleData = ['topMenu', 'topMenuPlaceholder', 'menuName', 'menuIcon', 'menuType', 'menuIconPlaceholder', 'menuRouterName', 'menuRouterNamePlaceholder', 'menuComponentPath', 'menuComponentPathPlaceholder', 'menuComponentRouter', 'menuComponentRouterPlaceholder', 'menustatu', 'show', 'hidden', 'cancel', 'submit'];
    const titleName = getPageTitleName(titleData, intl.formatMessage);

    const onFinish = (values: any) => {
        // create action and resource
        values.resources = values.resourcesData;
        if (!props.isCreate) {
            values._id = props.actionId;
        }
        props.onSubmit(values, props.isCreate);
    };

    const getAllResource = async () => {
        const initResources: Array<AllResourcesModel> = (await APIServices.getAllResource()).data;
        setResources(initResources);
    };

    const onSelectMethod = (params: string) => {
        const selectMethod: Array<any> = resources.filter((item: { method: string; path: string, _id: string }) => item.method === params && item);
        setOptions(selectMethod);
    };

    const handleClose = () => {
        props.onClose();
    };

    const onFieldsChange = async () => {
        if (!props.isCreate && props.actionId) {
            const newData: ActionModel = (await APIServices.getActionDetail(props.actionId)).data;
            const resourcesData = newData.resources;
            form.setFieldsValue({ ...newData, resourcesData });
        } else {
            form.resetFields();
        }
    };
    useEffect(() => {
        onFieldsChange();
        getAllResource();
    }, []);
    return (
        <Form
            layout='vertical'
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='name'
                        label='动作名称:'
                        rules={[{ required: true, message: 'Please enter action code' }]}
                    >
                        <Input placeholder={titleName.menuRouterNamePlaceholder} />

                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='key'
                        label='动作编号:'
                        rules={[{ required: true, message: 'Please enter action name' }]}
                    >
                        <Select placeholder={titleName.menuRouterNamePlaceholder} >
                            <Option value={0}>GET</Option>
                            <Option value={1}>POST</Option>
                            <Option value={2}>DELETE</Option>
                            <Option value={3}>PATCH</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24}>

                    <Form.List name='resourcesData'>
                        {(fields, { add, remove }) =>
                            <div>
                                <Form.Item>
                                    <Button
                                        type='primary'
                                        icon={<PlusOutlined />}
                                        onClick={() => add()}
                                    >
                                        绑定资源
                                    </Button>
                                </Form.Item>
                                <Row gutter={16}>
                                    <Col span={4}>
                                        方法名:
                                    </Col>
                                    <Col span={12}>
                                        路径:
                                    </Col>
                                </Row>
                                {fields.map(field =>
                                    <Row gutter={16} key={field.key} style={{ display: 'flex', marginBottom: 0 }}>
                                        <Col span={4}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'method']}
                                                fieldKey={[field.fieldKey, 'method']}
                                                rules={[{ required: true, message: '方法名不能为空' }]}
                                            >
                                                <Select placeholder='请选择方法' onChange={onSelectMethod}>
                                                    <Option value='GET'>GET</Option>
                                                    <Option value='POST'>POST</Option>
                                                    <Option value='PATCH'>PATCH</Option>
                                                    <Option value='DELETE'>DELETE</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'path']}
                                                fieldKey={[field.fieldKey, 'path']}
                                                rules={[{ required: true, message: '路径不能为空' }]}
                                            >
                                                <Select placeholder='请选择路径'>
                                                    {
                                                        options.map((item: { path: string, _id: string }) => <Option key={item._id} value={item._id}>{item.path}</Option>)
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Button
                                                icon={<MinusCircleOutlined />}
                                                size='large'
                                                type='text'
                                                onClick={() => remove(field.name)}>
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        }
                    </Form.List>
                </Col>

            </Row>


            <Row gutter={16} className='action-btn'>
                <Col span={24} className='text-right'>
                    <Button htmlType='button' onClick={handleClose}>
                        取消
                    </Button>
                    <Button type='primary' htmlType='submit' className='mar-left' >
                        确认
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default ActionForm;
