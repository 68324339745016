import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';

import APIServices from '../../lib/APIServices';

interface IProps {
    userId: string
    getSections: (lesson: { title: string; _id: string; }) => Promise<void>
}

const GroupList = (props: IProps) => {
    const [groups, setGroups] = useState([]);
    const columns = [
        {
            title: '名字',
            dataIndex: 'title',
        },
        {
            title: '类型',
            dataIndex: 'type',
            render: (text: any, formValues: any) => formValues.type === 0 ? '正常课' : '体验课',
        },
        {
            title: '购买',
            dataIndex: 'purchased',
            render: (text: any, formValues: any) => formValues.purchased ? '是' : '否',
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (text: any, formValues: any) => ['null', '未开始', '已开始', '已完成'][formValues.status || 1],
        },
    ];

    useEffect(() => {
        const getGroups = async () => {
            const newGroups = (await APIServices.getGroups(props.userId)).data;
            setGroups(newGroups);

            const { lessons } = newGroups[0];
            if (lessons && lessons.length) {
                props.getSections(lessons[0]);
            }
        };

        getGroups();
    }, []);

    return (
        <div className='userInfo-card'>
            <h1>Group</h1>
            <Table
                columns={columns as Array<object>}
                expandable={{
                    expandRowByClick: true,
                    expandedRowRender: (record: any) => record.lessons.map((lesson: any) => <React.Fragment key={lesson._id}>
                        <p>{lesson.title} -- {['null', '未开始', '已开始', '已完成'][lesson.status]}</p>
                        <Button onClick={() => props.getSections(lesson)}>Section</Button>
                    </React.Fragment>),
                }}
                dataSource={groups.map((group: any) => ({ key: group._id, ...group }))}
            />
        </div>
    );
};

export default GroupList;
