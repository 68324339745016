export default {
    // header
    webName: 'MMMY',
    setting: 'Personal Setting',
    logout: 'Log Out',

    // public
    action: 'Action',
    cancel: 'Cancel',
    submit: 'Submit',
    create: 'Create',
    update: 'Update',
    deleteTip: 'Are you sure to delete',
    deleteSuccess: 'Delete Success',

    // menu tab
    Books: 'Books',
    Course: 'Course',
    Client: 'Client',
    User: 'User',
    Mall: 'Mall',
    Order: 'Order',
    Role: 'Role',
    Menu: 'Menu',
    Action: 'Action',
    // Lesson
    lessonModuleName: 'Unit',
    lessonName: 'Unit Name',
    createLesson: 'Cerate Unit',
    updateLesson: 'Update Unit',
    lessonNamePlaceholder: 'Place Enter Unit Name',
    price: 'Price',
    pricePlaceholder: 'Place Enter Unit Price',
    category: 'Category',
    categoryPlaceholder: 'Place Enter Unit Category',
    author: 'Author',
    authorPlaceholder: 'Place Enter Unit Author',

    // section
    sectionModuleName: 'Lesson',
    selectBook: 'Select Unit',

    // client
    clientModuleName: 'Client',
    clientName: 'Client Name',
    clientType: 'Client Type',
    clientSecret: 'Client Secret',
    createClient: 'Create Client',
    updateClient: 'Update Client',
    clientNamePlaceholder: 'Place Enter Client Name',
    clientTypePlaceholder: 'Place Enter Client Type',
    clientId: 'Client ID',
    clientIdPlaceholder: 'Place Enter Client ID',

    // name
    nameModuleName: 'Name',
    englishName: 'English Name',
    englishCode: 'English Code',
    translation: 'Translation',

    // user
    userName: 'User Name',
    email: 'Email',
    phone: 'Phone',
    nickname: 'Nickname',
    gender: 'Gender',
    secret: 'Secret',
    man: 'Man',
    woman: 'Woman',
    birthday: 'Birthday',
    location: 'Location',
    studyReport: 'Study Report',
    order: 'My Order',
    address: 'My Address',

    // mall
    mallModuleName: 'Goods',
    createGood: 'Create Good',
    updateGood: 'Update Good',
    goodTitle: 'Good Name',
    goodTitlePlaceholder: 'Place Enter Good Name',
    actualPrice: 'Good Actual Price',
    actualPricePlaceholder: 'Place Enter Good Actual Price',
    originalPrice: 'Good Original Price',
    originalPricePlaceholder: 'Place Enter Good Original Price',
    commodityKind: 'Commodity Kind',
    commodityKindPlaceholder: 'Place Enter Commodity Kind',
    commodityItem: 'Commodity Title',
    commodityItemPlaceholder: 'Place Enter Commodity Title',
    goodImage: 'Goood Image',
    bigImage: 'Big',
    smallImage: 'Small',
    coverImage: 'Cover',
    summary: 'Summary',
    summaryPlaceholder: 'Place Enter Good Summary',
    detail: 'Detail',
    detailPlaceholder: 'Place Enter Good Detail',

    // order
    orderModuleName: 'Order',
    updateOrder: 'Update Order',
    orderId: 'ID',
    orderPrice: 'Price',
    orderNumber: 'Number',

    // role
    roleModuleName: 'Role',
    roleNumber: 'Number',
    roleName: 'Name',
    roleNamePlaceholder: 'Place Enter Role Name',
    rolePermissions: 'Permissions',
    rolePermissionsPlaceholder: 'Place Enter Role Permissions',
    roleStatu: 'Statu',
    createTime: 'Create Time',
    createRole: 'Create Role',
    updateRole: 'Update Role',
    disable: 'Disable',
    normal: 'Norma;',
    menuPower: 'Menu Permissions',
    remark: 'Remark',
    remarkPlaceholder: 'Place Enter Role Remark',

    // menu
    menuModuleName: 'Menu',
    menuName: 'Menu Name',
    menuIcon: 'Menu Icon',
    menuVisible: 'Visible',
    createMenu: 'Create Menu',
    updateMenu: 'Update Menu',
    topMenu: 'Parents Menu',
    topMenuPlaceholder: 'Palce Enter Parents Menu',
    menuType: 'Menu Type',
    menuIconPlaceholder: 'Palce Select Menu Icon',
    menuRouterName: 'Router Name',
    menuRouterNamePlaceholder: 'Palce Enter Menu Router Name',
    menuComponentPath: 'Component Path',
    menuComponentPathPlaceholder: 'Palce Enter Component Path',
    menuComponentRouter: 'Component Router',
    menuComponentRouterPlaceholder: 'Palce Enter Component Router',
    menuStatus: 'Menu Status',
    show: 'Show',
    hidden: 'Hidden',

    // new User
    userModule: 'User',
    createUser: 'Create',
    updateUser: 'Update',

    // action
    actionModuleName: 'Action',
    createAction: 'Create',
    updateAction: 'Update',
    // resource
    resourceModuleName: 'Resource',
    createResource: 'Create',
    updateResource: 'Update',

    // file
    fileModuleName: 'File',
};