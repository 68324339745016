import CONFIG from '../config/config';
import axios from '../utils/axiosSetting';
import { MallModel } from '../models/mall';
import { UserModel, UserProfileModel } from '../models/user';
import { ClientModel } from '../models/client';
import { AuthModel } from '../models/auth';
import { CourseModel, WordModel, EditWord, PictureBookModel } from '../models/course';
import { OrderModel } from '../models/order';
import { RoleModel } from '../models/role';
import { MenuModel } from '../models/menu';
import { ActionModel } from '../models/action';

const ClientToken = `Basic ${CONFIG.ClientAuth}`;
const [page, pageSize] = [1, 20];
export default {
    getCaptcha(data: any) {
        return axios.post('/api/v1/admin/auth/code/mail', data);
    },
    // user sign up
    phoneSignUp(data: any) {
        return axios.post('/api/v1/admin/auth/signup/mail', data);
    },
    userSignIn(data: AuthModel) {
        return axios.post('/api/v1/admin/auth/token', data, {
            headers: { Authorization: ClientToken },
        });
    },
    getUserProfile(userId: string) {
        return axios.get(`/api/v1/user/profile/${userId}`);
    },
    createUser(data: any) {
        return axios.post('/api/v1/admin/auth/users', data);
    },
    getUsers(pagination: { page: number, pageSize: number } = { page, pageSize }) {
        return axios.get(`/api/v1/admin/auth/users?page=${pagination.page}&pageSize=${pagination.pageSize}`);
    },
    updateUser(data: UserProfileModel) {
        return axios.put(`/api/v1/user/profile/${data.user._id}`, data);
    },
    deleteUser(id: string) {
        return axios.delete(`/api/v1/admin/auth/users/${id}`);
    },
    updateNewUser(data: UserModel) {
        return axios.patch(`/api/v1/admin/auth/users/${data._id}`, data);
    },
    getNewUserDetail(id: string) {
        return axios.get(`/api/v1/admin/auth/users/${id}`);
    },
    getUsersStats() {
        return axios.get('/api/v1/admin/auth//users/count/stats');
    },

    // get client
    createClient(data: ClientModel) {
        return axios.post('/api/v1/admin/clients', data);
    },
    getClients(pagination: { page: number, pageSize: number } = { page, pageSize }) {
        return axios.get(`/api/v1/admin/clients?page=${pagination.page}&pageSize=${pagination.pageSize}`);
    },
    updateClient(data: ClientModel) {
        return data._id && axios.patch(`/api/v1/admin/clients/${data._id}`, data);
    },
    deleteClient(id: string) {
        return axios.delete(`/api/v1/admin/clients/${id}`);
    },

    // get Books
    // createBook(data: CourseModel) {
    //     return axios.post('/api/v1/admin/allbooks', data);
    // },
    // updateBook(data: CourseModel) {
    //     return data._id && axios.patch(`/api/v1/admin/allbooks/${data._id}`, data);
    // },
    // deleteBook(id: string) {
    //     return axios.delete(`/api/v1/admin/allbooks/${id}`);
    // },
    // getBooks() {
    //     return axios.get('/api/v1/allbooks');
    // },

    // get Units
    createUnit(data: CourseModel) {
        return axios.post('/api/v1/admin/units', data);
    },
    updateUnit(data: CourseModel) {
        return data._id && axios.patch(`/api/v1/admin/units/${data._id}`, data);
    },
    deleteUnit(id: string) {
        return axios.delete(`/api/v1/admin/units/${id}`);
    },
    getUnits(bookId: string) {
        return axios.get(`/api/v1/units?bookId=${bookId}`);
    },

    // get words
    // createWord(data: WordModel) {
    //     return axios.post('/api/v1/admin/unit/arcards', data);
    // },
    // updateWord(data: EditWord) {
    //     return axios.patch(`/api/v1/admin/unit/arcards/${data._id}`, data);
    // },
    // deleteWord(id: string) {
    //     return axios.delete(`/api/v1/admin/unit/arcards/${id}`);
    // },
    // getWords(sectionId: string) {
    //     return axios.get(`/api/v1/admin/words?sectionId=${sectionId}`);
    // },

    // get picturebook
    createPictureBook(data: PictureBookModel) {
        return axios.post('/api/v1/admin/picturebook', data);
    },
    updatePictureBook(data: PictureBookModel) {
        return axios.patch(`/api/v1/admin/picturebook/${data._id}`, data);
    },
    deletePictureBook(id: string) {
        return axios.delete(`/api/v1/admin/picturebook/${id}`);
    },
    getPictureBooks(courseId: string) {
        return axios.get(`/api/v1/admin/picturebook?unitId=${courseId}`);
    },

    // grop
    getAllGroup() {
        return axios.get('/api/v1/admin/groups');
    },
    getGroups(userId: string) {
        return axios.get(`/api/v1/admin/groups?userId=${userId}`);
    },
    getGroup(gropId: string) {
        return axios.get(`/api/v1/admin/groups/${gropId}`);
    },
    createGroup(data: any) {
        return axios.post('/api/v1/admin/groups', data);
    },
    updateGroup(gropId: string, data: any) {
        return axios.patch(`/api/v1/admin/groups/${gropId}`, data);
    },
    deleteGroup(gropId: string) {
        return axios.delete(`/api/v1/admin/groups/${gropId}`);
    },

    // Lesson
    getAllLesson(pagination: { page: number, pageSize: number } = { page, pageSize }) {
        return axios.get(`/api/v1/admin/lessons?pages=${pagination.page}&pageSizes=${pagination.pageSize}`);
    },
    createLesson(data: any) {
        return axios.post('/api/v1/admin/lessons', data);
    },
    multiCreateLessons() {
        return axios.post('/api/v1/admin/lessons/multi');
    },
    updateLesson(data: { _id: string }) {
        return axios.patch(`/api/v1/admin/lessons/${data._id}`, data);
    },
    deleteLesson(lessonId: string) {
        return axios.delete(`/api/v1/admin/lessons/${lessonId}`);
    },

    // section
    getLessonSections(lessonId: string) {
        return axios.get(`/api/v1/admin/lessons/${lessonId}/sections`);
    },
    createSection(lessonId: string, data: any) {
        return axios.post(`/api/v1/admin/lessons/${lessonId}/sections`, data);
    },
    multiCreateSections(lessonId: string) {
        return axios.post(`/api/v1/admin/lessons/${lessonId}/sections/multi`);
    },
    updateSection(lessonId: string, sectionId: string, data: any) {
        return axios.patch(`/api/v1/admin/lessons/${lessonId}/sections/${sectionId}`, data);
    },
    deleteSection(lessonId: string, sectionId: string) {
        return axios.delete(`/api/v1/admin/lessons/${lessonId}/sections/${sectionId}`);
    },
    //  section content
    getSectionContent(sectionId: string) {
        return axios.get(`/api/v1/admin/sections/${sectionId}/sectionContents`);
    },
    createSectionRelation(sectionId: string, data: any) {
        return axios.post(`/api/v1/admin/sections/${sectionId}/sectionContents`, data);
    },
    updateSectionRelation(sectionId: string, data: any) {
        return axios.patch(`/api/v1/admin/sections/${sectionId}/sectionContents`, data);
    },
    createMultiSectionContent(data: { excelData: Array<any> }) {
        return axios.post(`/api/v1/admin/sectionContents`, data);
    },

    // words
    getAllWord() {
        return axios.get('/api/v1/admin/words');
    },
    createWord(data: any) {
        return axios.post('/api/v1/admin/words', data);
    },
    updateWord(data: { _id: string }) {
        return axios.patch(`/api/v1/admin/words/${data._id}`, data);
    },
    deleteWord(id: string) {
        return axios.delete(`/api/v1/admin/words/${id}`);
    },
    getWord(worldId: string) {
        return axios.get(`/api/v1/admin/words/${worldId}`);
    },

    // sentence
    getAllSentence() {
        return axios.get('/api/v1/admin/sentences');
    },
    createSentence(data: any) {
        return axios.post('/api/v1/admin/sentences', data);
    },
    updateSentence(data: { _id: string }) {
        return axios.patch(`/api/v1/admin/sentences/${data._id}`, data);
    },
    deleteSentence(id: string) {
        return axios.delete(`/api/v1/admin/sentences/${id}`);
    },
    getSentence(sentenceId: string) {
        return axios.get(`/api/v1/admin/sentences/${sentenceId}`);
    },

    // Cartoon
    getAllCartoon() {
        return axios.get('/api/v1/admin/cartoons');
    },
    createCartoon(data: any) {
        return axios.post('/api/v1/admin/cartoons', data);
    },
    updateCartoon(data: { _id: string }) {
        return axios.patch(`/api/v1/admin/cartoons/${data._id}`, data);
    },
    deleteCartoon(id: string) {
        return axios.delete(`/api/v1/admin/cartoons/${id}`);
    },
    getCartoon(sentenceId: string) {
        return axios.get(`/api/v1/admin/cartoons/${sentenceId}`);
    },
    getLessonCartoons(lessonId: string) {
        return axios.get(`/api/v1/admin/lessons/${lessonId}/lessonCartoons`);
    },
    createLessonCartoons(lessonId: string, cartoonIds: string[]) {
        return axios.post(`/api/v1/admin/lessons/${lessonId}/lessonCartoons`, { cartoonIds });
    },

    // getStudyTime
    getStudyTime(userId: string) {
        return axios.get(`/api/v1/admin/studytime/${userId}`);
    },
    // createStudyTime
    createStudyTime(data: object) {
        return axios.post('/api/v1/admin/studytime', data);
    },
    // getStudyProgress
    getStudyProgress(userId: string) {
        return axios.get(`/api/v1/studyprogress/${userId}`);
    },
    // get user study record
    getStudyRecords(sectionContentId: string, userId?: string) {
        return axios.get(`/api/v1/admin/sectionContent/${sectionContentId}/records?userId=${userId}`);
    },

    // get user order
    getUserOrders(userId: string) {
        return axios.get(`/api/v1/admin/userorders?userId=${userId}`);
    },

    // words
    getNames() {
        return axios.get('/api/v1/admin/names');
    },
    createName(data: any) {
        return axios.post('/api/v1/admin/names', data);
    },
    updateName(data: { _id: string }) {
        return axios.patch(`/api/v1/admin/names/${data._id}`, data);
    },
    deleteName(id: string) {
        return axios.delete(`/api/v1/admin/names/${id}`);
    },
    getName(worldId: string) {
        return axios.get(`/api/v1/admin/names/${worldId}`);
    },

    // media
    getAllMedia(pagination: {} = { page, pageSize }) {
        return axios.get('/api/v1/admin/medias');
    },
    getMedia(id: string) {
        return axios.get(`/api/v1/admin/medias/${id}`);
    },
    createMedia(data: any) {
        return axios.post('/api/v1/admin/medias', data);
    },
    updateMedia(data: { _id: string }) {
        return axios.patch(`/api/v1/admin/medias/${data._id}`, data);
    },
    deleteMedia(id: string) {
        return axios.delete(`/api/v1/admin/medias/${id}`);
    },

    // address
    createAddress(data: any, userId: string) {
        return axios.post(`/api/v1/admin/user/address?userId=${userId}`, data);
    },
    updateAddress(data: { _id: string }) {
        return data._id && axios.put(`/api/v1/admin/user/address/${data._id}`, data);
    },
    getAddress(userId: string) {
        return axios.get(`/api/v1/admin/user/address?userId=${userId}`);
    },
    deleteAddress(addressId: string) {
        return axios.delete(`/api/v1/admin/user/address/${addressId}`);
    },

    // order
    getOrders() {
        return axios.get('/api/v1/admin/orders');
    },
    getOrder(orderId: string) {
        return axios.get(`/api/v1/admin/userorders/${orderId}`);
    },
    updateOrder(data: OrderModel) {
        return axios.patch(`/api/v1/admin/userorders/${data._id}`, data);
    },
    deleteOrder(id: string) {
        return axios.delete(`/api/v1/admin/userorders/${id}`);
    },
    getRefundOrder(orderId: string) {
        return axios.get(`/api/v1/admin/refundOrder?orderId=${orderId}`);
    },
    setRefund(data: any) {
        return axios.post('/api/v1/wx/refund', data);
    },
    deleteUserOrder(orderId: string) {
        return axios.delete(`/api/v1/admin/userorders?orderId=${orderId}`);
    },
    // mall
    createGood(data: MallModel) {
        return axios.post('/api/v1/admin/products', data);
    },
    updateGood(data: MallModel) {
        return axios.patch(`/api/v1/admin/products/${data._id}`, data);
    },
    deleteGood(id: string) {
        return axios.delete(`/api/v1/admin/products/${id}`);
    },
    getGoods(pagination = { page, pageSize }) {
        return axios.get(`/api/v1/admin/products?page=${pagination.page}&pageSize=${pagination.pageSize}`);
    },
    getAllOrders(data: any) {
        return axios.get('/api/v1/userorders', data);
    },

    // role
    getAllRole(pagination: { page: number, pageSize: number } = { page, pageSize }) {
        return axios.get(`/api/v1/admin/roles?page=${pagination.page}&pageSize=${pagination.pageSize}`);
    },
    getRoleDetail(roleId: string) {
        return axios.get(`/api/v1/admin/roles/${roleId}`);
    },
    createRole(data: RoleModel) {
        return axios.post('/api/v1/admin/roles', data);
    },
    updateRole(data: RoleModel) {
        return axios.patch(`/api/v1/admin/roles/${data._id}`, data);
    },
    deleteRole(roleId: string) {
        return axios.delete(`/api/v1/admin/roles/${roleId}`);
    },

    // menu
    getAllMenu(pagination: { page: number, pageSize: number } = { page, pageSize }) {
        return axios.get(`/api/v1/admin/menus?page=${pagination.page}&pageSize=${pagination.pageSize}`);
    },
    createMenu(data: any) {
        return axios.post('/api/v1/admin/menus', data);
    },
    updateMenu(data: MenuModel) {
        return axios.patch(`/api/v1/admin/menus/${data._id}`, data);
    },
    deleteMenu(menuId: string) {
        return axios.delete(`/api/v1/admin/menus/${menuId}`);
    },
    getMenuTree() {
        return axios.get('/api/v1/admin/menusTree');
    },
    getMenuTreeById(id: string) {
        return axios.get(`/api/v1/admin/auth/users/${id}`);
    },
    getMenuDetail(menuId: string) {
        return axios.get(`/api/v1/admin/menus/${menuId}`);
    },

    // action
    createAction(data: ActionModel) {
        return axios.post('/api/v1/admin/actions', data);
    },
    getAllAction(pagination: { page: number, pageSize: number } = { page, pageSize }) {
        return axios.get(`/api/v1/admin/actions?page=${pagination.page}&pageSize=${pagination.pageSize}`);
    },
    getActionDetail(id: string) {
        return axios.get(`/api/v1/admin/actions/${id}`);
    },
    updateAction(data: { _id: string }) {
        return axios.patch(`/api/v1/admin/actions/${data._id}`, data);
    },
    relatedResources(data: { _id: string, resourceIds: any }) {
        return axios.patch(`/api/v1/admin/actions/${data._id}/resources`, data);
    },
    deleteAction(actionId: string) {
        return axios.delete(`/api/v1/admin/actions/${actionId}`);
    },
    getUserActions(id: string) {
        return axios.get(`/api/v1/admin/auth/users/${id}/actions`);
    },

    // resource
    getAllResource() {
        return axios.get('/api/v1/admin/resources');
    },
    getResources(pagination: { page: number, pageSize: number } = { page, pageSize: 1000 }) {
        return axios.get(`/api/v1/admin/resources?page=${pagination.page}&pageSize=${pagination.pageSize}`);
    },
    createResource(data: any) {
        return axios.post('/api/v1/admin/resources', data);
    },
    updateResource(data: { _id: string, resource: any }) {
        return axios.patch(`/api/v1/admin/resources/${data._id}`, data.resource);
    },
    deleteResource(resourceId: string) {
        return axios.delete(`/api/v1/admin/resources/${resourceId}`);
    },

    // roleMenu
    getRoleMenus() {
        return axios.get('/api/v1/admin/roleMenus');
    },
    createRoleMenu(data: any) {
        return axios.post('/api/v1/admin/roleMenus', data);
    },
    updateRoleMenu(data: { _id: string, menus: Array<any> }) {
        return axios.patch(`/api/v1/admin/roleMenus/${data._id}`, data.menus);
    },
    deleteRoleMenu(roleMenuId: string) {
        return axios.delete(`/api/v1/admin/roleMenus/${roleMenuId}`);
    },

    // roleAction
    createRoleAction(data: any) {
        return axios.post('/api/v1/admin/roleActions', data);
    },
    updateRoleAction(data: { _id: string, updateActions: Array<any> }) {
        return axios.patch(`/api/v1/admin/roleActions/${data._id}`, data.updateActions);
    },
    getFiles(pagination = { page, pageSize }, type: string = '') {
        return axios.get(`/api/v1/admin/files?type=${type}&page=${pagination.page}&pageSize=${pagination.pageSize}`);
    },
    getFile(id: string) {
        return axios.get(`/api/v1/admin/files/${id}`);
    },
    getStatsKeyCount() {
        return axios.get(`/api/v1/app/stats/key`);
    },
    getSharers() {
        return axios.get(`/api/v1/app/stats/sharers`);
    },
    getRecipients(sharerId: string) {
        return axios.get(`/api/v1/app/shares/${sharerId}/recipients`);
    }
};
