import React, { useState } from 'react';
import { Form, Col, Row, Input, Button, Select } from 'antd';

import { useIntl } from 'react-intl';
import { UserModel } from '../../../models/user';

const { Option } = Select;


interface Props {
    initialValues?: UserModel
    isCreate: boolean
    onSubmit: (data: UserModel, isCreate: boolean) => void
    onClose: () => void
    roles: Array<any>

}

const NewUserForm = (props: Props) => {
    const initRoles = props.initialValues ? props.initialValues.roles.map((role: any) => role._id) : [];
    const [form] = Form.useForm();
    const [roles, setRoles] = useState<Array<string>>(initRoles);
    const intl = useIntl();

    const titleData = ['roleName', 'roleNamePlaceholder', 'submit', 'menuPower', 'action', 'cancel', 'submit'];
    const titleName: any = {};
    titleData.forEach((text: string) => titleName[text] = intl.formatMessage({ id: text }));

    const onFinish = (values: any) => {
        values.roles = values.userRoles || roles;

        if (!props.isCreate) {
            values._id = props.initialValues && props.initialValues._id;
        }
        props.onSubmit(values, props.isCreate);
    };
    return (
        <Form
            layout='vertical'
            // hideRequiredMark
            initialValues={props.initialValues}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name={props.initialValues && props.initialValues.nickName ? 'nickName' : 'email'}
                        label='用户名:'
                        rules={[{ required: true, message: '用户名名称不能为空' }]}
                    >
                        <Input placeholder='请输入用户名' disabled={!props.isCreate} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='userRoles'
                        label='所属角色:'
                        rules={[{ required: false, type: 'array' }]}
                    >
                        <Select
                            mode='multiple'
                            defaultValue={roles}
                            placeholder='请选择角色'
                            onSelect={selectRoles => { setRoles(roles.concat(selectRoles)); }}
                        >
                            {
                                props.roles.map((data: any) => <Option key={data._id} value={data._id}>{data.name}</Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>

            </Row>
            <Row gutter={16} hidden={!props.isCreate}>
                <Col span={12}>
                    <Form.Item
                        name='password'
                        label='密码:'
                        rules={[{ required: props.isCreate, message: '密码不能为空' }]}
                    >
                        <Input.Password placeholder='请输入密码' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='confirmPassword'
                        label='确认密码:'
                        dependencies={['password']}
                        rules={[
                            { required: props.isCreate, message: '确认密码不能为空' },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('密码不匹配!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder='请输入确认密码' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='age'
                        label='年龄:'
                        rules={[{ required: false }]}
                    >
                        <Input placeholder='请输入年龄' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24} className='text-right'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            {titleName.cancel}
                        </Button>
                        <Button type='primary' htmlType='submit' className='mar-left'>
                            {titleName.submit}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default NewUserForm;
