import { createAction, handleActions } from 'redux-actions';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/constants';

const {
    CREATE_BOOK,
    UPDATE_BOOK,
    DELETE_BOOK,
    ALL_BOOK,
    SELECT_BOOK,
    CREATE_COURSE,
    UPDATE_COURSE,
    DELETE_COURSE,
    ALL_COURSE,
    SELECT_COURSE,
    GET_USERSTUDY_TIME,
    CREATE_WORD,
    UPDATE_WORD,
    DELETE_WORD,
    ALL_WORD,
    CREATE_PICTURE_BOOK,
    UPDATE_PICTURE_BOOK,
    DELETE_PICTURE_BOOK,
    ALL_PICTURE_BOOK,
    SECTION_CONTENT,
    ALL_SENTENCE,
    ALL_CARTOON,
} = CONSTANTS;
// initialState
const initialState = {
    books: [],
    currentBook: null,
    allCourses: [],
    current: null,
    studyProgress: null,
    words: [],
    currentWord: null,
    pictureBooks: [],
    currentPictureBooks: null,

    allLesson: [],
    currentLesson: null,
    currentWords: [],
    allSentence: [],
    allCartoons: [],
};

interface Action {
    type: string
    payload: any
}

export default handleActions(
    {
        [CREATE_BOOK](state) {
            return {
                ...state,
            };
        },
        [UPDATE_BOOK](state, action: Action) {
            return {
                ...state,
                currentBook: action.payload,
            };
        },
        [DELETE_BOOK](state) {
            return {
                ...state,
            };
        },
        [ALL_BOOK](state, action: Action) {
            return {
                ...state,
                books: action.payload,
                currentBook: state.currentBook || action.payload[0],
                allLesson: action.payload,
                currentLesson: state.currentLesson || action.payload[0],
            };
        },
        [SELECT_BOOK](state, action: Action) {
            const currentLesson = state.allLesson.find((item: any) => item._id === action.payload);
            return {
                ...state,
                currentBook: currentLesson,
                currentLesson,
            };
        },
        [CREATE_COURSE](state) {
            return {
                ...state,
            };
        },
        [UPDATE_COURSE](state, action: Action) {
            return {
                ...state,
                current: action.payload,
            };
        },
        [DELETE_COURSE](state) {
            return {
                ...state,
            };
        },
        [ALL_COURSE](state, action: Action) {
            return {
                ...state,
                allCourses: action.payload,
                current: state.current || action.payload[0],
                lessonSections: action.payload,
            };
        },
        [SELECT_COURSE](state, action: Action) {
            const current = state.allCourses.find((item: any) => item._id === action.payload);
            return {
                ...state,
                current,
            };
        },
        [GET_USERSTUDY_TIME](state, action: Action) {
            return {
                ...state,
                studyProgress: action.payload,
            };
        },
        [CREATE_WORD](state) {
            return {
                ...state,
            };
        },
        [UPDATE_WORD](state, action: Action) {
            return {
                ...state,
                currentWord: action.payload,
            };
        },
        [DELETE_WORD](state) {
            return {
                ...state,
            };
        },
        [ALL_WORD](state, action: Action) {
            return {
                ...state,
                words: action.payload,
                currentWord: state.currentWord || action.payload[0],
            };
        },
        [ALL_SENTENCE](state, action: Action) {
            return {
                ...state,
                allSentence: action.payload,
            };
        },
        [ALL_CARTOON](state, action: Action) {
            return {
                ...state,
                allCartoon: action.payload,
            };
        },
        [CREATE_PICTURE_BOOK](state) {
            return {
                ...state,
            };
        },
        [UPDATE_PICTURE_BOOK](state, action: Action) {
            return {
                ...state,
                currentPictureBook: action.payload,
            };
        },
        [DELETE_PICTURE_BOOK](state) {
            return {
                ...state,
            };
        },
        [ALL_PICTURE_BOOK](state, action: Action) {
            return {
                ...state,
                pictureBooks: action.payload,
                currentPictureBook: state.currentWord || action.payload[0],
            };
        },

        [SECTION_CONTENT](state, action: Action) {
            return {
                ...state,
                words: action.payload,
                currentWords: state.currentWord || action.payload[0],
                sectionWords: action.payload,
            };
        },
    },
    initialState as any
);

/**
 * action creators
 * */
export const actions = {
    // Lesson
    createLesson: createAction(CREATE_BOOK, (book: any) => APIServices.createLesson(book)),
    updateLesson: createAction(UPDATE_BOOK, async (book: any) => (await APIServices.updateLesson(book)).data),
    deleteLesson: createAction(DELETE_BOOK, async (book: any) => await APIServices.deleteLesson(book)),
    getAllLesson: createAction(ALL_BOOK, async (pagination: { page: number, pageSize: number }) => (await APIServices.getAllLesson(pagination)).data),
    selectBook: createAction(SELECT_BOOK),

    // course
    // createCourse: createAction(CREATE_COURSE, (course: any) => APIServices.createUnit(course)),
    // updateCourse: createAction(UPDATE_COURSE, async (course: any) => (await APIServices.updateUnit(course)).data),
    // deleteCourse: createAction(DELETE_COURSE, async (course: any) => await APIServices.deleteUnit(course)),
    // getAllCourse: createAction(ALL_COURSE, async (bookId: string) => (await APIServices.getUnits(bookId)).data),
    // selectCourse: createAction(SELECT_COURSE),

    // section
    createSection: createAction(CREATE_COURSE, (lessonId: string, course: any) => APIServices.createSection(lessonId, course)),
    updateSection: createAction(UPDATE_COURSE, async (lessonId: string, sectionId: string, course: any) => (await APIServices.updateSection(lessonId, sectionId, course)).data),
    deleteSection: createAction(DELETE_COURSE, async (lessonId: string, sectionId: string) => await APIServices.deleteSection(lessonId, sectionId)),
    getLessonSection: createAction(ALL_COURSE, async (lessonId: string) => (await APIServices.getLessonSections(lessonId)).data),
    selectSection: createAction(SELECT_COURSE),

    // sectionContent
    getSectionContent: createAction(SECTION_CONTENT, async (sectionId: string) => (await APIServices.getSectionContent(sectionId)).data),
    getStudyTime: createAction(GET_USERSTUDY_TIME, async (userId: string) => (await APIServices.getStudyTime(userId)).data || (await APIServices.createStudyTime({ user: userId })).data),

    // word
    getAllWord: createAction(ALL_WORD, async () => (await APIServices.getAllWord()).data),
    createWord: createAction(CREATE_WORD, (word: any) => APIServices.createWord(word)),
    updateWord: createAction(UPDATE_WORD, async (word: any) => (await APIServices.updateWord(word)).data),
    deleteWord: createAction(DELETE_WORD, async (wordId: string) => await APIServices.deleteWord(wordId)),
    // getWord: createAction(ALL_WORD, async (wordId: string) => (await APIServices.getWord(wordId)).data),

    // sentence
    getAllSentence: createAction(ALL_SENTENCE, async () => (await APIServices.getAllSentence()).data),
    // createSentence: createAction(CREATE_SENTENCE, (word: any) => APIServices.createWord(word)),
    // updateSentence: createAction(UPDATE_SENTENCE, async (word: any) => (await APIServices.updateWord(word)).data),
    // deleteSentence: createAction(DELETE_SENTENCE, async (wordId: string) => await APIServices.deleteWord(wordId)),

    // cartoon
    getAllCartoon: createAction(ALL_CARTOON, async () => (await APIServices.getAllCartoon()).data),

    // picture book
    createPictureBook: createAction(CREATE_PICTURE_BOOK, (pictureBook: any) => APIServices.createPictureBook(pictureBook)),
    updatePictureBook: createAction(UPDATE_PICTURE_BOOK, async (pictureBook: any) => (await APIServices.updatePictureBook(pictureBook)).data),
    deletePictureBook: createAction(DELETE_PICTURE_BOOK, async (pictureBookId: string) => await APIServices.deletePictureBook(pictureBookId)),
    getAllPictureBook: createAction(ALL_PICTURE_BOOK, async (courseId: string) => (await APIServices.getPictureBooks(courseId)).data),
};
