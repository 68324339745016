import React, { Component } from 'react';
import { Typography, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { actions } from '../../redux/modules/course';

import PictureBookList from './components/pictureBookList';
import DrawerForm from '../../components/basic/drawer';
import PictureBookForm from './components/pictureBookForm';
import { PictureBookModel } from '../../models/course';
import 'antd/dist/antd.min.css';

const { createPictureBook, deletePictureBook, updatePictureBook, getAllPictureBook } = actions;
const { Title } = Typography;

interface Props {
    match?: {
        params: {
            courseId: string
        }
    }
    pictureBooks: PictureBookModel[]
    courseId: string
    getAllPictureBook: (courseId: string) => void
    deletePictureBook: (id: string) => void
    updatePictureBook: (values: object) => void
    createPictureBook: (values: object) => void
}
interface State {
    pictureBooks?: PictureBookModel[]
    visible: boolean
    initialValues?: PictureBookModel
}

const mapStateToProps = (state: any) => ({
    pictureBooks: state.course.pictureBooks,
    currentPictureBook: state.course.currentPictureBook,
});

const mapDispatchToProps = {
    createPictureBook,
    deletePictureBook,
    updatePictureBook,
    getAllPictureBook,
};

class PictureBookView extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            pictureBooks: [],
            visible: false,
        };
    }

    refreshList = async () => {
        await this.props.getAllPictureBook(this.props.courseId);
        this.setState({
            pictureBooks: this.props.pictureBooks,
        });
    };

    showDrawer = (item?: PictureBookModel) => {
        const data = item && item._id
            ? {
                title: 'Update Picture Book',
                type: 'update',
                visible: true,
                initialValues: item,
            }
            : {
                title: 'Create Picture Book',
                type: 'create',
                visible: true,
            };
        this.setState(data);
    }

    deleteList = async (id: string) => {
        await this.props.deletePictureBook(id);
        notification.success({
            message: 'Delete Success',
            description: '删除成功',
            placement: 'topRight',
        });
        this.refreshList();
    }

    onClose = () => {
        this.setState({ visible: false, initialValues: undefined });
    }

    onSubmit = async (values: any) => {
        values.unit = this.props.courseId;
        values._id
            ? await this.props.updatePictureBook(values)
            : await this.props.createPictureBook(values);
        this.onClose();
        this.refreshList();
    }

    async componentDidMount() {
        this.refreshList();
    }

    render() {
        return (
            <>
                <Title>Picture Book</Title>
                <PlusOutlined className='plusIcon' onClick={() => this.showDrawer()} />
                <PictureBookList
                    dataSource={this.props.pictureBooks}
                    deleteList={this.deleteList}
                    edit={(course: any) => this.showDrawer(course)} />
                {
                    this.state.visible &&
                    <DrawerForm
                        {...this.state}
                        onClose={this.onClose}
                    >
                        <PictureBookForm
                            onClose={this.onClose}
                            initialValues={this.state.initialValues}
                            onSubmit={this.onSubmit}
                        />
                    </DrawerForm>
                }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PictureBookView);
