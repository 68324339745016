import React from 'react';
import 'antd/dist/antd.min.css';
import APIServices from '../../lib/APIServices';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Descriptions, Button, Card, Modal } from 'antd';

import './styles/index.css';

const { confirm } = Modal;
interface Props {
    match: {
        params: {
            orderId: string
        }
    }
    getOrderProfile: (id: string) => object
    getOrderStudyTime: (orderId: string) => object
}
interface State {
    currentOrder?: any
    refundOrder?: any
}

class OrderDetail extends React.Component<Props, State> {
    orderId = this.props.match.params.orderId;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    getRefundOrder = async () => {
        const [currentOrder, refundOrder] = await Promise.all([
            APIServices.getOrder(this.orderId),
            APIServices.getRefundOrder(this.orderId),
        ]);

        this.setState({
            currentOrder: currentOrder.data,
            refundOrder: refundOrder.data,
        });
    }

    async componentDidMount() {
        this.getRefundOrder();
    }

    render() {
        const { currentOrder, refundOrder } = this.state;
        const ORDER_STATE = ['待支付', '待收货', '交易完成', '交易取消'];
        const PAY_STATE = ['待支付', '待收货', '支付失败', '退款中', '退款成功', '退款失败', '交易完成', '交易取消'];
        return (
            <Card>
                <Descriptions title='订单详情' bordered>
                    {
                        currentOrder &&
                        <>
                            <Descriptions.Item label='订单号' span={2}>{currentOrder.orderCode}</Descriptions.Item>
                            <Descriptions.Item label='数量'>{currentOrder.goodsInfo.goodsNumber}</Descriptions.Item>
                            {
                                currentOrder.orderState > 1 && <>
                                    <Descriptions.Item label='物流单号' span={2}>{currentOrder.logisticsCode}</Descriptions.Item>
                                    <Descriptions.Item label='物流公司'>{currentOrder.logisticsCompany}</Descriptions.Item>
                                </>
                            }
                            <Descriptions.Item label='价钱'>{currentOrder.goodsInfo.goodsPrice}</Descriptions.Item>
                            <Descriptions.Item label='订单状态'>{ORDER_STATE[currentOrder.orderState]}</Descriptions.Item>
                            <Descriptions.Item label='支付状态'>{PAY_STATE[currentOrder.payState]}</Descriptions.Item>
                        </>

                    }
                    {
                        refundOrder && currentOrder.payState < 4 && <Descriptions.Item label='退款' span={4}>
                            <p>退款金额: {refundOrder.refundMoney}</p>
                            <p>退款时间: {refundOrder.createdTime}</p>
                            <p>退款状态: {ORDER_STATE[refundOrder.orderState]}</p>
                            <Button
                                onClick={() => {
                                    confirm({
                                        title: 'Are you sure you want to refund?',
                                        icon: <ExclamationCircleOutlined />,
                                        okType: 'danger',
                                        onOk() {
                                            APIServices.setRefund({
                                                orderCode: currentOrder.orderCode,
                                                money: currentOrder.goodsInfo.goodsTotalPrice,
                                                orderId: currentOrder._id,
                                                refundMoney: refundOrder.refundMoney,
                                            });
                                        },
                                    });
                                }}
                            >
                                确认退款
                            </Button>
                        </Descriptions.Item>
                    }
                </Descriptions>
            </Card>
        );
    }
}

export default OrderDetail;
