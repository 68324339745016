import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import dataSet from './dataSet';

import FrontendAuth from './FrontendAuth';

import { Route as RouteItem } from '../models/router';

const Routes = () => {
    const routeItems = dataSet.map((route: RouteItem) => <Route key={route.path} exact path={route.path} component={route.component} />);
    return (
        <Switch>
            <FrontendAuth routerConfig={dataSet} />
        </Switch >
    );
};
export default Routes;
