import React, { Component } from 'react';
import { Form, Input, Button, Checkbox, Typography, Modal } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { actions as authActions } from '../../../redux/modules/auth';
import { actions as globalActions } from '../../../redux/modules/global';
import { AuthModel, AuthInfo } from '../../../models/auth';
import '../styles/login.css';

const { Title } = Typography;
const { setUserInfo, userSignIn, getUserData } = authActions;
const { setLoading } = globalActions;

interface UserInfo {
    auth: {
        userInfo: AuthInfo
    }
}

const mapStateToProps = (state: UserInfo) => ({
    userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {
    setUserInfo,
    userSignIn,
    getUserData,
    setLoading,
};

interface Props {
    history: Array<string>
    userInfo: AuthInfo
    userSignIn: (data: AuthModel) => object
    getUserData: (id: string) => void
    setLoading: (parameter: boolean) => void
}
interface State {
    goToRegister?: boolean
}

class Login extends Component<Props, State>{
    constructor(props: Props) {
        super(props);

        this.state = {
            goToRegister: false,
        };
    }

    onFinish = async (values: AuthModel | any) => {
        this.props.setLoading(true);
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await this.props.userSignIn({
            ...values,
            'grant_type': 'password',
        });
        this.props.setLoading(false);
        let loginError;
        const { userInfo } = this.props;
        if (userInfo.code === 'INVALID_USER') {
            loginError = {
                title: 'Login Error, username or password invalid',
                content: 'Please try again!',
            };
        }

        if (loginError) {
            Modal.error(loginError);
        }
    };

    onFinishFailed = (errorInfo: object) => {
        console.log('Failed:', errorInfo);
    };

    goToRegister = () => {
        this.setState({
            goToRegister: true,
        });
    }

    render() {
        if (this.state.goToRegister) {
            return <Redirect push to='/register' />;
        }

        if (localStorage.getItem('accessToken')) {
            return <Redirect to='/home/newUser' />;
        }

        return (
            <div className='login-bg'>
                <div className='bg-box'></div>
                <div className='form-box'>
                    <Title level={4} className='login-title'>瑞木美语后端管理系统</Title>
                    <Form
                        name='normal_login'
                        className='login-form'
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Form.Item
                            name='username'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Username!',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className='site-form-item-icon' />} placeholder='Username' />
                        </Form.Item>
                        <Form.Item
                            name='password'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className='site-form-item-icon' />}
                                type='password'
                                placeholder='Password'
                            />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name='remember' valuePropName='checked' noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>

                            <a className='login-form-forgot' href='' hidden>
                                Forgot password
                            </a>
                        </Form.Item>

                        <Form.Item className='bottomBtn'>
                            <Button type='primary' htmlType='submit' className='login-form-button'>
                                Log in
                            </Button>
                            <p className='register' hidden>
                                <a className='btn-link' onClick={this.goToRegister}>Register Now!</a>
                            </p>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
