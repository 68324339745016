// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import { Form, Col, Row, Input, Button, Select } from 'antd';
import { useIntl } from 'react-intl';
import Common from '../../../lib/common';
import '../styles/index.css';

const { getPageTitleName } = Common;
const { Option } = Select;
interface Props {
    resource?: any
    isCreate: boolean
    onSubmit: (data: any, isCreate: boolean) => void
    onClose: () => void
}

const ResourceForm = (props: Props) => {
    const [form] = Form.useForm();
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();

    const titleData = ['topMenu', 'topMenuPlaceholder', 'menuName', 'menuIcon', 'menuType', 'menuIconPlaceholder', 'menuRouterName', 'menuRouterNamePlaceholder', 'menuComponentPath', 'menuComponentPathPlaceholder', 'menuComponentRouter', 'menuComponentRouterPlaceholder', 'menustatu', 'show', 'hidden', 'cancel', 'submit'];
    const titleName = getPageTitleName(titleData, intl.formatMessage);

    const onFinish = (values: any) => {
        // create action and resource
        values.resources = values.resourcesData;
        if (!props.isCreate) {
            values._id = props.resource._id;
        }

        props.onSubmit(values, props.isCreate);
        props.onClose();
    };

    const handleClose = () => {
        props.onClose();
    };

    const onFieldsChange = () => {
        if (!props.isCreate && props.resource) {
            form.setFieldsValue(props.resource);
        } else {
            form.resetFields();
        }
    };
    useEffect(() => {
        onFieldsChange();
    }, []);
    return (
        <Form
            layout='vertical'
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='method'
                        label='方法名:'
                        rules={[{ required: true, message: 'Please enter resource method name' }]}
                    >
                        <Select placeholder={titleName.menuRouterNamePlaceholder} >
                            <Option value='GET'>GET</Option>
                            <Option value='POST'>POST</Option>
                            <Option value='DELETE'>DELETE</Option>
                            <Option value='PATCH'>PATCH</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='path'
                        label='路由名称:'
                        rules={[{ required: true, message: 'Please enter resource path name' }]}
                    >
                        <Input placeholder={titleName.menuRouterNamePlaceholder} />

                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16} className='action-btn'>
                <Col span={24} className='text-right'>
                    <Button htmlType='button' onClick={handleClose}>
                        取消
                    </Button>
                    <Button type='primary' htmlType='submit' className='mar-left' >
                        确认
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default ResourceForm;
