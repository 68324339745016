import { createAction, handleActions } from 'redux-actions';
import CONSTANTS from '../../lib/constants';
const { SET_LOADING } = CONSTANTS;

// initialState
const initialState = {
    loadingState: false,
};

interface Action {
  type: string
  payload?: any
}

type State = typeof initialState

export default handleActions(
    {
        [SET_LOADING](state: State, action: Action) {
            return {
                ...state,
                loadingState: action.payload,
            };
        },
    },
    initialState
);

export const actions = {
    setLoading: createAction(SET_LOADING, (show: boolean) => show),
};
