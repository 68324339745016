import React from 'react';
import { Form, Col, Row, Input, Button, Select } from 'antd';
import { useIntl } from 'react-intl';

import { ClientModel } from '../../../models/client';
import Common from '../../../lib/common';

const { Option } = Select;
const { getPageTitleName } = Common;
interface Props {
    initialValues?: ClientModel
    onSubmit: (data: ClientModel) => void
    onClose: () => void
}

const ClientForm = (props: Props) => {
    const [form] = Form.useForm();
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();

    const titleData = ['clientName', 'clientId', 'clientType', 'clientIdPlaceholder', 'clientNamePlaceholder', 'clientTypePlaceholder', 'cancel', 'submit', 'create', 'update'];
    const titleName = getPageTitleName(titleData, intl.formatMessage);

    const onFinish = (values: ClientModel) => {
        values._id = props.initialValues && props.initialValues._id;
        props.onSubmit(values);
    };

    return (
        <Form
            layout='vertical'
            hideRequiredMark
            initialValues={props.initialValues}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='clientName'
                        label={titleName.clientName}
                        rules={[{ required: true, message: titleName.clientNameRequired }]}
                    >
                        <Input placeholder={titleName.clientNamePlaceholder} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='clientId'
                        label={titleName.clientId}
                        rules={[{ required: true, message: titleName.clientIdRequired }]}
                    >
                        <Input placeholder={titleName.clientIdPlaceholder} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='clientType'
                        label={titleName.clientType}
                        rules={[{ required: true, message: 'Please enter type of Client' }]}
                    >
                        <Select placeholder={titleName.clientTypePlaceholder} >
                            <Option value='0'>ADMIN</Option>
                            <Option value='1'>MINI</Option>
                            <Option value='2'>APP</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24} className='text-right'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            {titleName.cancel}
                        </Button>
                        <Button type='primary' htmlType='submit' className='mar-left'>
                            {titleName.submit}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default ClientForm;
