import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.min.css';
import DrawerForm from '../../components/basic/drawer';
import ActionForm from './components/actionForm';
import { Typography, notification, Button, Table, Modal } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import APIServices from '../../lib/APIServices';
import { ActionModel } from '../../models/action';
import CONSTANTS from '../../lib/constants';
import Common from '../../lib/common';

import './styles/index.css';

const { confirm } = Modal;
const { getPageTitleName } = Common;
const { Title } = Typography;
const { ACTION_KEY } = CONSTANTS;

interface Pagination {
    current: number
    pageSize: number
    showSizeChanger?: boolean
    total?: number
}

interface DefaultRootState {
    user: {
        userActions: Array<number>
    }
}

interface DrawerInfo {
    isCreate: boolean
    title: any,
    type: string
    actionId?: string
}

const Action = () => {
    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const [drawer, setDrawer] = useState<DrawerInfo>({
        isCreate: true,
        title: '',
        type: '',
    });
    const [visible, setVisible] = useState<boolean>(false);
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();
    const { userActions } = useSelector((state: DefaultRootState) => ({
        userActions: state.user.userActions,
    }));

    const [actions, setActions] = useState<Array<ActionModel>>([]);
    // config table title
    const titleData = ['deleteTip', 'roleNumber', 'roleName', 'roleNamePlaceholder', 'action'];
    const titleName = getPageTitleName(titleData, intl.formatMessage);

    const refreshList = async () => {
        const { data } = await APIServices.getAllAction({ page: pagination.current, pageSize: pagination.pageSize });
        setActions(data);
    };

    const showDrawer = (item?: ActionModel) => {
        const data = item && item._id
            ? {
                title: intl.formatMessage({ id: 'updateAction' }),
                type: 'update',
                actionId: item._id,
                isCreate: false,
            }
            : {
                title: intl.formatMessage({ id: 'createAction' }),
                type: 'create',
                isCreate: true,
            };
        setDrawer(data);
        setVisible(true);
    };

    const onClose = () => {
        setDrawer({ ...drawer, actionId: '' });
        setVisible(false);
    };

    const onSubmit = async (values: ActionModel, isCreate: boolean) => {
        // create action
        if (isCreate) {
            const action = (await APIServices.createAction(values)).data;
            if (action._id) {
                const resourceIds = values.resources.map((item: any) => item._id || item.path);
                APIServices.updateAction({ ...values, _id: action._id });
                APIServices.relatedResources({ _id: action._id, resourceIds });
                notification.success({
                    message: 'Create Success',
                    placement: 'topRight',
                });
            }
        } else {
            // update action = action Id && resource id
            const resourceIds = values.resources.map((item: any) => item._id || item.path);
            APIServices.updateAction({ ...values, _id: values._id });
            APIServices.relatedResources({ _id: values._id, resourceIds });
            notification.success({
                message: 'Update Success',
                placement: 'topRight',
            });
        }
        onClose();
        refreshList();
    };

    const deleteList = async (id: string) => {
        await APIServices.deleteAction(id);
        notification.success({
            message: 'Delete Success',
            placement: 'topRight',
        });
        refreshList();
    };

    const setPaginationParams = (paginationInfo: any) => {
        setPagination(paginationInfo);
        refreshList();
    };

    const columns = [
        {
            title: '动作编号',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '动作名称',
            dataIndex: 'key',
            key: 'key',
            // eslint-disable-next-line react/display-name
            render: (text: {}, formValues: { key: number }) => {
                const label = ['创建', '编辑', '删除', '只读'];
                return (
                    <span>
                        {label[formValues.key]}
                    </span>
                );
            },
        },
        {
            title: titleName.action,
            key: 'action',
            fixed: 'right',
            // eslint-disable-next-line react/display-name
            render: (text: {}, formValues: ActionModel) =>
                <span>
                    <Button
                        icon={<EditOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.EDIT)}
                        onClick={() => showDrawer(formValues)}
                    ></Button>
                    <Button
                        icon={<DeleteOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.DELETE)}
                        onClick={() => {
                            confirm({
                                title: `Are you sure you want to delete ${formValues.name}?`,
                                icon: <ExclamationCircleOutlined />,
                                okType: 'danger',
                                onOk() {
                                    deleteList(formValues._id);
                                },
                            });
                        }}
                    >
                    </Button>
                </span>,
        },
    ];

    useEffect(() => {
        refreshList();
    }, []);
    return (
        <div>
            <Title><FormattedMessage id='actionModuleName' /></Title>
            <Button
                shape='circle'
                size='large'
                icon={<PlusOutlined />}
                onClick={() => showDrawer()}
                type='primary'
                disabled={!userActions.includes(ACTION_KEY.CREATE)}
                className='plusIcon'
            >
            </Button>
            <Table
                className='menuTable'
                rowKey={(data: { _id: string }) => data._id}
                columns={columns as Array<object>}
                dataSource={actions as Array<{ _id: string }>}
                pagination={pagination}
                onChange={paginationInfo => setPaginationParams(paginationInfo)}
            />
            {
                visible &&
                <DrawerForm
                    {...drawer}
                    visible
                    onClose={onClose}
                >
                    <ActionForm
                        onClose={onClose}
                        isCreate={drawer.isCreate}
                        actionId={drawer.actionId}
                        onSubmit={onSubmit}
                    />
                </DrawerForm>
            }
        </div>
    );
};

export default Action;
