import { createAction, handleActions } from 'redux-actions';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/constants';
import { UserProfileModel } from '../../models/user';

const { CREATE_USER, UPDATE_USER, DELETE_USER, ALL_USER, GET_USERPROFILE, GET_USERSTUDY_TIME, UPDATE_USER_ROLE, GET_USER_ACTIONS } = CONSTANTS;

// initialState
const initialState = {
    users: [],
    currentUser: null,
    userProfile: {},
    userStudyTime: {},
    userActions: [],
};

interface Action {
    type: string
    payload: any
}

export default handleActions(
    {
        [CREATE_USER](state) {
            return {
                ...state,
            };
        },
        [UPDATE_USER](state, action: Action) {
            return {
                ...state,
                currentUser: action.payload,
            };
        },
        [DELETE_USER](state) {
            return {
                ...state,
            };
        },
        [ALL_USER](state, action: Action) {
            return {
                ...state,
                users: action.payload,
                currentUser: state.currentUser || action.payload[0],
            };
        },
        [GET_USERPROFILE](state, action: Action) {
            return {
                ...state,
                userProfile: action.payload,
            };
        },
        [GET_USERSTUDY_TIME](state, action: Action) {
            return {
                ...state,
                userStudyTime: action.payload,
            };
        },
        [UPDATE_USER_ROLE](state) {
            return {
                ...state,
            };
        },
        [GET_USER_ACTIONS](state, action: Action) {
            const userActions = action.payload.map((item: any) => item.key);
            return {
                ...state,
                userActions,
            };
        },
    },
    initialState
);

/**
 * action creators
 * */
export const actions = {
    createUser: createAction(CREATE_USER, (user: any) => APIServices.createUser(user)),
    updateUser: createAction(UPDATE_USER, async (user: UserProfileModel) => (await APIServices.updateUser(user)).data),
    deleteUser: createAction(DELETE_USER, async (user: string) => await APIServices.deleteUser(user)),
    getUsers: createAction(ALL_USER, async (pagination: { page: number, pageSize: number }) => {
        return (await APIServices.getUsers(pagination)).data;
    }),
    getUserProfile: createAction(GET_USERPROFILE, async (userId: string) => (await APIServices.getUserProfile(userId)).data),
    getUserStudyTime: createAction(GET_USERSTUDY_TIME, async (userId: string) => (await APIServices.getStudyTime(userId)).data || (await APIServices.createStudyTime({ user: userId })).data),
    getUserActions: createAction(GET_USER_ACTIONS, async (id: string) => (await APIServices.getUserActions(id)).data),
};
