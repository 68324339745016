import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../redux/modules/client';
import DrawerForm from '../../components/basic/drawer';
import ClientForm from './components/clientForm';
import { Typography, notification, Button, Modal, Table } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import CONSTANTS from '../../lib/constants';
import { ClientModel } from '../../models/client';
import Common from '../../lib/common';
import APIServices from '../../lib/APIServices';
import './styles/index.css';

const { ACTION_KEY } = CONSTANTS;
const { getPageTitleName } = Common;
const { confirm } = Modal;
const { Title } = Typography;
const { createClient, getClients, updateClient } = actions;
interface Pagination {
    current: number
    pageSize: number
    showSizeChanger?: boolean
    total?: number
}
interface DefaultRootState {
    client: {
        clients: Array<ClientModel>
    }
    user: {
        userActions: Array<number>
    }
}

interface DrawerInfo {
    isCreate: boolean
    title: any,
    type: string
    initialValues?: ClientModel
}

const Client = () => {
    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const [drawer, setDrawer] = useState<DrawerInfo>({
        isCreate: true,
        title: '',
        type: '',
    });
    const [visible, setVisible] = useState<boolean>(false);
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();
    const { clients, userActions } = useSelector((state: DefaultRootState) => ({
        clients: state.client.clients.map(item => (
            {
                ...item,
                clientType: (item.clientType || item.clientType === 0) && ['ADMIN', 'MINI', 'APP'][item.clientType],
            }
        )),
        userActions: state.user.userActions,
    }));
    const dispatch = useDispatch();

    // config table title
    const titleData = ['clientModuleName', 'clientName', 'clientType', 'clientId', 'clientSecret', 'action', 'deleteTip', 'cancel', 'submit', 'create'];
    const titleName = getPageTitleName(titleData, intl.formatMessage);
    const refreshList = () => {
        dispatch(getClients({ page: pagination.current, pageSize: pagination.pageSize }));
    };

    const showDrawer = (item?: ClientModel) => {
        const data: any = item && item._id
            ? {
                title: intl.formatMessage({ id: 'updateClient' }),
                type: 'update',
                initialValues: { ...item, clientType: item.clientType?.toString() },
            }
            : {
                title: intl.formatMessage({ id: 'createClient' }),
                type: 'create',
                visible: true,
            };
        setDrawer(data);
        setVisible(true);
    };

    const onClose = () => {
        setDrawer({ ...drawer, initialValues: undefined });
        setVisible(false);
    };

    const onSubmit = (values: ClientModel) => {
        if (values._id) {
            dispatch(updateClient(values));
        } else {
            dispatch(createClient({ ...values, grants: ['authorization_code', 'password', 'refresh_token', 'client_credentials'] }));
        }
        onClose();
        refreshList();
    };

    const deleteList = async (id: string) => {
        await APIServices.deleteClient(id);
        notification.success({
            message: 'Success Delete',
            placement: 'topRight',
        });
        refreshList();
    };

    const setPaginationParams = (paginationInfo: any) => {
        setPagination(paginationInfo);
        refreshList();
    };

    const columns = [
        {
            title: titleName.clientName,
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: titleName.clientType,
            dataIndex: 'clientType',
            key: 'clientType',
        },
        {
            title: titleName.clientId,
            dataIndex: 'clientId',
            key: 'clientId',
        },
        {
            title: titleName.clientSecret,
            dataIndex: 'clientSecret',
            key: 'clientSecret',
        },
        {
            title: titleName.action,
            key: 'action',
            width: 200,
            fixed: 'right',
            // eslint-disable-next-line react/display-name
            render: (text: {}, formValues: ClientModel) =>
                <span>
                    <Button
                        icon={<EditOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.EDIT)}
                        onClick={() => showDrawer(formValues)}
                    >
                    </Button>
                    <Button
                        icon={<DeleteOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.DELETE)}
                        onClick={() => {
                            if (formValues.clientName) {
                                confirm({
                                    title: `Are you sure you want to delete ${formValues.clientName}?`,
                                    icon: <ExclamationCircleOutlined />,
                                    okType: 'danger',
                                    onOk() {
                                        if (formValues._id) {
                                            deleteList(formValues._id);
                                        }
                                    },
                                });
                            }
                        }}
                    >
                    </Button>
                </span>,
        },
    ];
    useEffect(() => {
        refreshList();
    }, []);
    return (
        <div>
            <Title>{titleName.clientModuleName}</Title>
            <Button
                shape='circle'
                size='large'
                icon={<PlusOutlined />}
                onClick={() => showDrawer()}
                type='primary'
                disabled={!userActions.includes(ACTION_KEY.CREATE)}
                className='plusIcon'
            />
            <Table
                className='clientTable'
                rowKey={(data: { _id: string }) => data._id}
                columns={columns as Array<object>}
                dataSource={clients as Array<{ _id: string }>}
                pagination={pagination}
                onChange={paginationInfo => setPaginationParams(paginationInfo)}
            />
            {
                visible &&
                <DrawerForm
                    {...drawer}
                    visible
                    onClose={onClose}
                >
                    <ClientForm
                        onClose={onClose}
                        initialValues={drawer.initialValues}
                        onSubmit={onSubmit}
                    />
                </DrawerForm>
            }
        </div>
    );
};

export default Client;
