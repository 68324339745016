import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, Input, InputNumber } from 'antd';
import UploadFile from '../../../components/basic/uploadFile';
import { AvatarModel } from '../../../models/course';
import '../styles/index.css';
import APIServices from '../../../lib/APIServices';

interface Props {
    initialValues: any | undefined
    onSubmit: (user: any) => void
    onClose: () => void
}
const WordForm = (props: Props) => {
    const [form] = Form.useForm();
    const initFile = { _id: '', url: '' };
    const [cover, setCover] = useState(initFile);
    const [audio, setAudio] = useState(initFile);

    const onFinish = (values: any) => {
        values._id = props.initialValues && props.initialValues._id;
        // cover.url && (values.cover = cover);
        // audio.url && (values.audio = audio);
        const phonetic = {
            local: values.local || '',
            value: values.value || '',
        };

        const analysis = {
            partOfSpeech: values.partOfSpeech || '',
            translate: values.translate || '',
        };

        const deleteData = ['local', 'value', 'partOfSpeech', 'translate'];
        deleteData.map(data => {
            delete values[data];
        });

        props.onSubmit({ ...values, phonetic, analysis });
    };

    const onFieldsChange = async () => {
        if (props.initialValues && props.initialValues._id) {
            const newData = (await APIServices.getWord(props.initialValues._id)).data;
            const newPhonetic = newData.phonetic[0];
            const newAnalysis = newData.analysis[0];

            form.setFieldsValue({
                ...props.initialValues,
                local: newPhonetic.local,
                value: newPhonetic.value,
                partOfSpeech: newAnalysis.partOfSpeech,
                translate: newAnalysis.translate,
            });
        } else {
            form.resetFields();
        }
    };

    useEffect(() => {
        onFieldsChange();
    }, []);
    return (
        <Form
            layout='vertical'
            // hideRequiredMark
            // initialValues={props.initialValues}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='content'
                        label='Content'
                        rules={[{ required: true, message: 'Please enter the value of the word' }]}
                    >
                        <Input placeholder='Please enter the content of the word' />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name='plural'
                        label='复数'
                    >
                        <Input placeholder='Please enter word plural' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='local'
                        label='类别'
                        rules={[{ required: false, message: 'Please enter the phonetic of the word' }]}
                    >
                        <Input placeholder='Please enter the content of the word' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='value'
                        label='音标'
                        rules={[{ required: false, message: 'Please enter the phonetic of the word' }]}
                    >
                        <Input placeholder='Please enter the content of the word' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='partOfSpeech'
                        label='词性'
                        rules={[{ required: false, message: 'Please enter the phonetic of the word' }]}
                    >
                        <Input placeholder='Please enter the content of the word' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='translate'
                        label='翻译'
                        rules={[{ required: false, message: 'Please enter the phonetic of the word' }]}
                    >
                        <Input placeholder='Please enter the content of the word' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='audio'
                        label='Audio'
                        rules={[{ required: false, message: 'Please enter the audio of the word' }]}
                    >
                        <Input name='value' placeholder='Please enter the audio of the word' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16} hidden>
                <Col span={12}>
                    <Form.Item
                        name='audio'
                        label='Audio'
                        rules={[{ required: false, message: 'Please enter the audio of the word' }]}
                    >
                        <UploadFile
                            type='audio'
                            dataSources={props.initialValues && props.initialValues.audio}
                            uploadSuccess={(file: AvatarModel) => setAudio(file)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24} className='btn'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type='primary' htmlType='submit' className='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default WordForm;
