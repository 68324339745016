import React, { Component, MouseEvent } from 'react';
import { Card, Modal, Button } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import DrawerForm from '../../../components/basic/drawer';
import AddressForm from './addressForm';
import APIServices from '../../../lib/APIServices';
import { UserAddressModel } from '../../../models/user';
const { confirm } = Modal;

interface Props {
    userId: string
}

class UserAddress extends Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = { visiable: false };
    }

    refreshList = async () => {
        const addressList = (await APIServices.getAddress(this.props.userId)).data;
        this.setState({
            addressList,
        });
    }

    componentDidMount() {
        this.refreshList();
    }

    onClose = () => {
        this.setState({
            visible: false, initialValues: undefined,
        });
    }

    onSubmit = async (values: UserAddressModel) => {
        values._id
            ? await APIServices.updateAddress(values)
            : await APIServices.createAddress(values, this.props.userId);
        this.onClose();
        this.refreshList();
    }

    editAddress = (e: MouseEvent, item?: UserAddressModel) => {
        e.stopPropagation();
        const data = item && item._id
            ? {
                title: 'Update Address',
                type: 'update',
                visible: true,
                initialValues: item,
            }
            : {
                title: 'Create Address',
                type: 'create',
                visible: true,
            };
        this.setState(data);
    }
    render() {
        if (this.state.visible) {
            return (
                <DrawerForm
                    {...this.state}
                    onClose={this.onClose}
                >
                    <AddressForm
                        onClose={this.onClose}
                        initialValues={this.state.initialValues}
                        onSubmit={this.onSubmit}
                    />
                </DrawerForm>
            );
        }

        const { addressList } = this.state;
        const { refreshList } = this;
        return (
            <div>
                {
                    addressList && addressList.map((address: any) =>
                        <Card
                            key={address._id}
                            style={{ marginTop: 16 }}
                            type='inner'
                            title={address.name}
                            extra={[
                                <EditOutlined onClick={e => this.editAddress(e, address)} />,
                                <DeleteOutlined className='left-margin order-delete' onClick={e => {
                                    e.stopPropagation();
                                    return confirm({
                                        title: 'Are you sure you want to delete this order?',
                                        icon: <ExclamationCircleOutlined />,
                                        okType: 'danger',
                                        async onOk() {
                                            await APIServices.deleteAddress(address._id);
                                            refreshList();
                                        },
                                    });
                                }} />,
                            ]}
                        >
                            <p><strong>Phone: </strong>{address.phone}</p>
                            <p><strong>Area: </strong>{address.area}</p>
                            <p><strong>Details: </strong>{address.details}</p>
                        </Card>
                    )
                }
                <Button type='primary' block onClick={this.editAddress} >
                    Add
                </Button>
            </div>
        );
    }
}

export default UserAddress;
