import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.min.css';
import DrawerForm from '../../components/basic/drawer';
import ResourceForm from './components/resourceForm';
import { Typography, notification, Button, Table, Modal, Tag } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import APIServices from '../../lib/APIServices';
import { ActionModel } from '../../models/action';
import { ResourceModel } from '../../models/resource';
import CONSTANTS from '../../lib/constants';
import Common from '../../lib/common';

import './styles/index.css';

const { confirm } = Modal;
const { getPageTitleName } = Common;
const { Title } = Typography;
const { ACTION_KEY } = CONSTANTS;

interface Pagination {
    current: number
    pageSize: number
    showSizeChanger?: boolean
    total?: number
}

interface DefaultRootState {
    user: {
        userActions: Array<number>
    }
}

interface DrawerInfo {
    isCreate: boolean
    title: any,
    type: string
    resource?: any
}

const Resource = () => {
    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: 5,
        showSizeChanger: true,
    });
    const [drawer, setDrawer] = useState<DrawerInfo>({
        isCreate: true,
        title: '',
        type: '',
    });
    const [visible, setVisible] = useState<boolean>(false);
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();
    const { userActions } = useSelector((state: DefaultRootState) => ({
        userActions: state.user.userActions,
    }));

    const [resources, setResources] = useState<Array<ActionModel>>([]);
    // config table title
    const titleData = ['deleteTip', 'roleNumber', 'roleName', 'roleNamePlaceholder', 'action'];
    const titleName = getPageTitleName(titleData, intl.formatMessage);

    const refreshList = async () => {
        const { data } = await APIServices.getResources({ page: pagination.current, pageSize: pagination.pageSize });
        setResources(data);
    };

    const showDrawer = (item?: ResourceModel) => {
        const data = item && item._id
            ? {
                title: intl.formatMessage({ id: 'updateResource' }),
                type: 'update',
                resource: item,
                isCreate: false,
            }
            : {
                title: intl.formatMessage({ id: 'createResource' }),
                type: 'create',
                isCreate: true,
            };
        setDrawer(data);
        setVisible(true);
    };

    const onClose = () => {
        setDrawer({ ...drawer, resource: '' });
        setVisible(false);
    };

    const onSubmit = async (values: any, isCreate: boolean) => {
        // create action
        if (isCreate) {
            const createData = {
                method: values.method,
                path: values.path,
                service: 0,
            };
            await APIServices.createResource(createData);
            notification.success({
                message: 'Create Success',
                placement: 'topRight',
            });
        } else {
            // update
            await APIServices.updateResource({ _id: values._id, resource: values });
            notification.success({
                message: 'Update Success',
                placement: 'topRight',
            });
        }
        onClose();
        refreshList();
    };

    const deleteList = async (id: string) => {
        await APIServices.deleteResource(id);
        notification.success({
            message: 'Delete Success',
            placement: 'topRight',
        });
        refreshList();
    };

    const setPaginationParams = (paginationInfo: any) => {
        setPagination(paginationInfo);
        refreshList();
    };

    const columns = [
        {
            title: '方法名',
            dataIndex: 'method',
            key: 'method',
        },
        {
            title: '路径',
            dataIndex: 'path',
            key: 'path',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            // eslint-disable-next-line react/display-name
            render: (text: {}, formValues: ResourceModel) => {
                if (formValues.action) {
                    return (
                        <Tag color='success'>
                            {formValues.action.name}
                        </Tag>
                    );
                }
            },
        },
        {
            title: titleName.action,
            key: 'actions',
            fixed: 'right',
            // eslint-disable-next-line react/display-name
            render: (text: {}, formValues: ResourceModel) =>
                <span>
                    <Button
                        icon={<EditOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.EDIT)}
                        onClick={() => showDrawer(formValues)}
                    ></Button>
                    <Button
                        icon={<DeleteOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.DELETE)}
                        onClick={() => {
                            confirm({
                                title: `Are you sure you want to delete ${formValues.path}?`,
                                icon: <ExclamationCircleOutlined />,
                                okType: 'danger',
                                onOk() {
                                    deleteList(formValues._id);
                                },
                            });
                        }}
                    >
                    </Button>
                </span>,
        },
    ];

    useEffect(() => {
        refreshList();
    }, []);
    return (
        <div>
            <Title><FormattedMessage id='resourceModuleName' /></Title>
            <Button
                shape='circle'
                size='large'
                icon={<PlusOutlined />}
                onClick={() => showDrawer()}
                type='primary'
                disabled={!userActions.includes(ACTION_KEY.CREATE)}
                className='plusIcon'
            >
            </Button>
            <Table
                className='menuTable'
                rowKey={(data: { _id: string }) => data._id}
                columns={columns as Array<object>}
                dataSource={resources as Array<{ _id: string }>}
                pagination={pagination}
                onChange={paginationInfo => setPaginationParams(paginationInfo)}
            />
            {
                visible &&
                <DrawerForm
                    {...drawer}
                    visible
                    onClose={onClose}
                >
                    <ResourceForm
                        onClose={onClose}
                        isCreate={drawer.isCreate}
                        resource={drawer.resource}
                        onSubmit={onSubmit}
                    />
                </DrawerForm>
            }
        </div>
    );
};

export default Resource;
