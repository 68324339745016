import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Input, Button, Radio, Table, Select } from 'antd';
import { useIntl } from 'react-intl';
import { RoleModel } from '../../../models/role';
import APIServices from '../../../lib/APIServices';
import '../styles/index.css';

const { Option } = Select;
interface Props {
    roleId?: string
    isCreate: boolean
    onSubmit: (data: RoleModel, isCreate: boolean) => void
    onClose: () => void
    treeData: any
}

const pagination = {
    current: 1,
    pageSize: 50,
    showSizeChanger: false,
};
const RoleForm = (props: Props) => {
    const [form] = Form.useForm();
    const [menus, setMenus] = useState<Array<string | any>>([]);
    const [roleMenus, setRoleMenus] = useState<Array<string | any>>([]);
    const [actions, setActions] = useState<Array<string | any>>([]);
    const [roleActions, setRoleActions] = useState<Array<string | any>>([]);
    const [roleKey, setRoleKey] = useState<number>(0);
    const intl = useIntl();

    // multi-language
    const titleData = ['roleName', 'roleNamePlaceholder', 'submit', 'menuPower', 'action', 'cancel', 'submit'];
    const titleName: any = {};
    titleData.forEach((text: string) => titleName[text] = intl.formatMessage({ id: text }));

    // role menu
    const columns = [
        {
            title: titleName.menuPower,
            dataIndex: 'title',
            key: 'title',
        },
    ];
    const onFinish = (values: any) => {
        values.menus = roleMenus;
        values.actions = roleActions;
        if (!props.isCreate) { values._id = props.roleId; }
        props.onSubmit(values, props.isCreate);
    };

    const rowSelection = {
        selectedRowKeys: roleMenus,
        onChange: (keys: any, selectedRows: any) => {
            setMenus(selectedRows);
            setRoleMenus(keys);
        },
        onSelectAll: (selected: boolean, selectedRows: Array<any>) => {
            if (selected) {
                const menuActionId = selectedRows.map((row: any) => row._id);
                // set meu key
                setMenus(selectedRows);
                setRoleMenus(menuActionId);
            } else {
                setMenus([]);
                setRoleMenus([]);
            }
        },
    };
    const getActions = async () => {
        const data = (await APIServices.getAllAction({ page: 1, pageSize: 100 })).data || [];
        setActions(data);
    };

    const options = actions.map((data: any) => <Option key={data._id} value={data._id}>{data.name}</Option>);

    const onFieldsChange = async () => {
        if (!props.isCreate && props.roleId) {
            const { roleId } = props;
            const newData: RoleModel = (await APIServices.getRoleDetail(roleId)).data;
            const newRoleAction = newData.actions.map((action: any) => action.action);
            const newRoleMenus = newData.menus.map((menu: any) => menu._id);
            setRoleMenus(newRoleMenus);
            setRoleActions(newRoleAction);
            setRoleKey(newData.key);
            form.setFieldsValue({ ...newData, roleActions: newRoleAction, roleMenus: newRoleMenus });
        } else {
            form.resetFields();
        }
    };

    useEffect(() => {
        onFieldsChange();
        getActions();
    }, []);
    return (
        <Form
            layout='vertical'
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='name'
                        label={titleName.roleName}
                        rules={[{ required: true, message: '角色名称不能为空' }]}
                    >
                        <Input placeholder={titleName.roleNamePlaceholder} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='key'
                        label='角色Key:'
                        rules={[{ required: false }]}
                    >
                        <Select placeholder='请选择角色Key' disabled={roleKey === 3}>
                            <Option value={0}>USER</Option>
                            <Option value={1}>ADMIN</Option>
                            <Option value={2}>SERVICE</Option>
                            <Option value={3}>SUPERUSER</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='status'
                        label='状态:'
                    >
                        <Radio.Group>
                            <Radio value={0}>停用</Radio>
                            <Radio value={1}>正常</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='roleActions'
                        label='操作:'
                        rules={[{ required: false, type: 'array' }]}
                    >
                        <Select
                            mode='multiple'
                            style={{ width: '100%' }}
                            placeholder='请选择操作'
                            defaultValue={roleActions}
                            onChange={selectKeys => { setRoleActions(selectKeys); }}
                        >
                            {options}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>

            </Row> <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name='roleMenus'
                        label='菜单权限:'
                        rules={[{ required: false, type: 'array' }]}
                    >
                        <Table
                            className='menuTable'
                            rowKey={(data: { _id: string }) => data._id}
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                                checkStrictly: false,

                            }}
                            expandable={{
                                defaultExpandAllRows: true,
                            }}
                            columns={columns as Array<object>}
                            dataSource={props.treeData as Array<{ _id: string }>}
                            pagination={pagination}
                        />

                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
            </Row>
            <Row gutter={16} className='action-btn'>
                <Col span={24} className='text-right'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            {titleName.cancel}
                        </Button>
                        <Button type='primary' htmlType='submit' className='mar-left'>
                            {titleName.submit}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default RoleForm;
