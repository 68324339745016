import React from 'react';
import { Card, Col, Row, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { PictureBookModel } from '../../../models/course';
const { confirm } = Modal;
const { Meta } = Card;

interface Props {
    dataSource: PictureBookModel[]
    deleteList: (id: string) => void
    edit: (item: object) => void
}

const PictureBookList = (props: Props) =>
    <div className='site-card-wrapper'>
        <Row gutter={16}>{
            props.dataSource.map((item: PictureBookModel) =>
                <Col span={5} key={item._id}>
                    <Card
                        hoverable
                        className='cardCover'
                        cover={<img alt='Picture' src={item.picture.url} />}
                        actions={[
                            <EditOutlined
                                onClick={() => props.edit(item)}
                            />,
                            <DeleteOutlined
                                onClick={() => confirm({
                                    title: 'Are you sure you want to delete this picture?',
                                    icon: <ExclamationCircleOutlined />,
                                    okType: 'danger',
                                    onOk() {
                                        props.deleteList(item._id);
                                    },
                                })}
                            />,
                        ]}
                    >
                        <Meta title={item.content} />
                    </Card>
                </Col>
            )
        }</Row>
    </div >;


export default PictureBookList;
