import React, { Component, useState } from 'react';
import { Button, Select } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import WordView from './wordView';
import PictureBookView from './pictureBookView';
import 'antd/dist/antd.min.css';

const { Option } = Select;

interface Props {
    history: string[]
    match: {
        params: {
            courseId: string
        }
    }
}
const SectionDetail = (props: Props) => {
    const [page, setPage] = useState('word');
    const backTo = () => {
        props.history.push(`/home/section`);
    }
    return (
        <div>

            <div className='selectBook'>
                <Button type="text" icon={<LeftOutlined />} onClick={backTo}></Button>
                <strong>请选择匹配项:</strong>
                <Select defaultValue='word' style={{ width: 120 }} onChange={event => setPage(event)}>
                    <Option value='word'>Word</Option>
                    <Option value='picture'>Picture Book</Option>
                </Select>
            </div>
            {
                page === 'word'
                    ? <WordView courseId={props.match.params.courseId} />
                    : <PictureBookView courseId={props.match.params.courseId} />
            }
        </div>
    );
};

export default SectionDetail;
