import React, { useState, useEffect, useRef } from 'react';
import 'antd/dist/antd.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actions } from '../../redux/modules/user';
import { actions as actions1 } from '../../redux/modules/role';
import DrawerForm from '../../components/basic/drawer';
import NewUserForm from './components/newUserForm';
import { Typography, notification, Button, Table, Tag, Modal } from 'antd';
import { PlusOutlined, DeleteOutlined, EyeOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserModel } from '../../models/user';

import './styles/index.css';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/constants';
import Common from '../../lib/common';
import CONFIG from '../../config/config';
import CountUser from './components/CountUser';

const { ACTION_KEY } = CONSTANTS;
const { Title } = Typography;
const { confirm } = Modal;
const { getUsers, createUser, deleteUser } = actions;
const { getRoles } = actions1;
const { getPageTitleName } = Common;

interface Pagination {
    current: number
    pageSize: number
    showSizeChanger?: boolean
    total?: number
}
interface DefaultRootState {
    role: {
        roles: Array<any>
    }
    user: {
        users: Array<UserModel>
        userActions: Array<number>
    }
}
interface DrawerInfo {
    isCreate: boolean
    title: any,
    type: string
    initialValues?: UserModel
}

const NewUser = (props: any) => {
    const history = useHistory();
    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const [drawer, setDrawer] = useState<DrawerInfo>({
        isCreate: true,
        title: '',
        type: '',
    });
    const [visible, setVisible] = useState<boolean>(false);
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();
    const { users, userActions, roles } = useSelector((state: DefaultRootState) => ({
        users: state.user.users,
        userActions: state.user.userActions,
        roles: state.role.roles,
    }));
    const dispatch = useDispatch();

    // config table title
    const titleData = ['deleteTip', 'action'];
    const titleName = getPageTitleName(titleData, intl.formatMessage);

    const refreshList = () => {
        dispatch(getUsers({ page: pagination.current, pageSize: pagination.pageSize }))
        if (!roles.length) {
            dispatch(getRoles({ page: 1, pageSize: 100 }));
        }
    };

    const goToDetail = (_id: string) => {
        history.push(`/home/newUser/${_id}`);
    };

    const showDrawer = (item?: UserModel) => {
        const data = item && item._id
            ? {
                title: intl.formatMessage({ id: 'updateUser' }),
                type: 'update',
                initialValues: item,
                isCreate: false,
            }
            : {
                title: intl.formatMessage({ id: 'createUser' }),
                type: 'create',
                isCreate: true,
            };
        setDrawer(data);
        setVisible(true);
    };

    const onClose = () => {
        setDrawer({ ...drawer, initialValues: undefined });
        setVisible(false);
    };
    const onSubmit = async (values: UserModel, isCreate: boolean) => {
        // create
        if (isCreate) {
            const createNewUser = {
                ...values,
                client_id: CONFIG.client_id,
                client_secret: CONFIG.client_secret,
            };
            // eslint-disable-next-line @typescript-eslint/await-thenable
            const newUser = await APIServices.createUser(createNewUser);
            if (newUser.code) {
                notification.error({
                    message: newUser.msg,
                    placement: 'topRight',
                });
            } else {
                notification.success({
                    message: newUser.msg,
                    placement: 'topRight',
                });
            }
        } else {
            // update user
            await APIServices.updateNewUser(values);
            notification.success({
                message: '更新成功',
                placement: 'topRight',
            });
        }
        onClose();
        refreshList();
    };

    const deleteList = (id: string) => {
        dispatch(deleteUser(id));
        notification.success({
            message: 'Delete Success',
            description: '删除成功',
            placement: 'topRight',
        });
        refreshList();
    };

    const setPaginationParams = (paginationInfo: any) => {
        setPagination(paginationInfo);
    };

    const columns = [
        {
            title: '用户名',
            dataIndex: 'email',
            key: 'email',
            render: (text: {}, formValues: any) => formValues.email || formValues.nickName || formValues.unionId,
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '角色',
            dataIndex: 'roles',
            key: 'roles',
            render: (text: {}, formValues: UserModel) => formValues.roles.map((role: { name: string, _id: string }) => <Tag key={role._id} color='success'>{role.name}</Tag>),
        },
        {
            title: titleName.action,
            key: 'action',
            fixed: 'right',
            // eslint-disable-next-line react/display-name
            render: (text: {}, formValues: UserModel) =>
                <span>
                    <Button
                        icon={<EyeOutlined />}
                        type='text'
                        onClick={() => goToDetail(formValues._id)}
                    >
                    </Button>
                    <Button
                        icon={<EditOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.EDIT)}
                        onClick={() => showDrawer(formValues)}
                    >
                    </Button>
                    <Button
                        icon={<DeleteOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.DELETE)}
                        onClick={() => {
                            confirm({
                                title: `Are you sure you want to delete ${formValues.email}?`,
                                icon: <ExclamationCircleOutlined />,
                                okType: 'danger',
                                onOk() {
                                    deleteList(formValues._id);
                                },
                            });
                        }}
                    >
                    </Button>
                </span>,
        },
    ];

    const getUsersTotal = async () => {
        const { count: total } = await APIServices.getUsers()
        setPagination({ ...pagination, total })
    }

    useEffect(() => {
        getUsersTotal()
    }, [])

    useEffect(() => {
        refreshList();
    }, [pagination.current, pagination.pageSize]);

    return (
        <div>
            <Title><FormattedMessage id='userModule' /></Title>
            <Button
                shape='circle'
                size='large'
                icon={<PlusOutlined />}
                onClick={() => showDrawer()}
                type='primary'
                disabled={!userActions.includes(ACTION_KEY.CREATE)}
                className='plusIcon'
            >
            </Button>
            <CountUser users={users} total={pagination.total} />
            <Table
                className='orderTable'
                rowKey={(data: { _id: string }) => data._id}
                columns={columns as Array<object>}
                dataSource={users as Array<{ _id: string }>}
                pagination={pagination}
                onChange={paginationInfo => setPaginationParams(paginationInfo)}
            />
            {
                visible &&
                <DrawerForm
                    {...drawer}
                    visible
                    onClose={onClose}
                >
                    <NewUserForm
                        onClose={onClose}
                        isCreate={drawer.isCreate}
                        initialValues={drawer.initialValues}
                        onSubmit={onSubmit}
                        roles={roles}
                    />
                </DrawerForm>
            }
        </div>
    );
};
export default NewUser;
