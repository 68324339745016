const actions = {
    // auth
    GET_USERDATA: 'auth/get_userdata',
    SET_USERDATA: 'auth/set_userdata',
    PHONE_SIGNUP: 'auth/phone_signup',
    LOGIN: 'auth/phone_login',
    GET_USER_DETAIL: 'auth/gte_user_detail',

    // user
    CREATE_USER: 'user/create_user',
    UPDATE_USER: 'user/update_user',
    DELETE_USER: 'user/delete_user',
    ALL_USER: 'user/all_user',
    GET_USERPROFILE: 'user/get_userProfile',
    GET_USERSTUDY_TIME: 'user/get_user_study_time',
    GET_USER_ACTIONS: 'user/get_user_actions',

    // user role
    GET_USER_ALL_ROLE: 'user/get_user_all_role',
    CREATE_USER_ROLE: 'user/create_user_role',
    UPDATE_USER_ROLE: 'user/update_user_role',
    DELETE_USER_ROLE: 'user/delete_user_role',

    // gloabl
    SET_LOADING: 'global/set_loading',

    // client
    CREATE_CLIENT: 'client/create_client',
    UPDATE_CLIENT: 'client/update_client',
    DELETE_CLIENT: 'client/delete_client',
    ALL_CLIENT: 'client/all_client',

    // book
    CREATE_BOOK: 'book/create_book',
    UPDATE_BOOK: 'book/update_book',
    DELETE_BOOK: 'book/delete_book',
    ALL_BOOK: 'book/all_book',
    SELECT_BOOK: 'book/select_book',

    // course
    CREATE_COURSE: 'course/create_course',
    UPDATE_COURSE: 'course/update_course',
    DELETE_COURSE: 'course/delete_course',
    ALL_COURSE: 'course/all_course',
    SELECT_COURSE: 'course/select_course',
    GET_STUDY_PROGRESS: 'course/get_study_progress',

    // section content
    SECTION_CONTENT: 'lesson/section/content',

    // word
    CREATE_WORD: 'word/create_word',
    UPDATE_WORD: 'word/update_word',
    DELETE_WORD: 'word/delete_word',
    ALL_WORD: 'word/all_word',

    // sentence
    ALL_SENTENCE: 'sentence/all_sentence',
    ALL_CARTOON: 'cartoon/all_cartoon',

    CREATE_PICTURE_BOOK: 'picture/create_picture_book',
    UPDATE_PICTURE_BOOK: 'picture/update_picture_book',
    DELETE_PICTURE_BOOK: 'picture/delete_picture_book',
    ALL_PICTURE_BOOK: 'picture/all_picture_book',

    // good
    CREATE_GOOD: 'good/create_good',
    UPDATE_GOOD: 'good/update_good',
    DELETE_GOOD: 'good/delete_good',
    ALL_GOOD: 'good/all_good',
    MALL_SELECT_GOOD: 'mall/mall_select_good',
    USER_ALL_ORDERS: 'order/user_all_orders',
    USER_SELECT_ORDER: 'order/user_select_order',

    // order
    REFUND_ORDER: 'order/refund_order',
    ALL_ORDER: 'order/all_order',
    ALL_REFUND_ORDER: 'order/all_refund_order',
    UPDATE_ORDER: 'order/update_order',
    DELETE_ORDER: 'order/delete_order',

    // role
    ALL_ROLE: 'role/all_role',
    CREATE_ROLE: 'role/create_role',
    UPDATE_ROLE: 'role/update_role',
    DELETE_ROLE: 'role/delete_role',
    CHANGE_ROLE_STATU: 'role/change_role_statu',

    // menu
    ALL_MENU: 'menu/all_menu',
    CREATE_MENU: 'menu/create_menu',
    UPDATE_MENU: 'menu/update_menu',
    DELETE_MENU: 'menu/delete_menu',
    ALL_MENU_DATA: 'menu/all_menu_data',
    GET_CURRENT_USER_MENUS: 'menu/get_current_user_menus',

    ALL_MEDIAS: 'all_media',

}

const constant = {
    // ACTION
    ACTION_KEY: {
        CREATE: 0,
        EDIT: 1,
        DELETE: 2,
        READY: 3,
    },

    // media
    MEDIAS_TYPE: {
        RECITE: 0,
        SONG: 1,
        VIDEO: 2,
        KISS: 3,
    },

    USER_STATUS: {
        BLOCKED: -1,
        FIRST_LOGIN: 0,
        NORMAL: 1,
        WAIT_CONFIRM_USER: 2,
        WAIT_CONFIRM_PROFILE: 3,
        WAIT_CONFIRM_PHONE: 4,
    }
}

export default {
    ...actions,
    ...constant,
};
