import React from 'react';
import { Form, Col, Row, Input, Button } from 'antd';
import { useIntl } from 'react-intl';

import { OrderModel } from '../../../models/order';

interface Props {
    initialValues?: OrderModel
    onSubmit: (data: OrderModel) => void
    onClose: () => void
}

const OrderForm = (props: Props) => {
    const [form] = Form.useForm();
    const intl = useIntl();

    const titleData = ['orderId', 'cancel', 'submit'];

    const titleName: any = {};
    titleData.forEach((text: string) => titleName[text] = intl.formatMessage({ id: text }));

    const onFinish = (values: any) => {
        values._id = props.initialValues && props.initialValues._id;
        props.onSubmit(values);
    };

    return (
        <Form
            layout='vertical'
            hideRequiredMark
            initialValues={props.initialValues}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='orderCode'
                        label={titleName.orderId}
                        rules={[{ required: true, message: 'Please enter order Code' }]}
                    >
                        <Input placeholder='Please enter order Code' />
                    </Form.Item>
                </Col>
            </Row>
            {
                props.initialValues && props.initialValues.orderState > 1 &&
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name='logisticsCode'
                            label='Logistics Code'
                        >
                            <Input placeholder='Please enter Logistics Code' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name='logisticsCompany'
                            label='Logistics Company'
                        >
                            <Input placeholder='Please enter Logistics Company' />
                        </Form.Item>
                    </Col>
                </Row>

            }
            <Row gutter={16}>
                <Col span={24} className='text-right'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            {titleName.cancel}
                        </Button>
                        <Button type='primary' htmlType='submit' className='mar-left'>
                            {titleName.submit}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default OrderForm;
