import { combineReducers } from 'redux';
import auth from './modules/auth';
import client from './modules/client';
import course from './modules/course';
import mall from './modules/mall';
import user from './modules/user';
import order from './modules/order';
import global from './modules/global';
import role from './modules/role';
import menu from './modules/menu';
import media from './modules/media';

export default function createRootReducer() {
    return combineReducers({
        auth,
        client,
        course,
        mall,
        global,
        order,
        user,
        role,
        menu,
        media,
    } as any);
}
