import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, Input, InputNumber, Select } from 'antd';
import UploadFile from '../../../components/basic/uploadFile';
import { AvatarModel } from '../../../models/course';
import '../styles/index.css';

const { Option } = Select;

interface Props {
    initialValues: any | undefined
    onSubmit: (user: any) => void
    onClose: () => void
}
const NameForm = (props: Props) => {
    const [form] = Form.useForm();
    const initFile = { _id: '', url: '' };
    const [audio, setAudio] = useState(initFile);

    const onFinish = (values: any) => {
        values._id = props.initialValues && props.initialValues._id;
        audio.url && (values.audio = audio);

        props.onSubmit(values);
    };

    const onFieldsChange = async () => {
        if (props.initialValues && props.initialValues._id) {
            form.setFieldsValue(props.initialValues);
        } else {
            form.resetFields();
        }
    };

    useEffect(() => {
        onFieldsChange();
    }, []);
    return (
        <Form
            layout='vertical'
            // hideRequiredMark
            // initialValues={props.initialValues}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='englishName'
                        label='英文名'
                        rules={[{ required: true, message: 'Please enter the English Name' }]}
                    >
                        <Input placeholder='English Name' />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name='englishCode'
                        label='英文名编码'
                        rules={[{ required: true, message: 'Please enter the English Code' }]}
                    >
                        <Input placeholder='English Code' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='translation'
                        label='译文'
                        rules={[{ required: true, message: 'Please enter the translation' }]}
                    >
                        <Input placeholder='Translation' />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name='translationCode'
                        label='译文编码'
                        rules={[{ required: true, message: 'Please enter the translation Code' }]}
                    >
                        <Input placeholder='Translation Code' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={6}>
                    <Form.Item
                        name='spell'
                        label='拼音'
                        rules={[{ required: false, message: 'Please enter the spell' }]}
                    >
                        <Input name='value' placeholder='Spell' />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name='gender'
                        label='性别'
                    >
                        <Select className='sexOption' placeholder='Please enter gender'>
                            <Option value='0'>Woman</Option>
                            <Option value='1'>Man</Option>
                            <Option value='2'>Secret</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='audio'
                        label='Audio'
                        rules={[{ required: false, message: 'Please enter the audio of the name' }]}
                    >
                        <UploadFile
                            type='audio'
                            dataSources={props.initialValues && props.initialValues.audio}
                            uploadSuccess={(file: AvatarModel) => setAudio(file)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24} className='btn'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type='primary' htmlType='submit' className='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default NameForm;
