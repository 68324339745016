import React, { useState } from 'react';
import { Form, Col, Row, Input, Button, Radio, TreeSelect } from 'antd';
import SelectIcon from './selectIcon';
import { useIntl } from 'react-intl';
import { MenuModel } from '../../../models/menu';
import Common from '../../../lib/common';

const { getPageTitleName } = Common;
interface Props {
    initialValues?: MenuModel
    isCreate: boolean
    onSubmit: (data: MenuModel, isCreate: boolean) => void
    onClose: () => void
    menuData: Array<MenuModel>
}

const MenuForm = (props: Props) => {
    const [form] = Form.useForm();
    const [menuIcon, setMenuIcon] = useState<string>('');
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();
    const titleData = ['topMenu', 'topMenuPlaceholder', 'menuName', 'menuIcon', 'menuType', 'menuIconPlaceholder', 'menuRouterName', 'menuRouterNamePlaceholder', 'menuComponentPath', 'menuComponentPathPlaceholder', 'menuComponentRouter', 'menuComponentRouterPlaceholder', 'menuStatus', 'show', 'hidden', 'cancel', 'submit'];

    const titleName = getPageTitleName(titleData, intl.formatMessage);
    titleData.forEach((text: string) => titleName[text] = intl.formatMessage({ id: text }));

    const onFinish = (values: any) => {
        values.menuPower = menuIcon;
        if (!props.isCreate) {
            values._id = props.initialValues && props.initialValues._id;
        }
        props.onSubmit(values, props.isCreate);
    };

    const selectIcon = (values: string) => {
        setMenuIcon(values);
    };

    return (
        <Form
            layout='vertical'
            // hideRequiredMark
            initialValues={props.initialValues}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16} hidden>
                <Col span={24}>
                    <Form.Item
                        name='parentId'
                        label={titleName.topMenu}
                        rules={[{ required: false }]}
                    >
                        <TreeSelect
                            treeData={props.menuData}
                            treeDefaultExpandAll={true}
                            placeholder={titleName.topMenuPlaceholder}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name='title'
                        label={titleName.menuName}
                        rules={[{ required: true, message: '菜单名称不能为空' }]}
                    >
                        <Input placeholder='请输入菜单名称' />
                    </Form.Item>
                </Col>
                {/* <Col span={12}>
                    <Form.Item
                        name='iconName'
                        label={titleName.menuIcon}
                        rules={[{ required: false }]}
                    >
                        <SelectIcon selectIcon={selectIcon} menuIcon={props.initialValues && props.initialValues.iconName} />
                    </Form.Item>
                </Col> */}
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='routeName'
                        label={titleName.menuRouterName}
                        rules={[{ required: true, message: '路由不能为空' }]}
                    >
                        <Input placeholder={titleName.menuRouterNamePlaceholder} />
                    </Form.Item>
                </Col>
                {/* <Col span={12}>
                    <Form.Item
                        name='routePath'
                        label={titleName.menuComponentPath}
                        rules={[{ required: false, message: 'Please enter component path' }]}
                    >
                        <Input placeholder={titleName.menuComponentPathPlaceholder} />
                    </Form.Item>
                </Col> */}
                <Col span={12}>
                    <Form.Item
                        name='routeComponent'
                        label={titleName.menuComponentRouter}
                        rules={[{ required: true, message: '路由地址不能为空' }]}
                    >
                        <Input placeholder={titleName.menuComponentRouterPlaceholder} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='visible'
                        label={titleName.menuStatus}
                        rules={[{ required: true, message: '状态不能为空' }]}
                    >
                        <Radio.Group>
                            <Radio value={true}>{titleName.show}</Radio>
                            <Radio value={false}>{titleName.hidden}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24} className='text-right'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            {titleName.cancel}
                        </Button>
                        <Button type='primary' htmlType='submit' className='mar-left'>
                            {titleName.submit}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default MenuForm;
