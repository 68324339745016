import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../redux/modules/course';
import DrawerForm from '../../components/basic/drawer';
import LessonForm from './components/lessonForm';
import { Typography, notification, Table, Modal, Button, message } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { BookModel } from '../../models/course';
import Common from '../../lib/common';
import CONSTANTS from '../../lib/constants';
import './styles/index.css';
import APIServices from '../../lib/APIServices';

const { Title } = Typography;
const { ACTION_KEY } = CONSTANTS;
const { confirm } = Modal;
const { createLesson, getAllLesson, deleteLesson, updateLesson } = actions;
const { getPageTitleName } = Common;


interface Pagination {
    current: number
    pageSize: number
    showSizeChanger?: boolean
    total?: number
}
interface DefaultRootState {
    user: {
        userActions: Array<number>
    }
    course: {
        allLesson: Array<any>
    }
}

interface DrawerInfo {
    // isCreate: boolean
    title: any,
    type: string
    initialValues?: any | undefined
}

const Lesson = () => {
    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const [drawer, setDrawer] = useState<DrawerInfo>({
        // isCreate: true,
        title: '',
        type: '',
    });
    const [visible, setVisible] = useState<boolean>(false);
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();
    const { allLesson, userActions } = useSelector((state: DefaultRootState) => ({
        allLesson: state.course.allLesson,
        userActions: state.user.userActions,
    }));
    const dispatch = useDispatch();

    // config table title
    const titleData = ['lessonName', 'deleteTip', 'action'];
    const titleName = getPageTitleName(titleData, intl.formatMessage);

    const refreshList = async () => {
        dispatch(getAllLesson({ page: pagination.current, pageSize: pagination.pageSize }));
    };

    const deleteList = async (id: string) => {
        // await this.props.deleteLesson(id);
        dispatch(deleteLesson(id))
        notification.success({
            message: 'Delete Success',
            description: '删除成功',
            placement: 'topRight',
        });
        refreshList();
    }

    const showDrawer = (item?: any) => {

        const data = item && item._id
            ? {
                title: intl.formatMessage({ id: 'updateLesson' }),
                type: 'update',
                initialValues: item,
                // isCreate: false,
            }
            : {
                title: intl.formatMessage({ id: 'createLesson' }),
                type: 'create',
                // isCreate: true,
            };
        setDrawer(data);
        setVisible(true);
    };

    const multiCreateLessons = async () => {
        await APIServices.multiCreateLessons()
        message.success('成功生成了五节课程');
        refreshList();
    }

    const onClose = () => {
        setDrawer({ ...drawer, initialValues: '' });
        setVisible(false);
    };

    const createRelation = (id: string, itemIds: any) => {
        if (itemIds.length) {
            APIServices.createLessonCartoons(id, itemIds);
        }
    }

    const onSubmit = async (values: any) => {
        let { _id } = values;
        const { cartoons } = values;
        if (_id) {
            dispatch(updateLesson(values))
        } else {
            dispatch(createLesson(values))
        }

        createRelation(_id, cartoons);

        onClose();
        refreshList();
    }

    const setPaginationParams = (paginationInfo: any) => {
        setPagination(paginationInfo);
    };

    const columns = [
        {
            title: titleName.lessonName,
            dataIndex: 'title',
            key: 'title',
            // sorter: (a: { title: string }, b: { title: string }) => a.title.length - b.title.length,
            // sortOrder: sortedInfo.columnKey === 'lessonName' && sortedInfo.order,
        },
        {
            title: titleName.action,
            key: 'action',
            width: 200,
            fixed: 'right',
            render: (text: {}, formValues: any) =>
                <span>
                    <Button
                        icon={<EditOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.EDIT)}
                        onClick={() => showDrawer(formValues)}
                    ></Button>
                    <Button
                        icon={<DeleteOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.DELETE)}
                        onClick={() => {
                            confirm({
                                title: `Are you sure you want to delete ${formValues.title}?`,
                                icon: <ExclamationCircleOutlined />,
                                okType: 'danger',
                                onOk() {
                                    deleteList(formValues._id);
                                },
                            });
                        }}
                    >
                    </Button>
                </span>
            ,
        },
    ];

    useEffect(() => {
        refreshList();
    }, [pagination.current, pagination.pageSize]);

    return (
        <>
            <Title><FormattedMessage id='lessonModuleName' /></Title>
            <Button
                shape='circle'
                size='large'
                icon={<PlusOutlined />}
                onClick={() => showDrawer()}
                type='primary'
                disabled={!userActions.includes(ACTION_KEY.CREATE)}
                className='plusIcon'
            />
            <Button
                icon={<PlusOutlined />}
                onClick={multiCreateLessons}
                type='default'
                className='plusIcon'
                style={{ marginLeft: '10px' }}
            >一键生成六个单元</Button>
            <Table
                className='clientTable'
                rowKey={(data: { _id: string }) => data._id}
                columns={columns as Array<object>}
                dataSource={allLesson as Array<{ _id: string }>}
                pagination={pagination}
                onChange={paginationInfo => setPaginationParams(paginationInfo)}
            />
            {
                visible &&
                <DrawerForm
                    {...drawer}
                    visible
                    onClose={onClose}
                >
                    <LessonForm
                        onClose={onClose}
                        initialValues={drawer.initialValues}
                        onSubmit={onSubmit}
                    />
                </DrawerForm>
            }
        </>
    )
}

export default Lesson;
