import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.min.css';
import { Typography, notification, Button, Pagination } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import GoodList from './components/goodList';
import DrawerForm from '../../components/basic/drawer';
import FormItem from './components/formItem';
import { FormattedMessage } from 'react-intl';

import CONSTANTS from '../../lib/constants';
import { MallModel } from '../../models/mall';
import { useDispatch, useSelector } from 'react-redux';
import APIServices from '../../lib/APIServices';

const { ACTION_KEY } = CONSTANTS;
const { Title } = Typography;
interface Props {
    goods: Array<MallModel>;
}
interface DrawerInfo {
    // isCreate: boolean
    title: any;
    type: string;
    initialValues?: any | undefined;
}
interface DefaultRootState {
    user: {
        userActions: Array<number>;
    };
}

interface Pagination {
    current: number;
    pageSize: number;
    total?: number;
    hideOnSinglePage?: boolean;
}

const Good = (props: Props) => {
    const [drawer, setDrawer] = useState<DrawerInfo>({
        title: '',
        type: '',
    });
    const [goods, setGroups] = useState<Array<MallModel>>([]);
    const [visible, setVisible] = useState(false);
    const [total, setTotal] = useState(0);

    const { userActions } = useSelector((state: DefaultRootState) => ({
        userActions: state.user.userActions,
    }));
    const dispatch = useDispatch();
    const initPage = {
        current: 1,
        pageSize: 2,
        hideOnSinglePage: true,
    };
    const [pagination, setPagination] = useState<Pagination>(initPage);

    const refreshList = async () => {
        const { current: page, pageSize } = pagination;
        const {
            data: { products: goods, total },
        } = await APIServices.getGoods({ page, pageSize });
        setGroups(goods);
        setTotal(total);
    };

    const onChangePagination = (current: number, pageSize: any) => {
        setPagination({ ...pagination, current, pageSize });
    };

    const deleteList = async (id: string) => {
        await APIServices.deleteGood(id);
        notification.success({
            // message: <FormattedMessage id='deleteSuccess' />,
            message: '删除成功',
            placement: 'topRight',
        });
        refreshList();
    };

    const showDrawer = async (item?: MallModel) => {
        let data: any = {
            title: <FormattedMessage id='createGood' />,
            type: 'create',
        };
        if (item && item._id) {
            // if (item.thumbImage) {
            //     const thumbImage = await APIServices.getFile(item.thumbImage);
            // }
            const tempFiles: any = {};
            if (item.coverImage) {
                const coverImage = (await APIServices.getFile(item.coverImage)).data;
                tempFiles.coverImage = coverImage;
            }
            if (item.carouselImages && item.carouselImages.length) {
                const carouselImages = await Promise.all(item.carouselImages.map(async item => (await APIServices.getFile(item)).data));
                tempFiles.carouselImages = carouselImages;
            }
            data = {
                title: <FormattedMessage id='updateGood' />,
                type: 'update',
                initialValues: { ...item, ...tempFiles },
            };
        }
        console.log(data);
        setDrawer(data);
        setVisible(true);
    };

    const onClose = () => {
        setDrawer({ ...drawer, initialValues: '' });
        setVisible(false);
    };

    const onSubmit = async (good: MallModel) => {
        good._id ? await APIServices.updateGood(good) : await APIServices.createGood(good);
        onClose();
        refreshList();
    };

    useEffect(() => {
        setPagination({ ...initPage, total });
    }, [total]);

    useEffect(() => {
        refreshList();
    }, [pagination]);

    return (
        <div>
            <Title>
                <FormattedMessage id='mallModuleName' />
            </Title>
            <Button
                shape='circle'
                size='large'
                icon={<PlusOutlined />}
                onClick={() => showDrawer()}
                type='primary'
                disabled={!userActions.includes(ACTION_KEY.CREATE)}
                className='plusIcon'
            />
            <GoodList dataSource={goods} deleteList={deleteList} edit={(course: MallModel) => showDrawer(course)} />
            <Pagination style={{ textAlign: 'right' }} {...pagination} onChange={onChangePagination} />
            {visible && (
                <DrawerForm {...drawer} visible onClose={onClose}>
                    <FormItem onClose={onClose} initialValues={drawer.initialValues} onSubmit={onSubmit} />
                </DrawerForm>
            )}
        </div>
    );
};

export default Good;
