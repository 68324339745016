import React, { useState } from 'react';
import { Form, Button, Col, Row, Input, InputNumber } from 'antd';
import UploadFile from '../../../components/basic/uploadFile';
import { AvatarModel, PictureBookModel } from '../../../models/course';
import '../styles/index.css';

interface Props {
    initialValues: PictureBookModel | undefined
    onSubmit: (user: any) => void
    onClose: () => void
}
const PictureBookForm = (props: Props) => {
    const [form] = Form.useForm();
    const initFile = { _id: '', url: '' };
    const [picture, setPicture] = useState(initFile);

    const onFinish = (values: any) => {
        values._id = props.initialValues && props.initialValues._id;
        picture.url && (values.picture = picture);
        props.onSubmit(values);
    };
    return (
        <Form
            layout='vertical'
            hideRequiredMark
            initialValues={props.initialValues}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='content'
                        label='Content'
                        rules={[{ required: true, message: 'Please enter the content' }]}
                    >
                        <Input placeholder='Please enter the content' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='picture'
                        label='Picture'
                    >
                        <UploadFile
                            dataSources={props.initialValues && props.initialValues.picture}
                            uploadSuccess={(file: AvatarModel) => setPicture(file)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24} className='btn'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type='primary' htmlType='submit' className='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default PictureBookForm;
