import React, { useEffect, useState } from 'react';
import { Col, Row, Modal, Pagination, Card, Typography, Image, Radio, Upload, Button, message } from 'antd';
import { UploadOutlined, ClearOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import APIServices from '../../lib/APIServices';
import config from '../../config/config';

const { Title } = Typography;
const { Meta } = Card;

interface Props {
    intl: any;
}
interface FilePagination {
    current: number;
    pageSize: number;
    total?: number;
    hideOnSinglePage?: boolean;
}
const File = (props: Props) => {
    const [files, setFiles] = useState([]);
    const [total, setTotal] = useState(0);
    const [type, setType] = useState<string>('');
    const initPage = {
        current: 1,
        pageSize: 20,
        hideOnSinglePage: true,
    };
    const [pagination, setPagination] = useState<FilePagination>(initPage);

    const onChangePagination = (current: number, pageSize: any) => {
        setPagination({ ...pagination, current, pageSize });
    };

    const getFiles = async () => {
        const { current: page, pageSize } = pagination;
        const { data } = await APIServices.getFiles({ page, pageSize }, type);
        const { files: currentFiles, total: totalCount } = data;
        setFiles(currentFiles);
        setTotal(totalCount);
    };

    const Tool = ({ item: { url, type: fileType } }: { item: { url: string; type: string } }) => {
        const newType = fileType.split('/')[0];
        switch (newType) {
            case 'video':
                return <video width='100%' src={url} controls />;
            case 'audio':
                return <audio src={url} controls />;
            default:
                return <Image width='100%' src={url} />;
        }
    };

    const handleModeChange = (e: any) => {
        const newType = e.target.value;
        setType(newType);
        console.log(newType, ';newType');
    };

    const clearType = () => {
        setType('');
    };

    const uploadProps = {
        name: 'file',
        multiple: true,
        maxCount: 50,
        action: config.uploadFile,
        async onChange(info: any) {
            if (info.file.status === 'done') {
                console.log(info.file.response.data, 'info');
                message.success('批量创建文件资源成功');
                getFiles();
            } else if (info.file.status === 'error') {
                message.error('批量创建文件资源失败');
            }
        },
    };

    useEffect(() => {
        getFiles();
    }, [type]);

    useEffect(() => {
        setPagination({ ...initPage, total });
    }, [total]);

    useEffect(() => {
        getFiles();
    }, [pagination]);

    return (
        <div className='site-card-wrapper'>
            <Title>
                <FormattedMessage id='fileModuleName' />
            </Title>
            <Row>
                <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>批量创建文件资源 (Max: 50)</Button>
                </Upload>
            </Row>
            <Radio.Group onChange={handleModeChange} style={{ marginTop: 20, marginBottom: 20 }}>
                <Radio.Button value='video'>视频</Radio.Button>
                <Radio.Button value='audio'>音频</Radio.Button>
                <Radio.Button value='image'>图片</Radio.Button>
            </Radio.Group>
            {type && <ClearOutlined style={{ marginLeft: 10 }} onClick={clearType} />}
            <Row gutter={16}>
                {files.map((item: { name: string; type: string; _id: string; url: string; internalUrl: string }) => (
                    <Col span={4} key={item._id}>
                        <Card hoverable className='cardCover' title={`类型：${item.type}`} cover={<Tool item={item} />}>
                            <Meta
                                title={`Title: ${item.name}`}
                                description={
                                    <div>
                                        <p>ID: {item._id}</p>
                                        <p>URL: {item.url}</p>
                                        <p>Internal URL: {item.internalUrl}</p>
                                    </div>
                                }
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
            <Pagination style={{ textAlign: 'right' }} total={total} {...pagination} onChange={onChangePagination} />
        </div>
    );
};

export default injectIntl(File);
