const config = {
    version: '0.0.1',
    // 'AdminHost': 'https://admin.mamameiyu.com',
    AdminHost: 'http://localhost:3003',
    company: '西安瑞木信息技术有限公司',
    uploadFile: '/api/v1/admin/files/upload',
    uploadXlsx: '/api/v1/admin/files/upload/xlsx',
    multiUpload: '/api/v1/admin/files/upload',
    copyRight: 'Copyright@2020 Erealmsoft All Rights Reserved',
    logo: 'https://files.aceconsulting.cn/duo/common/logo.png',
    share: {
        'title': '少儿英语，每天十分钟，英语启蒙很轻松',
        'path': 'pages/index',
        'imageUrl': 'https://files.aceconsulting.cn/duo/common/share.png',
    },
    avatarUrl: 'https://files.aceconsulting.cn/images/default-profile.png',
    goodAudio: 'https://files.aceconsulting.cn/duo/common/verygood.mp3',
    againAudio: 'https://files.aceconsulting.cn/duo/common/tryagain.mp3',
    ClientAuth: 'YWRtaW46MzlmMTQwMWUxOTJiZjcyN2IxYWNmMTZiMDZhNTZiMjQ4Nzk3ZmNkZA==',
    client_secret: '39f1401e192bf727b1acf16b06a56b248797fcdd',
    client_id: 'admin',
};

export default config;
