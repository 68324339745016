import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, Input, InputNumber, Select, Radio } from 'antd';
import UploadFile from '../../../components/basic/uploadFile';
import DynamicTag from '../../../components/dynamicTag';
import { AvatarModel } from '../../../models/course';
import '../styles/index.css';
import APIServices from '../../../lib/APIServices';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../../redux/modules/course';

const { getAllLesson } = actions;
const { Option } = Select;

interface DefaultRootState {
    course: {
        allLesson: Array<any>
    }
}
interface Props {
    initialValues: any | undefined
    onSubmit: (user: any) => void
    onClose: () => void
}
const GroupdForm = (props: Props) => {
    const [form] = Form.useForm();
    const [selectLessons, setSelectLessons] = useState<Array<any>>([]);
    const [selectLessonIds, setSelectLessonIds] = useState<Array<any>>();
    const { allLesson } = useSelector((state: DefaultRootState) => ({
        allLesson: state.course.allLesson,
    }));

    const dispatch = useDispatch();

    const onSelectLesson = (itemIds: Array<any>) => {
        setSelectLessonIds(itemIds);
    };

    const onFinish = (values: any) => {
        values._id = props.initialValues && props.initialValues._id;
        values.lessons = selectLessonIds;

        props.onSubmit({ ...values });
    };

    const onFieldsChange = () => {
        if (props.initialValues && props.initialValues._id) {
            const { lessons } = props.initialValues;
            setSelectLessons(lessons);
            form.setFieldsValue({
                ...props.initialValues,
            });
        } else {
            form.resetFields();
        }
    };

    useEffect(() => {
        onFieldsChange();
    }, [allLesson]);
    return (
        <Form
            layout='vertical'
            // hideRequiredMark
            // initialValues={props.initialValues}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='title'
                        label='标题'
                        rules={[{ required: true, message: 'Please enter the title of the group' }]}
                    >
                        <Input placeholder='Please enter the title of the group' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='order'
                        label='序号'
                        rules={[{ required: true, message: 'Please enter the order of the group' }]}
                    >
                        <Input placeholder='Please enter the order of the group' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='type'
                        label='分类:'
                        rules={[{ required: true, message: 'Please enter category' }]}
                    >
                        <Select placeholder='请选择课程分类' >
                            <Option value={-1}>体验课</Option>
                            <Option value={0}>普通课</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='visible'
                        label='可购买'
                        rules={[{ required: false, message: '状态不能为空' }]}
                    >
                        <Radio.Group>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='coverImage'
                        label='封面 <s1.png>'
                        rules={[{ required: true, message: 'Please enter cover image' }]}
                    >
                        <Input placeholder='Please enter cover image' />
                    </Form.Item>
                </Col>

            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name='lessons'
                        label='课程'
                        rules={[{ required: false, message: '状态不能为空', type: 'array' }]}
                    >
                        {
                            allLesson
                            &&
                            <DynamicTag
                                title='Lesson'
                                selectOptions={selectLessons}
                                onSelect={onSelectLesson}
                                allOptions={allLesson}
                                type='lesson'
                            />
                        }

                    </Form.Item>
                </Col>

            </Row>
            <Row gutter={16}>
                <Col span={24} className='btn'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type='primary' htmlType='submit' className='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
            </Row>

        </Form>
    );
};

export default GroupdForm;
