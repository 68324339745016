import React from 'react';
import { Table, Modal } from 'antd';
import { EyeOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { UserModel } from '../../../models/user';

const { confirm } = Modal;

interface Columns {
    _id: string
    title: string
    key: string
    dataIndex?: string
    width?: number
    fixed?: string
    render: (user: UserModel) => void
}

interface Props {
    goToDetail: (userId: string) => void
    deleteList: (userId: string) => void
    dataSource: Columns[]
}

interface State {
    title?: string
}

class UserList extends React.Component<Props, State> {
    render() {
        const columns: ColumnsType<Columns> = [
            {
                title: 'User Name',
                dataIndex: 'username',
                key: 'username',
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: 'Action',
                key: 'action',
                width: 200,
                fixed: 'right',
                render: (formValues: UserModel) =>
                    <span>
                        <EyeOutlined onClick={() => this.props.goToDetail(formValues._id)} />
                        <DeleteOutlined
                            className='left-margin'
                            onClick={() => {
                                const { props } = this;
                                return confirm({
                                    title: 'Are you sure you want to delete this user?',
                                    icon: <ExclamationCircleOutlined />,
                                    okType: 'danger',
                                    onOk() {
                                        props.deleteList(formValues._id);
                                    },
                                });
                            }}
                        />
                    </span>
                ,
            },
        ];

        return (
            <Table
                className='userTable'
                rowKey={data => data._id}
                columns={columns}
                dataSource={this.props.dataSource}
            />
        );
    }
}

export default UserList;
