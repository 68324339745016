import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, Input, Select } from 'antd';
import { useIntl } from 'react-intl';
import '../styles/index.css';
import DynamicTag from '../../../components/dynamicTag';
import { useDispatch, useSelector } from 'react-redux';
import APIServices from '../../../lib/APIServices';
import { actions } from '../../../redux/modules/course';

const { getAllCartoon } = actions;

const { Option } = Select;
interface DefaultRootState {
    course: {
        allCartoon: Array<any>
    }
}
interface Props {
    initialValues: any | undefined
    onSubmit: (user: any) => void
    onClose: () => void
}

const BookForm = (props: Props) => {
    const [form] = Form.useForm();
    const [selectCartoons, setSelectCartoons] = useState<Array<any>>([]);
    const [selectCartoonIds, setSelectCartoonsIds] = useState<Array<any>>([]);

    const intl = useIntl();

    const titleData = ['lessonName', 'lessonNamePlaceholder', 'cancel', 'submit', 'create', 'update'];
    const titleName: any = {};
    titleData.forEach((text: string) => titleName[text] = intl.formatMessage({ id: text }));

    const { allCartoon } = useSelector((state: DefaultRootState) => ({
        allCartoon: state.course.allCartoon,
    }));

    const onFieldsChange = async () => {
        if (props.initialValues && props.initialValues._id) {
            const lessonCartoons = (await APIServices.getLessonCartoons(props.initialValues._id)).data;
            const cartoons = lessonCartoons.map((lessonCartoon: { cartoon: any }) => lessonCartoon.cartoon)
            setSelectCartoons(cartoons)
            form.setFieldsValue({});
        } else {
            form.resetFields();
        }
    };

    const onFinish = (values: any) => {
        values._id = props.initialValues && props.initialValues._id;
        props.onSubmit({
            ...values,
            cartoons: selectCartoonIds,
        });
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllCartoon());
    }, []);

    useEffect(() => {
        onFieldsChange()
    }, [allCartoon]);

    return (
        <Form
            layout='vertical'
            hideRequiredMark
            initialValues={props.initialValues}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='title'
                        label={titleName.lessonName}
                        rules={[{ required: true, message: `${titleName.lessonNamePlaceholder}` }]}
                    >
                        <Input placeholder={titleName.lessonNamePlaceholder} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='order'
                        label='序号'
                        rules={[{ required: true, message: 'Please enter the order' }]}
                    >
                        <Input type='number' placeholder='请输入课程序号' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='type'
                        label='分类:'
                        rules={[{ required: true, message: 'Please enter category' }]}
                    >
                        <Select placeholder='请选择课程分类' >
                            <Option value={-1}>体验课</Option>
                            <Option value={0}>普通课</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name='cartoons'
                        label='卡通课'
                        rules={[{ required: false, message: '状态不能为空', type: 'array' }]}
                    >
                        {
                            allCartoon
                            &&
                            <DynamicTag
                                title='Cartoon'
                                selectOptions={selectCartoons}
                                onSelect={setSelectCartoonsIds}
                                allOptions={allCartoon}
                                type='cartoon'
                            />
                        }

                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24} className='btn'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            {titleName.cancel}
                        </Button>
                        <Button type='primary' htmlType='submit' className='submit'>
                            {titleName.submit}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default BookForm;
