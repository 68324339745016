import React from 'react';
import { Upload, Modal, Avatar, Button } from 'antd';
import 'antd/dist/antd.css';
import '../styles/index.css';
import config from '../../config/config';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';

function getBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

interface Props {
    isEdit: boolean
    dataSources: any | any[]
    type?: string
    showUploadList?: boolean
    uploadSuccess: (fileList: any) => void
}

interface State {
    imageUrl: string | null
    previewVisible: boolean
    previewImage: string
    fileList: any
    loading: boolean
}
class UploadFile extends React.Component<Props, State> {
    static defaultProps = {
        showUploadList: false,
        type: 'image',
        isEdit: true,
    };
    constructor(props: Props) {
        super(props);

        const fileList = Array.isArray(this.props.dataSources)
            ? this.props.dataSources
            : this.props.dataSources ? [this.props.dataSources] : [];

        this.state = {
            imageUrl: '',
            previewVisible: false,
            previewImage: '',
            fileList,
            loading: false,
        };
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = ({ file, fileList }: any) => {
        this.setState({ fileList });

        if (file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }

        if (file.status === 'done') {
            const newFileList = fileList.map((item: any) => item.response ? item.response.data : item);

            getBase64(file.originFileObj)
                .then((imageUrl: any) => {
                    this.setState({
                        imageUrl,
                        loading: false,
                    });
                });
            this.props.uploadSuccess(this.props.showUploadList ? newFileList : file.response.data);
        }
    }

    render() {
        const { previewVisible, previewImage, fileList } = this.state;
        const uploadButton = <>{this.state.loading ? <LoadingOutlined /> : <Button><UploadOutlined /></Button>}</>;
        const accessToken = localStorage.getItem('accessToken');
        const newProps: any = {
            name: 'file',
            showUploadList: this.props.showUploadList,
            listType: 'picture-card',
            fileList,
            className: 'uploadButton',
            action: config.uploadFile,
            onPreview: this.handlePreview,
            onChange: this.handleChange,
            headers: {
                authorization: `Bearer ${accessToken || ''}`,
            },
            data: (file: any) => file,
            // beforeUpload,
        };
        function preview(props: any, imageUrl: any) {
            let previewElement;
            const url = imageUrl || props.dataSources && props.dataSources.url;
            switch (props.type) {
                case 'video':
                    previewElement = url && <video controls width='250' src={url} />;
                    break;
                case 'audio':
                    previewElement = url && <audio controls src={url} />;
                    break;
                case 'avatar':
                    previewElement = <Avatar src={url || config.avatarUrl} size={60} />;
                    break;
                default:
                    previewElement = url && <Avatar shape='square' src={url} size={100} />;
                    break;
            }

            return previewElement;
        }
        return (
            <div className='clearfix'>
                {!this.props.showUploadList && preview(this.props, this.state.imageUrl)}
                <Upload {...newProps}>
                    {!fileList ? fileList.length < 5 : this.props.isEdit && uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt='preview' style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}

export default UploadFile;
