import CONFIG from '../config/config';
import store from './storageUtils';
import axios from 'axios';
import { notification } from 'antd';
// import AxiosCacheAdapter from './AxiosCacheAdapter'

const CONN_TIMEOUT = 15000;
const CONN_TIMEOUT_ERROR_MSG = 'Your device is having trouble while connecting to our services. Please check your internet connection and try again.';
const ClientToken = `Basic ${CONFIG.ClientAuth}`;

declare module 'axios' {
    export interface AxiosResponse<T = any> extends Promise<T> { }
}

declare module 'axios' {
    interface AxiosInstance {
        (config: AxiosRequestConfig): Promise<any>
    }
}

const axiosService = axios.create({
    // baseURL: CONFIG.AdminHost,
    timeout: CONN_TIMEOUT,
    // adapter: AxiosCacheAdapter,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    },
});

axiosService.defaults.responseType = 'json';
// axiosService.defaults.isRetryRequest = false
axiosService.defaults.validateStatus = status => status < 500;

axiosService.interceptors.request.use(
    async config => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken && !config.headers.Authorization) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    err => Promise.reject(err)
);

const refreshAccessToken = async (pResponse: any) => {
    const refreshData = {
        'grant_type': 'refresh_token',
        'refresh_token': store.authToken.refresh_token,
    };
    return axiosService
        .post('/api/base/oauth/token', refreshData, {
            headers: { Authorization: ClientToken },
        })
        .then(response => {
            const tokenJson = response.data || response;
            if (tokenJson && tokenJson.type !== 'error' && tokenJson.access_token) {
                pResponse.config.headers.Authorization = `Bearer ${tokenJson.access_token}`;
                return axiosService(pResponse.config);
            } else {
                store.deleteSessionToken();
                return Promise.reject('Invalid Refresh Token');
            }
        })
        .catch(err => err);
};

const responseInterceptorSuccess = (response: any) => {
    if (!response) {
        throw 'Network Error';
    }
    if (response.status >= 200 && response.status < 400) {
        if (response.data) {
            const { data } = response;
            if (data.type === 'error') {
                if (data.token_type === 'Bearer') {
                    store.deleteSessionToken();
                }
                throw data;
            } else if (data.token_type === 'Bearer') {
                store.storeSessionToken(data);
            }
            return data;
        }
    } else if (response.status === 401 && response.data) {
        if (response.data.code === 'CMSV_EXPIRED_TOKEN_ERR') {
            return refreshAccessToken(response);
        } else if (
            response.data.code === 'CMSV_INVALID_REFRESH_TOKEN_ERR' ||
            response.data.code === 'CMSV_EXPIRED_REFRESH_TOKEN_ERR'
        ) {
            store.deleteSessionToken();
        } else if (response.data.code === 'CMSV_INVALID_TOKEN_ERR') {
            store.deleteSessionToken();
        }
    } else if (response.status === 403) {
        store.deleteSessionToken()
        notification.error({
            message: 'No Permission',
            placement: 'topRight',
        });
        return Promise.reject('403: No Permission');
    } else if (response.status === 404) {
        store.deleteSessionToken();
        notification.error({
            message: 'Not Found',
            placement: 'topRight',
        });
        return Promise.reject('404: Not Found');
    }
};

const responseInterceptorError = (error: any) => new Promise((resolve, reject) => {
    if (error.message === 'Network Error') {
        return reject('Network Error');
    } else {
        if (error.code === 'ECONNABORTED') {
            return reject(CONN_TIMEOUT_ERROR_MSG);
        }
        return reject(error);
    }
});

axiosService.interceptors.response.use(
    response => {
        const data = responseInterceptorSuccess(response);
        const { headers: { count } } = response;
        if (data) {
            return { ...data, count };
        }
    },
    error => responseInterceptorError(error)
);

export default axiosService;
