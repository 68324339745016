const getLocalLanguage = () => {
    const language = window.localStorage.getItem('local') || navigator.language;
    let localeLanguage = '';
    switch (language) {
        case 'en':
            localeLanguage = 'en';
            break;
        case 'en-US':
            localeLanguage = 'en';
            break;
        case 'zh':
            localeLanguage = 'cn';
            break;
        case 'zh-CN':
            localeLanguage = 'cn';
            break;
    }
    return localeLanguage;
};

const getPageTitleName = (titles: Array<string>, intl: Function) => {
    const titleName: any = {};
    titles.map((text: string) => titleName[text] = intl({ id: text }));
    return titleName;
};
export default {
    getLocalLanguage,
    getPageTitleName,
};
