import React, { useState } from 'react';
import { Form, Button, Col, Row, Input, InputNumber } from 'antd';
import UploadFile from '../../../components/basic/uploadFile';
import { AvatarModel } from '../../../models/course';
import '../styles/index.css';

interface Props {
    initialValues: any | undefined
    onSubmit: (user: any) => void
    onClose: () => void
}
const WordForm = (props: Props) => {
    const [form] = Form.useForm();
    const initFile = { _id: '', url: '' };
    const [cover, setCover] = useState(initFile);
    const [audio, setAudio] = useState(initFile);

    const onFinish = (values: any) => {
        values._id = props.initialValues && props.initialValues._id;
        cover.url && (values.cover = cover);
        audio.url && (values.audio = audio);
        props.onSubmit(values);
    };
    return (
        <Form
            layout='vertical'
            hideRequiredMark
            initialValues={props.initialValues}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='value'
                        label='Value'
                        rules={[{ required: true, message: 'Please enter the value of the word' }]}
                    >
                        <Input placeholder='Please enter the value of the word' />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name='index'
                        label='Index'
                    >
                        <InputNumber placeholder='Please enter word index' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='cover'
                        label='Cover'
                    >
                        <UploadFile
                            dataSources={props.initialValues && props.initialValues.cover}
                            uploadSuccess={(file: AvatarModel) => setCover(file)}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='audio'
                        label='Audio'
                    >
                        <UploadFile
                            type='audio'
                            dataSources={props.initialValues && props.initialValues.audio}
                            uploadSuccess={(file: AvatarModel) => setAudio(file)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24} className='btn'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type='primary' htmlType='submit' className='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default WordForm;
