import React, { useState } from 'react';
import { Row, Col } from 'antd';
import GroupList from './groupList';
import SectionList from './sectionList';

interface Props {
    match: {
        params: {
            newUserId: string
        }
    }
}
const NewUserDetail = (props: Props) => {
    const { newUserId } = props.match.params;
    const [currentLesson, setCurrentLesson] = useState({ title: '' });

    const getSections = async (lesson: { title: string, _id: string }) => {
        setCurrentLesson(lesson);
    };

    return (
        <Row className='newUserDetail'>
            <Col span={1} />
            <Col span={7}>
                <GroupList
                    userId={newUserId}
                    getSections={getSections}
                />
            </Col>
            <Col span={1} />
            <Col span={14} className='text-left'>
                <SectionList
                    userId={newUserId}
                    currentLesson={currentLesson}
                />
            </Col>
            <Col span={1} />
        </Row>
    );
};

export default NewUserDetail;
