import { createAction, handleActions } from 'redux-actions';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/constants';

import { ClientModel } from '../../models/client';
const { CREATE_CLIENT, UPDATE_CLIENT, ALL_CLIENT } = CONSTANTS;

// initialState
const initialState = {
    clients: [],
};

interface Action {
    type: string
    payload: any
}

export default handleActions(
    {
        [CREATE_CLIENT](state) {
            return {
                ...state,
            };
        },
        [UPDATE_CLIENT](state, action: Action) {
            return {
                ...state,
            };
        },
        [ALL_CLIENT](state, action: Action) {
            return {
                ...state,
                clients: action.payload,
            };
        },
    },
    initialState
);

/**
 * action creators
 * */
export const actions = {
    createClient: createAction(CREATE_CLIENT, (client: ClientModel) => APIServices.createClient(client)),
    updateClient: createAction(UPDATE_CLIENT, async (client: ClientModel) => (await APIServices.updateClient(client)).data),
    getClients: createAction(ALL_CLIENT, async (pagination: { page: number, pageSize: number }) => (await APIServices.getClients(pagination)).data),
};
