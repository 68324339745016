import React, { Component, useEffect, useState } from 'react';
import { Typography, notification, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { connect, useSelector, useDispatch } from 'react-redux';
import { actions } from '../../redux/modules/course';

import WordList from './components/wordList';
import DrawerForm from '../../components/basic/drawer';
import WordForm from './components/wordForm';
import { FormattedMessage, useIntl } from 'react-intl';
import { WordModel, EditWord } from '../../models/course';
import 'antd/dist/antd.min.css';
import Common from '../../lib/common';
import CONSTANTS from '../../lib/constants';

const { ACTION_KEY } = CONSTANTS;
const { getSectionContent, getAllWord, deleteWord, createWord, updateWord } = actions;
const { Title } = Typography;
const { getPageTitleName } = Common;

interface Pagination {
    current: number
    pageSize: number
    showSizeChanger?: boolean
    total?: number
}
interface DefaultRootState {
    user: {
        userActions: Array<number>
    }
    course: {
        allLesson: Array<any>
        sectionWords: Array<any>
    }
}

interface DrawerInfo {
    // isCreate: boolean
    title: any,
    type: string
    initialValues?: any | undefined
}

interface Props {
    courseId: string
}

const WordView = (props: Props) => {
    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const [drawer, setDrawer] = useState<DrawerInfo>({
        // isCreate: true,
        title: '',
        type: '',
    });
    const [visible, setVisible] = useState<boolean>(false);
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();
    const { sectionWords, userActions } = useSelector((state: DefaultRootState) => ({
        sectionWords: state.course.sectionWords,
        userActions: state.user.userActions,
    }));
    const dispatch = useDispatch();

    // config table title
    const titleData = ['lessonName', 'deleteTip', 'action'];
    const titleName = getPageTitleName(titleData, intl.formatMessage);

    const refreshList = async () => {
        dispatch(getSectionContent(props.courseId))
        // await this.props.getAllWord(this.props.courseId);
        // this.setState({
        //     words: this.props.words,
        // });
    };

    const showDrawer = (item?: { _id: string }) => {
        const data = item && item._id
            ? {
                title: 'Update Word',
                type: 'update',
                visible: true,
                initialValues: item,
            }
            : {
                title: 'Create Word',
                type: 'create',
                visible: true,
            };
        setDrawer(data);
        setVisible(true);
    }

    const deleteList = async (id: string) => {
        dispatch(deleteWord(id));
        notification.success({
            message: 'Delete Success',
            description: '删除成功',
            placement: 'topRight',
        });
        refreshList();
    }

    const onClose = () => {
        setDrawer({ ...drawer, initialValues: '' });
        setVisible(false);
    };

    const onSubmit = async (values: any) => {
        if (values._id) {
            dispatch(updateWord(values))
        } else {
            dispatch(createWord(values))
        }
        onClose();
        refreshList();
    }

    const setPaginationParams = (paginationInfo: any) => {
        setPagination(paginationInfo);
        refreshList();
    };

    useEffect(() => {
        refreshList();
    }, []);

    return (
        <div>
            <Title>Words</Title>
            <Button
                shape='circle'
                size='large'
                icon={<PlusOutlined />}
                onClick={() => showDrawer()}
                type='primary'
                disabled={!userActions.includes(ACTION_KEY.CREATE)}
                className='plusIcon'
            >
            </Button>
            {
                sectionWords &&
                <WordList
                    dataSource={sectionWords}
                    deleteList={deleteList}
                    edit={(course: any) => showDrawer(course)}
                />
            }

            {
                visible &&
                <DrawerForm
                    {...drawer}
                    visible
                    onClose={onClose}
                >
                    <WordForm
                        onClose={onClose}
                        initialValues={drawer.initialValues}
                        onSubmit={onSubmit}
                    />
                </DrawerForm>
            }
        </div>
    );
}


export default WordView;
