import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import APIServices from '../../../lib/APIServices';

const CountUser = (props: any) => {
    const { users, total } = props;
    const [dataSource, setDataSource] = useState<any>([])

    const columns = [
        {
            title: '总数',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: '授权用户信息总数',
            dataIndex: 'userInfo',
            key: 'userInfo',
        },
        {
            title: '未授权用户信息总数',
            dataIndex: 'unauthorized',
            key: 'unauthorized',
        },
        {
            title: '授权手机号总数',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '授权家庭信息总数/正常用户',
            dataIndex: 'profile',
            key: 'profile',
        },
    ];

    const getUsersStats = async () => {
        const stats = (await APIServices.getUsersStats()).data

        setDataSource([{
            key: 1,
            total,
            ...stats
        }])
    }

    useEffect(() => {
        getUsersStats()
    }, [total, users])

    return <Table
        style={{ marginBottom: 10 }}
        pagination={false}
        dataSource={dataSource}
        columns={columns} />;
};
export default CountUser;
