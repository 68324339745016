import React, { useEffect, useState } from 'react';
import {
    Typography, Table,
} from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import APIServices from '../../lib/APIServices';

const { Title } = Typography;

interface Props {
    intl: any
}

const Stats = (props: Props) => {
    const [keyList, setKeyList] = useState([])
    const [keyDataSource, setKeyDataSource] = useState([])
    const [sharers, setSharers] = useState([])
    const [sharerDataSource, setSharerDataSource] = useState<any>([])

    const getStats = async () => {
        setKeyList((await APIServices.getStatsKeyCount()).data)
    }

    const getSharers = async () => {
        setSharers((await APIServices.getSharers()).data)
    }


    useEffect(() => {
        getStats();
        getSharers();
    }, [])

    useEffect(() => {
        const dataSource: any = [];

        for (let [key, values = []] of Object.entries(keyList)) {
            dataSource.push({
                action: key,
                key,
                date: values.map(item => {
                    const targetDate = new Date(item)
                    return `${targetDate.getFullYear()}/${targetDate.getMonth() + 1}/${targetDate.getDate()} ${targetDate.getHours()}:${targetDate.getMinutes()} `
                }),
            })
        }

        setKeyDataSource(dataSource)
    }, [keyList])

    useEffect(() => {
        const getRecipients = async () => {
            const dataSource = await Promise.all(sharers.map(async (user: { nickName: string, _id: string, unionId: string }) => {
                return {
                    sharer: user.nickName || user.unionId,
                    recipients: (await APIServices.getRecipients(user._id)).data
                }
            }))

            setSharerDataSource(dataSource)
        }
        getRecipients()
    }, [sharers])

    const keyColumns = [{
        title: '操作',
        dataIndex: 'action',
        key: 'action',
    }, {
        title: '唯一KEY',
        dataIndex: 'key',
        key: 'key',
    }, {
        title: '日期',
        dataIndex: 'date',
        key: 'date',
    }];

    const sharerColumns = [
        {
            title: '分享者',
            dataIndex: 'sharer',
            key: 'sharer',
        },
        {
            title: '接收分享',
            dataIndex: 'recipients',
            key: 'recipients',
            render: (text: {}, formValues: any) => {
                return formValues.recipients.map((item: { nickName: string, unionId: string, _id: string }) => `${item.nickName || item.unionId || item._id} `)
            },
        },
    ];


    return (
        <div className='site-card-wrapper'>
            <Title>数据统计</Title>
            <Table
                dataSource={sharerDataSource as any}
                columns={sharerColumns} />

            <Table
                dataSource={keyDataSource}
                columns={keyColumns} />
        </div >
    );
};

export default injectIntl(Stats);
