import store from './storageSetting'

const StorageKey = {
    SessionTokenKey: 'SessionTokenKey',
    SessionHasAppOpenedKey: 'SessionHasAppOpenedKey',
    SessionFirstOpenJudgeKey: 'SessionFirstOpenJudgeKey',
    RefreshTokenFlag: 'RefreshTokenFlag',
    DeviceIdentifier: 'DeviceIdentifier',
    VoteTutorialOpenedKey: 'VoteTutorialOpenedKey',
    SplashImage: 'SplashImage',
    AppConfig: 'AppConfig',
    AppUserConfig: 'AppUserConfig',
    UserID: 'UserID',
    LoggedInOffline: 'LoggedInOffline'
};

let _authToken = null, _userID = null, _calendarDate = {};
let _loggedInOffline = false;

const loadDataWithDefault = (key) => {
    return store.load({key: key}).catch(() => Promise.resolve(null))
}

export default {
    clear() {
        return store.clearAll();
    },
    remove(key) {
        return store.remove({key: key})
    },
    isAppOpened() {
        return store.load({key: StorageKey.SessionHasAppOpenedKey}).then(isOpened => {
            if (!isOpened) {
                return store.save({key: StorageKey.SessionHasAppOpenedKey, data: true}).then(() => {
                    return Promise.resolve(false)
                })
            } else {
                return Promise.resolve(true)
            }
        }).catch(() => {
            return store.save({key: StorageKey.SessionHasAppOpenedKey, data: true}).then(() => {
                return Promise.resolve(false)
            })
        })
    },

    /**
     * ### storeSessionToken
     * Store the session key
     */
    storeSessionToken(sessionToken) {
        _authToken = sessionToken;
        _userID = sessionToken.user_id;

        return store.save({key: StorageKey.UserID, data: _userID}).then(() => {
            return store.save({key: StorageKey.SessionTokenKey, data: sessionToken})
        })
    },
    get userID() {
        return _userID;
    },
    get authToken() {
        return _authToken;
    },
    /**
     * ### getSessionToken
     * @param {Object} sessionToken the currentUser object
     *
     * When Hot Loading, the sessionToken  will be passed in, and if so,
     * it needs to be stored on the device.  Remember, the store is a
     * promise so, have to be careful.
     */
    getSessionToken() {
        return store.load({key: StorageKey.SessionTokenKey}).then((auth) => {
            _authToken = auth;
            _userID = auth != null ? auth.user_id : null;
            return Promise.resolve(auth);
        }).catch(() => Promise.resolve(null))
    },
    getUserID() {
        return store.load({key: StorageKey.UserID}).then((userID) => {
            _userID = userID;
            return Promise.resolve(userID);
        }).catch(() => Promise.resolve(null))
    },
    /**
     * ### deleteSessionToken
     * Deleted during log out
     */
    deleteSessionToken() {
        _authToken = null;
        _loggedInOffline = false;

        return store.remove({key: StorageKey.SessionTokenKey})
    },
    getDeviceIdentifier() {
        return loadDataWithDefault(StorageKey.DeviceIdentifier)
    },
    saveDeviceIdentifier() {
        return store.save({key: StorageKey.DeviceIdentifier, data: Math.random().toString()})
    },
    getSplashImage() {
        return loadDataWithDefault(StorageKey.SplashImage)
    },
    saveSplashImage(image) {
        return store.save({key: StorageKey.SplashImage, data: image})
    },
    isShowVoteTutorial() {
        return store.load({key: StorageKey.VoteTutorialOpenedKey}).then(isOpened => {
            if (!isOpened) {
                return store.save({key: StorageKey.VoteTutorialOpenedKey, data: true}).then(() => {
                    return Promise.resolve(false)
                })
            } else {
                return Promise.resolve(true)
            }
        }).catch(() => {
            return store.save({key: StorageKey.VoteTutorialOpenedKey, data: true}).then(() => {
                return Promise.resolve(false)
            })
        })
    },
    getCachedCalendarDate() {
        return _calendarDate;
    },
    saveCachedCalendarDate(calendarDate) {
        _calendarDate = calendarDate;
    },
    setLoggedInOffline(loggedInOffline) {
        _loggedInOffline = loggedInOffline;
    },
    isLoggedInOffline() {
        return _loggedInOffline;
    },
    async getAllData() {
        return [
            {key: 'SessionHasAppOpenedKey', value: await loadDataWithDefault(StorageKey.SessionHasAppOpenedKey)},
            {key: 'RefreshTokenFlag', value: await loadDataWithDefault(StorageKey.RefreshTokenFlag)},
            {key: 'DeviceIdentifier', value: await loadDataWithDefault(StorageKey.DeviceIdentifier)},
            {key: 'SessionTokenKey', value: await loadDataWithDefault(StorageKey.SessionTokenKey)},
            {key: 'VoteTutorialOpenedKey', value: await loadDataWithDefault(StorageKey.VoteTutorialOpenedKey)},
            {key: 'SplashImage', value: await loadDataWithDefault(StorageKey.SplashImage)},
            {key: StorageKey.AppConfig, value: await loadDataWithDefault(StorageKey.AppConfig)},
            {key: StorageKey.AppUserConfig, value: await loadDataWithDefault(StorageKey.AppUserConfig)},
            {key: StorageKey.UserID, value: await loadDataWithDefault(StorageKey.UserID)}
        ]
    }
}
