import React from 'react';
import { Select } from 'antd';
import { BookModel } from '../../../models/course';

const { Option } = Select;

interface Props {
    dataSources: any
    defaultValue: any
    getCurrentBook: (value: string) => void
}


const BookMenu = (props: Props) => {
    const onChange = (value: string) => {
        props.getCurrentBook(value);
    }

    const onSearch = (val: any) => {
        console.log('search:', val);
    }
    return (
        <Select
            showSearch
            style={{ width: 200 }}
            defaultValue={props.defaultValue.title}
            optionFilterProp='children'
            onChange={onChange}
            onSearch={onSearch}
        >
            {
                props.dataSources.map((book: any) => <Option key={book._id} value={book._id}>{book.title}</Option>)
            }
        </Select>
    );
};
export default BookMenu;
