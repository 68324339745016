import { Spin } from 'antd';
import React from 'react';
import '../styles/index.css';

interface Props {
  loadingState?: boolean
}

const LoadingView = (props: Props) => props.loadingState
    ?
    <div className='loading'>
        <Spin size='large' />
    </div>

    : null;

export default LoadingView;
