import React from 'react';
import { Form, Button, Col, Row, Input } from 'antd';
import { UserModel } from '../../../models/user';
import '../styles/index.css';

interface Props {
    initialValues: UserModel | undefined
    onSubmit: (user: UserModel) => void
    onClose: () => void
}
const FormItem = (props: Props) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        values._id = props.initialValues && props.initialValues._id;
        props.onSubmit(values);
    };

    return (
        <Form
            layout='vertical'
            hideRequiredMark
            initialValues={props.initialValues}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='email'
                        label='Email'
                        rules={[{ required: true, message: 'Please enter email' }]}
                    >
                        <Input placeholder='Please enter email' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='phone'
                        label='Phone'
                        rules={[{ required: true, message: 'Please enter phone' }]}
                    >
                        <Input placeholder='Please enter phone' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='username'
                        label='User Name'
                        rules={[{ required: true, message: 'Please enter user name' }]}
                    >
                        <Input placeholder='Please enter user name' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='nickname'
                        label='Nickname'
                    >
                        <Input placeholder='Please enter nickname' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24} className='btn'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type='primary' htmlType='submit' className='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default FormItem;
