
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/constants';

import { createAction, handleActions } from 'redux-actions';

const {
    ALL_MENU,
    CREATE_MENU,
    UPDATE_MENU,
    DELETE_MENU,
    ALL_MENU_DATA,
    GET_CURRENT_USER_MENUS } = CONSTANTS;

// initialState
const initialState = {
    menus: [],
    currentUserMenus: [],
    menuData: [],
};

interface Action {
    type: string
    payload: any
}

export default handleActions(
    {
        [ALL_MENU_DATA](state, action: Action) {
            return {
                ...state,
                menuData: action.payload,
            };
        },
        [ALL_MENU](state, action: Action) {
            return {
                ...state,
                menus: action.payload,
            };
        },
        [GET_CURRENT_USER_MENUS](state, action: Action) {
            return {
                ...state,
                currentUserMenus: action.payload.menus,
            };
        },
        [CREATE_MENU](state) {
            return {
                ...state,
            };
        },
        [UPDATE_MENU](state) {
            return {
                ...state,
            };
        },
        [DELETE_MENU](state) {
            return {
                ...state,
            };
        },
    },
    initialState
);

/**
 * action creators
 * */
export const actions = {
    getMenus: createAction(ALL_MENU, async (pagination: { page: number, pageSize: number }) => {
        const datas = (await APIServices.getAllMenu(pagination)).data;
        return datas.map((data: any) => ({
            ...data,
            key: data._id,
        }));
    }),
    getMenusData: createAction(ALL_MENU_DATA, async () => (await APIServices.getMenuTree()).data),
    createMenu: createAction(CREATE_MENU, async (data: any) => (await APIServices.createMenu(data)).data),
    updateMenu: createAction(UPDATE_MENU, async (data: any) => (await APIServices.updateMenu(data)).data),
    deleteMenu: createAction(DELETE_MENU, async (menuId: string) => await APIServices.deleteMenu(menuId)),
    getMenusDataByID: createAction(GET_CURRENT_USER_MENUS, async (userId: string) => (await APIServices.getMenuTreeById(userId)).data),
};
