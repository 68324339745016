import { createAction, handleActions } from 'redux-actions';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/constants';

const { MEDIAS_TYPE, ALL_MEDIAS } = CONSTANTS;

// initialState
const initialState = {
    allMedia: [],
    allSongs: [],
    allVideos: [],
    allKisses: [],
};

interface Action {
    type: string
    payload: any
}

export default handleActions(
    {

        [ALL_MEDIAS](state, action: Action) {
            return {
                ...state,
                allMedia: action.payload,
                allSongs: action.payload.filter((item: { type: number }) => item.type === MEDIAS_TYPE.SONG),
                allVideos: action.payload.filter((item: { type: number }) => item.type === MEDIAS_TYPE.VIDEO),
                allKisses: action.payload.filter((item: { type: number }) => item.type === MEDIAS_TYPE.KISS),
            };
        },
    },
    initialState
);

/**
 * action creators
 * */
export const actions = {
    getAllMedia: createAction(ALL_MEDIAS, async () => (await APIServices.getAllMedia()).data),
};
