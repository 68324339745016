import { createAction, handleActions } from 'redux-actions';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/constants';

import { OrderModel } from '../../models/order';
const {
    REFUND_ORDER,
    ALL_ORDER,
    UPDATE_ORDER,
    DELETE_ORDER,
    ALL_REFUND_ORDER } = CONSTANTS;

// initialState
const initialState = {
    orders: [],
    currentOrder: null,
};

interface Action {
  type: string
  payload: any
}

export default handleActions(
    {
        [REFUND_ORDER](state, action: Action) {
            return {
                ...state,
                currentOrder: action.payload,
            };
        },
        [ALL_REFUND_ORDER](state, action: Action) {
            return {
                ...state,
                refundOrders: action.payload,
            };
        },
        [ALL_ORDER](state, action: Action) {
            return {
                ...state,
                orders: action.payload,
                currentOrder: state.currentOrder || action.payload[0],
            };
        },
        [UPDATE_ORDER](state, action: Action) {
            return {
                ...state,
                currentBook: action.payload,
            };
        },
        [DELETE_ORDER](state) {
            return {
                ...state,
            };
        },
    },
    initialState
);

/**
 * action creators
 * */
export const actions = {
    setRefund: createAction(REFUND_ORDER, async (order: OrderModel) => (await APIServices.setRefund(order)).data),
    getOrders: createAction(ALL_ORDER, async () => (await APIServices.getOrders()).data),
    updateOrder: createAction(UPDATE_ORDER, async (data: any) => (await APIServices.updateOrder(data)).data),
    deleteOrder: createAction(UPDATE_ORDER, async (orderId: string) => (await APIServices.deleteOrder(orderId)).data),
    getRefundOrder: createAction(ALL_REFUND_ORDER, async () => (await APIServices.getRefundOrder('')).data),
};
