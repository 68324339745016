import React from 'react';
import { Card, Col, Row, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { MallModel } from '../../../models/mall';
import { injectIntl } from 'react-intl';

const { confirm } = Modal;
const { Meta } = Card;

interface Props {
    dataSource: Array<MallModel>
    deleteList: (goodId: string) => void
    edit: (good: MallModel) => void
    intl: any
}

const GoodList = (props: Props) => {
    const { intl } = props;
    // config table title
    const titleData = ['deleteTip'];
    const titleName: any = {};
    titleData.forEach((text: string) => titleName[text] = intl.formatMessage({ id: text }));

    const onShowSizeChange = (current: number, pageSize: number) => {
        console.log(current, pageSize);
    }

    return (
        <div className='site-card-wrapper'>
            <Row gutter={16}>
                {
                    props.dataSource.map((item: MallModel) =>
                        <Col span={4} key={item._id}>
                            <Card
                                hoverable
                                className='cardCover'
                                cover={<img alt='Good Cover' src={item.thumbImage && item.thumbImage.url} />}
                                actions={[
                                    <EditOutlined onClick={() => props.edit(item)} />,
                                    <DeleteOutlined
                                        onClick={() => confirm({
                                            title: `${titleName.deleteTip} ${item.title}`,
                                            icon: <ExclamationCircleOutlined />,
                                            okType: 'danger',
                                            onOk() {
                                                props.deleteList(item._id);
                                            },
                                        })} />,
                                ]}
                            >
                                <Meta title={item.title} />
                            </Card>
                        </Col>
                    )
                }
            </Row>

        </div >
    );
};

export default injectIntl(GoodList);
