import React, { useState } from 'react';
import { Result, Button } from 'antd';
import { Redirect } from 'react-router-dom';

// '403': 'Sorry, you are not authorized to access this page.',
// '404': 'Sorry, the page you visited does not exist.',
// '500': 'Sorry, the server is wrong.'

interface Params {
    title: string
    subTitle: string
    goHome: (params: boolean) => void
}

const ResultPage = ({
    title = '404',
    subTitle = 'Sorry, the page you visited does not exist.',
}: Params) => {
    const [routeState, goHome] = useState(false);

    if (routeState) {
        return <Redirect push to='/home/newUser' />;
    }

    return (
        <Result
            title={title}
            subTitle={subTitle}
            extra={<Button type='primary' onClick={() => goHome(!routeState)}>Back Home</Button>}
        />
    );
};
export default ResultPage;
