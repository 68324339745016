import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, notification, Modal, Button, Table, Empty, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

import { actions } from '../../redux/modules/course';
import { actions as actionsMedia } from '../../redux/modules/media';
import DrawerForm from '../../components/basic/drawer';
import IndexForm from './components/indexForm';
import LessonMenu from './components/lessonMenu';

import './styles/index.css';
import Common from '../../lib/common';
import CONSTANTS from '../../lib/constants';
import APIServices from '../../lib/APIServices';
import config from '../../config/config'

const { Title } = Typography;
const { confirm } = Modal;
const { createSection, getLessonSection, deleteSection, updateSection, getAllLesson, selectBook, getAllWord, getAllSentence } = actions;
const { getAllMedia } = actionsMedia;
const { ACTION_KEY } = CONSTANTS;
const { getPageTitleName } = Common;
interface Props {
    history: string[]
}

interface Pagination {
    current: number
    pageSize: number
    showSizeChanger?: boolean
    total?: number
}

interface DefaultRootState {
    user: {
        userActions: Array<number>
    }
    course: {
        allLesson: Array<any>
        currentLesson: {
            _id: string
            title: string
        },
        lessonSections: Array<any>
        words: Array<any>
        allSentence: Array<any>
    }
    media: {
        allMedia: Array<any>
    }
}

interface DrawerInfo {
    title: any,
    type: string
    initialValues?: any | undefined
}
const Section = (props: Props) => {
    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const [drawer, setDrawer] = useState<DrawerInfo>({
        // isCreate: true,
        title: '',
        type: '',
    });

    const initFile = { _id: '', url: '' };
    const [multiSentences, setMultiSentences] = useState(initFile);

    const [visible, setVisible] = useState<boolean>(false);
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();
    const { allLesson, userActions, currentLesson, lessonSections, allWord, allSentence, allMedia } = useSelector((state: DefaultRootState) => ({
        allLesson: state.course.allLesson,
        userActions: state.user.userActions,
        currentLesson: state.course.currentLesson,
        lessonSections: state.course.lessonSections,
        allWord: state.course.words,
        allSentence: state.course.allSentence,
        allMedia: state.media.allMedia,
    }));
    const dispatch = useDispatch();

    // config table title
    const titleData = ['lessonName', 'deleteTip', 'action'];
    const titleName = getPageTitleName(titleData, intl.formatMessage);

    const refreshList = (lessonId: string) => {
        // get lesson section
        dispatch(getLessonSection(lessonId))
    };

    const deleteList = async (id: string) => {
        dispatch(deleteSection(currentLesson._id, id));
        notification.success({
            message: 'Delete Success',
            placement: 'topRight',
        });
        refreshList(currentLesson._id);
    }

    const showDrawer = (item?: any) => {
        let data;
        if (item && item._id) {
            data = {
                title: <FormattedMessage id='updateSuccess' />,
                type: 'update',
                initialValues: item,
            }
        } else {
            data = {
                title: <FormattedMessage id='createSuccess' />,
                type: 'create',
                initialValues: { book: currentLesson._id },
            }
        }
        setDrawer(data);
        setVisible(true);
    }

    const onClose = () => {
        setDrawer({ ...drawer, initialValues: '' });
        setVisible(false);
    };

    const createRelation = (id: string, kind: string, itemIds: any) => {
        if (itemIds.length) {
            const sentenceRelation = {
                itemIds,
                kind,
            }
            APIServices.createSectionRelation(id, sentenceRelation);
        }
    }

    const dealWithSentence = (id: string, { practices, checks }: any) => {
        if (practices.length || checks.length) {
            const sentenceRelation = {
                practices,
                checks,
                kind: 'Sentence'
            }
            APIServices.createSectionRelation(id, sentenceRelation);
        }
    }

    const updateRelation = (id: string, itemIds: any) => {
        // if (itemIds.length) {
        //     APIServices.updateSectionRelation(id, { itemIds });
        // }
    }

    const onSubmit = async (values: any) => {
        let { _id } = values;
        const {
            words, practices, checks, sentences, songs, videos, kisses, itemIds,
            ...sectionAttr
        } = values;
        if (_id) {
            dispatch(updateSection(currentLesson._id, _id, sectionAttr))
        } else {
            _id = (await APIServices.createSection(currentLesson._id, values)).data._id
        }

        createRelation(_id, 'Word', words);
        dealWithSentence(_id, {
            practices,
            checks
        })
        createRelation(_id, 'Media', [...songs, ...videos, ...kisses])

        updateRelation(_id, itemIds);

        onClose();
        refreshList(currentLesson._id);
    }

    const setPaginationParams = (paginationInfo: any) => {
        setPagination(paginationInfo);
        refreshList(currentLesson._id);
    };

    const getCurrentBook = (id: string) => {
        dispatch(selectBook(id))
    }

    const multiCreateSections = async () => {
        await APIServices.multiCreateSections(currentLesson._id);
        message.success('成功生成了五节课程');
        refreshList(currentLesson._id);
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: titleName.action,
            key: 'action',
            width: 200,
            fixed: 'right',
            render: (text: {}, formValues: any) =>
                <span>
                    <Button
                        icon={<EditOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.EDIT)}
                        onClick={() => showDrawer(formValues)}
                    ></Button>
                    <Button
                        icon={<DeleteOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.DELETE)}
                        onClick={() => {
                            confirm({
                                title: `Are you sure you want to delete ${formValues.title}?`,
                                icon: <ExclamationCircleOutlined />,
                                okType: 'danger',
                                onOk() {
                                    deleteList(formValues._id);
                                },
                            });
                        }}
                    >
                    </Button>
                </span>,
        },
    ];


    const uploadProps = {
        name: 'file',
        action: config.uploadXlsx,
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info: any) {
            if (info.file.status === 'done') {
                await APIServices.createMultiSectionContent({ excelData: info.file.response.data })
                message.success('批量创建课程资源成功');
                refreshList(currentLesson._id);
            } else if (info.file.status === 'error') {
                message.error('批量创建课程资源失败');
            }
        },
    };

    useEffect(() => {
        if (currentLesson) {
            refreshList(currentLesson._id);
        }
    }, [currentLesson, pagination.current, pagination.pageSize]);

    useEffect(() => {
        if (!allLesson.length) {
            dispatch(getAllLesson({ page: 1, pageSize: 20 }))
        }
    }, [allLesson]);

    useEffect(() => {
        if (!allWord.length) {
            dispatch(getAllSentence());
        }
    }, [allWord]);

    useEffect(() => {
        if (!allSentence.length) {
            dispatch(getAllWord())
        }
    }, [allSentence]);

    useEffect(() => {
        if (!allMedia.length) {
            dispatch(getAllMedia())
        }
    }, [allMedia]);
    return (
        <>
            {
                allLesson.length && currentLesson
                    ? <>
                        <div style={{ textAlign: 'left' }}>
                            <Button
                                icon={<PlusOutlined />}
                                onClick={multiCreateSections}
                                type='default'
                                className='plusIcon'
                            >一键生成五个课程</Button>
                            <Upload {...uploadProps}>
                                <Button icon={<UploadOutlined />}>批量上传课程资源</Button>
                            </Upload>
                        </div>
                        <div className='selectBook'>
                            <strong><FormattedMessage id='selectBook' /></strong>
                            <LessonMenu
                                defaultValue={currentLesson}
                                dataSources={allLesson}
                                getCurrentBook={getCurrentBook}
                            />
                        </div>
                        <Title><FormattedMessage id='sectionModuleName' /></Title>
                        <Button
                            shape='circle'
                            size='large'
                            icon={<PlusOutlined />}
                            onClick={showDrawer}
                            type='primary'
                            disabled={!userActions.includes(ACTION_KEY.CREATE)}
                            className='plusIcon'
                        >
                        </Button>
                        <Table
                            className='clientTable'
                            rowKey={(data: { _id: string }) => data._id}
                            columns={columns as Array<object>}
                            dataSource={lessonSections as Array<{ _id: string }>}
                            pagination={pagination}
                            onChange={paginationInfo => setPaginationParams(paginationInfo)}
                        />

                    </>
                    : <Empty />
            }
            {
                visible &&
                <DrawerForm
                    {...drawer}
                    visible
                    onClose={onClose}
                >
                    <IndexForm
                        onClose={onClose}
                        initialValues={drawer.initialValues}
                        onSubmit={onSubmit}
                    />
                </DrawerForm>
            }
        </>
    )
}

export default Section;
