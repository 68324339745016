import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Row, Input, DatePicker, Select } from 'antd';
import { UserProfileModel } from '../../../models/user';
import { AvatarModel } from '../../../models/course';
import moment from 'moment';
import UploadFile from '../../../components/basic/uploadFile';
import 'antd/dist/antd.css';

const { Option } = Select;

interface Props {
    userProfile: UserProfileModel
    edit: (values: UserProfileModel) => void
}

const UserBasicInfo = (props: Props) => {
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState(true);
    const onFinish = (values: any) => {
        values.user._id = props.userProfile.user._id;
        props.edit({
            ...props.userProfile,
            ...values,
        });
        setDisabled(true);
    };

    const cancel = () => {
        form.resetFields();
        setDisabled(true);
    };

    const uploadSuccess = (avatar: any) => {
        props.userProfile.avatar = avatar;
    };

    useEffect(() => {
        form.setFieldsValue(props.userProfile);
    }, [props.userProfile]);

    if (props.userProfile.birthday) {
        props.userProfile.birthday = moment(props.userProfile.birthday);
    }
    return (
        <div className='userInfo-card'>
            <UploadFile
                isEdit={!disabled}
                type={'avatar'}
                dataSources={props.userProfile.avatar}
                uploadSuccess={(file: AvatarModel) => uploadSuccess(file)}
            />
            <Form
                layout='vertical'
                hideRequiredMark
                initialValues={props.userProfile}
                form={form}
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name='nickname'
                            label='Nickname'
                        >
                            <Input disabled={disabled} placeholder='Please enter nickname' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name={['user', 'phone']}
                            label='Phone'>
                            <Input disabled placeholder='Please enter phone' />

                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name={['user', 'username']}
                            label='Username'>
                            <Input disabled placeholder='Please enter username' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name={['user', 'email']}
                            label='Email'>
                            <Input disabled placeholder='Please enter email' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name='gender'
                            label='Gender'
                        >
                            <Select disabled={disabled} className='sexOption' placeholder='Please enter gender'>
                                <Option value='male'>Man</Option>
                                <Option value='female'>Woman</Option>
                                <Option value='secret'>Secret</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name='birthday'
                            label='Birthday'
                        >
                            <DatePicker disabled={disabled} className='sexOption' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name='location'
                            label='Location'
                            rules={[{ required: true, message: 'Please enter location' }]}
                        >
                            <Input disabled={disabled} placeholder='Please enter location' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} className='btn'>
                        <Form.Item>
                            {
                                disabled
                                    ? <Button type='primary' onClick={() => setDisabled(false)}>Edit</Button>
                                    : <>
                                        <Button type='primary' onClick={cancel}>
                                            Cancel
                                        </Button>
                                        <Button type='primary' htmlType='submit' className='submit'>
                                            Update
                                        </Button>
                                    </>
                            }
                        </Form.Item>
                    </Col>
                </Row>
            </Form >
        </div >
    );
};

export default UserBasicInfo;
