import React, { MouseEvent } from 'react';
import { Table, Modal } from 'antd';
import { EyeOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import { OrderModel } from '../../../models/order';

const { confirm } = Modal;

interface Props {
    dataSource: OrderModel[]
    deleteList: (id: string) => void
    edit: (e: MouseEvent, data: OrderModel) => void
    goToDetail: (orderId: string) => void
    intl: any
}

interface State {
    sortedInfo: {
        columnKey: string
        order: string
    }
}

class OrderList extends React.Component<Props, State> {
    state = {
        sortedInfo: {
            columnKey: '',
            order: '',
        },
    }

    handleChange = (sorter: {
        columnKey: string
        order: string
    }) => {
        this.setState({
            sortedInfo: sorter,
        });
    };

    render() {
        const { sortedInfo } = this.state;
        const { intl } = this.props;
        // config table title
        const titleData = ['deleteTip', 'orderId', 'orderPrice', 'orderNumber', 'action'];
        const titleName: any = {};
        titleData.forEach((text: string) => titleName[text] = intl.formatMessage({ id: text }));
        const columns = [
            {
                title: titleName.orderId,
                dataIndex: 'orderCode',
                key: 'orderCode',
                sortOrder: sortedInfo.columnKey === 'orderCode' && sortedInfo.order,
            },
            {
                title: titleName.orderPrice,
                dataIndex: ['goodsInfo', 'goodsPrice'],
                key: 'goodsPrice',
                sortOrder: sortedInfo.columnKey === 'goodsPrice' && sortedInfo.order,
            },
            {
                title: titleName.orderNumber,
                dataIndex: ['goodsInfo', 'goodsNumber'],
                key: 'goodsNumber',
                sortOrder: sortedInfo.columnKey === 'goodsNumber' && sortedInfo.order,
            },
            {
                title: titleName.action,
                key: 'action',
                width: 200,
                fixed: 'right',
                render: (text: {}, formValues: OrderModel) =>
                    <span>
                        <EyeOutlined onClick={() => this.props.goToDetail(formValues._id)} />
                        <EditOutlined
                            className='left-margin'
                            onClick={(e: MouseEvent) => this.props.edit(e, formValues)}
                        />
                        {
                            formValues.payState > 2 && <DeleteOutlined
                                className='left-margin'
                                onClick={() => {
                                    const { props } = this;
                                    confirm({
                                        title: `${titleName.deleteTip} this order?`,
                                        icon: <ExclamationCircleOutlined />,
                                        okType: 'danger',
                                        onOk() {
                                            props.deleteList(formValues._id);
                                        },
                                    });
                                }}
                            />
                        }
                    </span>
                ,
            },
        ];

        return (
            <Table
                className='orderTable'
                rowKey={(data: { _id: string }) => data._id}
                columns={columns as Array<object>}
                dataSource={this.props.dataSource as Array<{ _id: string }>}
                onChange={() => this.handleChange({ columnKey: 'orderName', order: 'descend' })}
            />
        );
    }
}

export default injectIntl(OrderList);
