import React, { useEffect, useState } from 'react';
import { Typography, notification, Button, Modal, Table } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../redux/modules/course';

import DrawerForm from '../../components/basic/drawer';
import CartoonForm from './components/cartoonForm';
import { FormattedMessage, useIntl } from 'react-intl';
import 'antd/dist/antd.min.css';
import Common from '../../lib/common';
import CONSTANTS from '../../lib/constants';
import APIServices from '../../lib/APIServices';

const { ACTION_KEY } = CONSTANTS;
const { getAllCartoon } = actions;
const { Title } = Typography;
const { confirm } = Modal;
const { getPageTitleName } = Common;

interface Pagination {
    current: number
    pageSize: number
    showSizeChanger?: boolean
    total?: number
}
interface DefaultRootState {
    user: {
        userActions: Array<number>
    }
    course: {
        allCartoon: Array<any>
    }
}

interface DrawerInfo {
    // isCreate: boolean
    title: any,
    type: string
    initialValues?: any | undefined
}

interface Props {
    courseId: string
}

const WordView = (props: Props) => {
    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const [drawer, setDrawer] = useState<DrawerInfo>({
        // isCreate: true,
        title: '',
        type: '',
    });
    const [visible, setVisible] = useState<boolean>(false);
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();
    const { allCartoon, userActions } = useSelector((state: DefaultRootState) => ({
        allCartoon: state.course.allCartoon,
        userActions: state.user.userActions,
    }));

    console.log(allCartoon, ';;;;;;;;;;allCartoon')
    const dispatch = useDispatch();

    // config table title
    const titleData = ['lessonName', 'deleteTip', 'action'];
    const titleName = getPageTitleName(titleData, intl.formatMessage);

    const refreshList = () => {
        dispatch(getAllCartoon());
    };

    const showDrawer = (item?: { _id: string }) => {
        const data = item && item._id
            ? {
                title: 'Update Word',
                type: 'update',
                visible: true,
                initialValues: item,
            }
            : {
                title: 'Create Word',
                type: 'create',
                visible: true,
            };
        setDrawer(data);
        setVisible(true);
    };

    const deleteList = async (id: string) => {
        await APIServices.deleteCartoon(id);
        notification.success({
            message: 'Delete Success',
            description: '删除成功',
            placement: 'topRight',
        });
        refreshList();
    };

    const onClose = () => {
        setDrawer({ ...drawer, initialValues: '' });
        setVisible(false);
    };

    const onSubmit = async (values: any) => {
        if (values._id) {
            // dispatch(updateWord(values));
            await APIServices.updateCartoon(values);
        } else {
            // dispatch(createWord(values));
            await APIServices.createCartoon(values);
        }
        onClose();
        refreshList();
    };

    const setPaginationParams = (paginationInfo: any) => {
        setPagination(paginationInfo);
    };

    const columns = [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: '链接',
            dataIndex: 'link',
            key: 'link',
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            // eslint-disable-next-line react/display-name
            render: (text: {}, formValues: { title: string, _id: string }) =>
                <span>
                    <Button
                        icon={<EditOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.EDIT)}
                        onClick={() => showDrawer(formValues)}
                    ></Button>
                    <Button
                        icon={<DeleteOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.DELETE)}
                        onClick={() => {
                            confirm({
                                title: `Are you sure you want to delete ${formValues.title}?`,
                                icon: <ExclamationCircleOutlined />,
                                okType: 'danger',
                                onOk() {
                                    deleteList(formValues._id);
                                },
                            });
                        }}
                    >
                    </Button>
                </span>,
        },
    ];

    useEffect(() => {
        refreshList();
    }, [pagination.current, pagination.pageSize]);
    return (
        <div>
            <Title>Cartoon</Title>
            <Button
                shape='circle'
                size='large'
                icon={<PlusOutlined />}
                onClick={() => showDrawer()}
                type='primary'
                disabled={!userActions.includes(ACTION_KEY.CREATE)}
                className='plusIcon'
            >
            </Button>
            <Table
                rowKey={(data: { _id: string }) => data._id}
                columns={columns as Array<object>}
                dataSource={allCartoon as Array<{ _id: string }>}
                pagination={pagination}
                onChange={paginationInfo => setPaginationParams(paginationInfo)}
            />

            {
                visible &&
                <DrawerForm
                    {...drawer}
                    visible
                    onClose={onClose}
                >
                    <CartoonForm
                        onClose={onClose}
                        initialValues={drawer.initialValues}
                        onSubmit={onSubmit}
                    />
                </DrawerForm>
            }
        </div>
    );
};


export default WordView;
