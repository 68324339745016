import React from 'react';
import { Row, Col, Typography, Progress } from 'antd';
import APIServices from '../../../lib/APIServices';

import { ReportMenuModel, StudyProgressModel } from '../../../models/user';

const { Text, Title } = Typography;

interface Props {
    id: string
    reportMenu: Array<ReportMenuModel>
}
interface State {
    courseProgress?: Array<StudyProgressModel>
}
class StudyReport extends React.Component<Props, State> {
    state: State = {
        courseProgress: [],
    }

    componentWillMount = () => {
        APIServices.getStudyRecords(this.props.id)
            .then(res => {
                this.setState({ courseProgress: res.data });
            });
    }

    render() {
        const { reportMenu } = this.props;
        const { courseProgress } = this.state;
        return (
            <div>
                <Title level={3}>学习报告</Title>
                <div className='text-center'>
                    <Row className='pad-20'>
                        {
                            reportMenu && reportMenu.map(menu =>
                                <Col span={5} className='divider-style' offset={1} key={menu.title}>
                                    <Title level={4} className='text-white'>{menu.title}</Title>
                                    <Title level={4} className='text-white'>{menu.value}{menu.unit}</Title>
                                </Col>
                            )
                        }
                    </Row>
                </div>

                <div>
                    {/* <Title level={3}>学习进度</Title> */}
                    {/* {
            courseProgress && courseProgress.map(course => (
              <Row className='progress-list' key={course._id}>
                <Col span={2}>
                  <div className='progress-image'>
                    <img src={course.unit.cover} />
                  </div>
                </Col>
                <Col span={10}>
                  <Text className='font-18'>{course.unit.title}</Text >
                </Col>
                <Col span={11}>
                  <Progress percent={course.progress * 100} />
                </Col>
              </Row>
            ))
          } */}
                </div>
            </div>
        );
    }
}

export default StudyReport;
