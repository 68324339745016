import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, Input, Select } from 'antd';
import UploadFile from '../../../components/basic/uploadFile';
import { AvatarModel } from '../../../models/course';
import { MallModel } from '../../../models/mall';
import { FormattedMessage, useIntl } from 'react-intl';

import '../style/index.css';
import APIServices from '../../../lib/APIServices';
import { Store } from 'antd/lib/form/interface';

const { Option } = Select;

interface Props {
    initialValues?: any;
    onSubmit: (good: MallModel) => void;
    onClose: () => void;
}
const FormItem = (props: Props) => {
    const [form] = Form.useForm();
    const initFile = { _id: '', url: '' };
    const [groups, setGroups] = useState([]);
    const [coverImage, setCoverImage] = useState(initFile);
    const [thumbImage, setThumbImage] = useState(initFile);
    const [carouselImages, setCarouselImages] = useState([initFile]);
    const intl = useIntl();

    const titleData = [
        'goodTitle',
        'goodTitlePlaceholder',
        'commodityKind',
        'commodityKindPlaceholder',
        'commodityItem',
        'commodityItemPlaceholder',
        'actualPrice',
        'actualPricePlaceholder',
        'originalPrice',
        'originalPricePlaceholder',
        'goodImage',
        'bigImage',
        'smallImage',
        'coverImage',
        'summary',
        'summaryPlaceholder',
        'detail',
        'detailPlaceholder',
    ];

    const titleName: any = {};
    titleData.forEach((text: string) => (titleName[text] = intl.formatMessage({ id: text })));

    const onFinish = (values: any) => {
        if (props.initialValues) {
            values._id = props.initialValues._id;
        }

        if (coverImage.url || thumbImage.url) {
            coverImage.url && (values.coverImage = coverImage);
            thumbImage.url && (values.thumbImage = thumbImage._id);
        }
        carouselImages.length && carouselImages[0].url && (values.carouselImages = carouselImages);

        props.onSubmit(values);
    };

    if (props.initialValues && props.initialValues.carouselImages) {
        props.initialValues.carouselImages.forEach((item: any) => {
            item.uid = item._id;
        });
    }

    const getGroups = async () => {
        const newGroups = (await APIServices.getAllGroup()).data;
        setGroups(newGroups);
    };

    const onValuesChange = (changedValues: Store) => {
        if (changedValues.commodity && changedValues.commodity.kind) {
            switch (changedValues.commodity.kind) {
                case 'Group':
                    getGroups();
                    break;
                default:
                    setGroups([]);
                    form.setFieldsValue({ commodity: { item: '' } });
            }
        }
    };

    useEffect(() => {
        getGroups();
    }, []);

    return (
        <Form
            layout='vertical'
            hideRequiredMark
            initialValues={props.initialValues}
            form={form}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name={['commodity', 'kind']}
                        label={titleName.commodityKind}
                        rules={[{ required: true, message: 'Please enter kind of commodity' }]}
                    >
                        <Select placeholder={titleName.commodityKindPlaceholder}>
                            {['Group', 'Lesson'].map((kind: string, index: number) => {
                                return (
                                    <Option key={index} value={kind}>
                                        {kind}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['commodity', 'item']}
                        label={titleName.commodityItem}
                        rules={[{ required: true, message: 'Please enter item of commodity' }]}
                    >
                        <Select placeholder={titleName.commodityItemPlaceholder}>
                            {groups.map((group: { _id: string; title: string }) => {
                                return (
                                    <Option key={group._id} value={group._id}>
                                        {group.title}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name='title' label={titleName.goodTitle} rules={[{ required: true, message: 'Please enter good title' }]}>
                        <Input placeholder={titleName.goodTitlePlaceholder} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name='originalPrice'
                        label={titleName.originalPrice}
                        rules={[{ required: true, message: 'Please enter original Price' }]}
                    >
                        <Input placeholder={titleName.goodPricePlaceholder} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name='actualPrice'
                        label={titleName.actualPrice}
                        rules={[{ required: true, message: 'Please enter actual Price' }]}
                    >
                        <Input placeholder={titleName.goodPricePlaceholder} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label='Thumb Image' className='inlineItemLeft'>
                        <UploadFile
                            dataSources={props.initialValues && props.initialValues.thumbImage}
                            uploadSuccess={(file: AvatarModel) => setThumbImage(file)}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label='Cover Image' className='inlineItemRight'>
                        <UploadFile
                            dataSources={props.initialValues && props.initialValues.coverImage}
                            uploadSuccess={(file: AvatarModel) => setCoverImage(file)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item label='Carousel Images' className='inlineItemRight'>
                        <UploadFile
                            isEdit={true}
                            showUploadList={true}
                            dataSources={props.initialValues && props.initialValues.carouselImages}
                            uploadSuccess={(file: AvatarModel[]) => setCarouselImages(file)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item name='summary' label='Summary'>
                        <Input.TextArea rows={4} placeholder='please enter summary' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item name='description' label='Description'>
                        <Input.TextArea rows={4} placeholder='please enter description' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24} className='btn'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type='primary' htmlType='submit' className='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default FormItem;
