import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, Input, InputNumber } from 'antd';
import UploadFile from '../../../components/basic/uploadFile';
import { AvatarModel } from '../../../models/course';
import '../styles/index.css';
import APIServices from '../../../lib/APIServices';

interface Props {
    initialValues: any | undefined
    onSubmit: (user: any) => void
    onClose: () => void
}
const WordForm = (props: Props) => {
    const [form] = Form.useForm();
    const initFile = { _id: '', url: '' };
    const [cover, setCover] = useState(initFile);
    const [audio, setAudio] = useState(initFile);

    const onFinish = (values: any) => {
        values._id = props.initialValues && props.initialValues._id;
        // cover.url && (values.cover = cover);
        // audio.url && (values.audio = audio)

        props.onSubmit(values);
    };

    const onFieldsChange = async () => {
        if (props.initialValues && props.initialValues._id) {
            const newData = (await APIServices.getSentence(props.initialValues._id)).data;
            form.setFieldsValue(newData);
        } else {
            form.resetFields();
        }
    };

    useEffect(() => {
        onFieldsChange();
    }, []);
    return (
        <Form
            layout='vertical'
            // hideRequiredMark
            // initialValues={props.initialValues}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item
                        name='content'
                        label='Content'
                        rules={[{ required: true, message: 'Please enter the value of the sentence' }]}
                    >
                        <Input placeholder='Please enter the content of the sentence' />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name='order'
                        label='排序'
                    >
                        <Input type='number' placeholder='Please enter sentence plural' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item
                        name='translation'
                        label='译文'
                        rules={[{ required: false, message: 'Please enter the translation of the sentence' }]}
                    >
                        <Input placeholder='Please enter the translation of the sentence' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item
                        name='eikon'
                        label='插画 <1-1-A.png>'
                        rules={[{ required: false, message: 'Please enter the eikon of the sentence' }]}
                    >
                        <Input placeholder='Please enter the eikon of the sentence' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item
                        name='audio'
                        label='Audio <U1-1-B.mp3>'
                        rules={[{ required: false, message: 'Please enter the audio of the sentence' }]}
                    >
                        <Input name='value' placeholder='Please enter the audio of the sentence' />
                    </Form.Item>
                </Col>
            </Row>
            {/*   <Row gutter={16} hidden>
                <Col span={12}>
                    <Form.Item
                        name='audio'
                        label='Audio'
                        rules={[{ required: false, message: 'Please enter the audio of the word' }]}
                    >
                        <UploadFile
                            type='audio'
                            dataSources={props.initialValues && props.initialValues.audio}
                            uploadSuccess={(file: AvatarModel) => setAudio(file)}
                        />
                    </Form.Item>
                </Col>
            </Row> */}
            <Row gutter={16}>
                <Col span={24} className='btn'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type='primary' htmlType='submit' className='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default WordForm;
