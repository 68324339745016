import { createAction, handleActions } from 'redux-actions';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/constants';

const {
    ALL_ROLE,
    CREATE_ROLE,
    UPDATE_ROLE,
    DELETE_ROLE } = CONSTANTS;

// initialState
const initialState = {
    roles: [],
    currentRole: null,
};

interface Action {
    type: string
    payload: any
}

export default handleActions(
    {
        [ALL_ROLE](state, action: Action) {
            return {
                ...state,
                roles: action.payload,
                currentRole: state.currentRole || action.payload[0],
            };
        },
        [CREATE_ROLE](state) {
            return {
                ...state,
            };
        },
        [UPDATE_ROLE](state) {
            return {
                ...state,
            };
        },
        [DELETE_ROLE](state) {
            return {
                ...state,
            };
        },
    },
    initialState
);

/**
 * action creators
 * */
export const actions = {
    getRoles: createAction(ALL_ROLE, async (pagination: { page: number, pageSize: number }) => (await APIServices.getAllRole(pagination)).data),
    createRole: createAction(CREATE_ROLE, async (data: any) => (await APIServices.createRole(data)).data),
    updateRole: createAction(UPDATE_ROLE, async (data: any) => (await APIServices.updateRole(data)).data),
    deleteRole: createAction(DELETE_ROLE, async (roleId: string) => await APIServices.deleteRole(roleId)),
};
