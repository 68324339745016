import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, Input } from 'antd';
import { CourseModel, AvatarModel } from '../../../models/course';
import UploadFile from '../../../components/basic/uploadFile';
import DynamicTag from '../../../components/dynamicTag'
import '../styles/index.css';
import APIServices from '../../../lib/APIServices';
import { useDispatch, useSelector } from 'react-redux';
interface DefaultRootState {
    course: {
        words: Array<any>
        allSentence: Array<any>
    }
    media: {
        allMedia: Array<any>
        allSongs: Array<any>
        allVideos: Array<any>
        allKisses: Array<any>
    }
}
interface Props {
    initialValues: any | undefined
    onSubmit: (course: CourseModel) => void
    onClose: () => void
}

const FormItem = (props: Props) => {

    const [form] = Form.useForm();
    const [selectWords, setSelectWords] = useState<Array<any>>([]);
    const [selectWordsIds, setSelectWordsIds] = useState<Array<any>>([]);
    const [selectPractices, setSelectPractices] = useState<Array<any>>([]);
    const [selectPracticeIds, setSelectPracticesIds] = useState<Array<any>>([]);
    const [selectChecks, setSelectChecks] = useState<Array<any>>([]);
    const [selectCheckIds, setSelectCheckIds] = useState<Array<any>>([]);
    const [selectSongs, setSelectSongs] = useState<Array<any>>([]);
    const [selectSongsIds, setSelectSongsIds] = useState<Array<any>>([]);
    const [selectVideos, setSelectVideos] = useState<Array<any>>([]);
    const [selectVideosIds, setSelectVideosIds] = useState<Array<any>>([]);
    const [selectKisses, setSelectKisses] = useState<Array<any>>([]);
    const [selectKissIds, setSelectKissIds] = useState<Array<any>>([]);
    const { allWord, allSentence, allMedia, allSongs, allVideos, allKisses } = useSelector((state: DefaultRootState) => ({
        allWord: state.course.words,
        allSentence: state.course.allSentence,
        allMedia: state.media.allMedia,
        allSongs: state.media.allSongs,
        allVideos: state.media.allVideos,
        allKisses: state.media.allKisses,
    }));

    const onFinish = (values: any) => {
        if (props.initialValues) {
            values._id = props.initialValues._id;
        }

        props.onSubmit({
            ...values,
            words: selectWordsIds,
            checks: selectCheckIds,
            practices: selectPracticeIds,
            songs: selectSongsIds,
            videos: selectVideosIds,
            kisses: selectKissIds,
        });
    };

    const onFieldsChange = async () => {
        if (props.initialValues && props.initialValues._id) {
            const { words, sentences, songs, videos, kisses } = (await APIServices.getSectionContent(props.initialValues._id)).data;
            let practices: any = [],
                checks: any = [];

            if (sentences) {
                practices = sentences.filter((sentence: { targets: Array<number> }) => sentence.targets.includes(0))
                checks = sentences.filter((sentence: { targets: Array<number> }) => sentence.targets.includes(1))
            }

            setSelectWords(words)
            setSelectPractices(practices)
            setSelectChecks(checks)
            setSelectSongs(songs)
            setSelectVideos(videos)
            setSelectKisses(kisses)
            form.setFieldsValue({});
        } else {
            form.resetFields();
        }
    };

    useEffect(() => {
        onFieldsChange();
    }, [allWord, allSentence]);

    return (
        <Form
            layout='vertical'
            hideRequiredMark
            initialValues={props.initialValues}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name='title'
                        label='标题'
                    >
                        <Input placeholder='please enter title' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='order'
                        label='排序'
                    >
                        <Input type='number' placeholder='please enter title' />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name='words'
                        label='Words'
                        rules={[{ required: false, message: 'Please enter course title', type: 'array' },]}
                    >
                        <DynamicTag
                            title='Word'
                            selectOptions={selectWords}
                            onSelect={setSelectWordsIds}
                            allOptions={allWord}
                            type='word'
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name='practices'
                        label='练一练句子'
                        rules={[{ required: false, message: 'Please enter practice sentences', type: 'array' },]}
                    >
                        <DynamicTag
                            title='Practice'
                            selectOptions={selectPractices}
                            onSelect={setSelectPracticesIds}
                            allOptions={allSentence}
                            type='practice'
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name='checks'
                        label='测一测句子'
                        rules={[{ required: false, message: 'Please enter check sentences', type: 'array' },]}
                    >
                        <DynamicTag
                            title='Check'
                            selectOptions={selectChecks}
                            onSelect={setSelectCheckIds}
                            allOptions={allSentence}
                            type='check'
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name='songs'
                        label='CD 歌曲'
                        rules={[{ required: false, message: 'Please enter section songs', type: 'array' },]}
                    >
                        <DynamicTag
                            title='Songs'
                            selectOptions={selectSongs}
                            onSelect={setSelectSongsIds}
                            allOptions={allSongs}
                            type='songs'
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name='videos'
                        label='看一看视频'
                        rules={[{ required: false, message: 'Please enter section videos', type: 'array' },]}
                    >
                        <DynamicTag
                            title='Videos'
                            selectOptions={selectVideos}
                            onSelect={setSelectVideosIds}
                            allOptions={allVideos}
                            type='videos'
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name='kisses'
                        label='亲一亲视频'
                        rules={[{ required: false, message: 'Please enter section kisses', type: 'array' },]}
                    >
                        <DynamicTag
                            title='Kisses'
                            selectOptions={selectKisses}
                            onSelect={setSelectKissIds}
                            allOptions={allKisses}
                            type='kisses'
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24} className='btn'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type='primary' htmlType='submit' className='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default FormItem;
