import React from 'react';
import { Card, Row, Col, notification, Modal, Typography } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import APIServices from '../../../lib/APIServices';

import { UserOrderListModel } from '../../../models/user';

const { confirm } = Modal;
const { Text } = Typography;

interface Props {
    id: string
}

interface State {
    orderList: Array<UserOrderListModel>
}
class UerOrder extends React.Component<Props, State> {
    state: State = {
        orderList: [],
    }
    componentDidMount = () => {
        // get user order
        this.onGetUserOrders();
    }

    onGetUserOrders = () => {
        APIServices.getUserOrders(this.props.id)
            .then(res => {
                this.setState({ orderList: res.data });
            });
    }

    onDeleteOrder = (id: string) => {
        APIServices.deleteUserOrder(id);
        notification.success({
            message: 'Delete Success',
            description: '删除成功',
            placement: 'topRight',
        });
        // refresh
        this.onGetUserOrders();
    }

    render() {
        const orderStatusTip = ['待支付', '待收货', '支付失败', '退款中', '退款成功', '退款失败', '交易完成', '交易取消'];
        return (
            <div>
                {
                    this.state.orderList.length
                        ? <Card >
                            <Row className='order-list-head'>
                                <Col span={8}>订单详情</Col>
                                <Col span={4}>数量</Col>
                                <Col span={4}>价钱</Col>
                                <Col span={3}>状态</Col>
                                <Col span={5}>收货人</Col>
                            </Row>

                            {
                                this.state.orderList.map(order =>
                                    <Card
                                        key={order._id}
                                        className='map-top'
                                        type='inner'
                                        title={`订单号 : ${order.orderCode}`}
                                        hoverable
                                        extra={
                                            (order.orderState === 2 || order.orderState === 3)
                                            &&
                                            <DeleteOutlined className='order-delete' onClick={e => {
                                                e.stopPropagation();
                                                const { onDeleteOrder } = this;
                                                return confirm({
                                                    title: 'Are you sure you want to delete this order?',
                                                    icon: <ExclamationCircleOutlined />,
                                                    okType: 'danger',
                                                    onOk() {
                                                        onDeleteOrder(order._id);
                                                    },
                                                });
                                            }} />
                                        }
                                    >
                                        <Row className='order-list-body'>
                                            <Col span={2}>
                                                <div className='progress-image'>
                                                    <img src={order.goodsInfo.goodsImage.url} />
                                                </div>
                                            </Col>
                                            <Col span={6} className='text-left text-long-hidden'>{order.goodsInfo.goodsName}</Col>
                                            <Col span={4}>X{order.goodsInfo.goodsNumber}</Col>
                                            <Col span={4}>￥{order.goodsInfo.goodsPrice}</Col>
                                            <Col span={4}>{order.orderState === 3 && order.payState === 0 ? orderStatusTip[7] : orderStatusTip[order.payState]}</Col>
                                            <Col span={4}>{order.address.name}</Col>
                                        </Row>
                                    </Card>
                                )
                            }
                        </Card>
                        : <div className='text-center'><Text>亲，暂时没有查询到订单呦~</Text></div>
                }
            </div>
        );
    }
}

export default UerOrder;
