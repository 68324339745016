import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../redux/modules/menu';
import DrawerForm from '../../components/basic/drawer';
import MenuForm from './components/menuForm';
import { Typography, notification, Button, Table, Tag, Modal } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import APIServices from '../../lib/APIServices';
import { MenuModel } from '../../models/menu';
import CONSTANTS from '../../lib/constants';
import Common from '../../lib/common';
import './styles/index.css';


const { getPageTitleName } = Common;
const { ACTION_KEY } = CONSTANTS;
const { Title } = Typography;
const { confirm } = Modal;
const { getMenus, deleteMenu, updateMenu, getMenusData, getMenusDataByID } = actions;

interface Pagination {
    current: number
    pageSize: number
    showSizeChanger?: boolean
    total?: number
}
interface DefaultRootState {
    menu: {
        menus: Array<any>
        menuData: any
    }
    user: {
        userActions: Array<number>
    },
    auth: {
        loginUser: any
    }
}

interface DrawerInfo {
    isCreate: boolean
    title: any,
    type: string
    initialValues?: MenuModel
}

const Menu = () => {
    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const [drawer, setDrawer] = useState<DrawerInfo>({
        isCreate: true,
        title: '',
        type: '',
    });
    const [visible, setVisible] = useState<boolean>(false);
    const intl: { formatMessage: (id: { id: string }) => string } = useIntl();
    const { menus, menuData, userActions, userInfo } = useSelector((state: DefaultRootState) => ({
        menus: state.menu.menus,
        menuData: state.menu.menuData,
        userActions: state.user.userActions,
        userInfo: state.auth.loginUser,
    }));
    const dispatch = useDispatch();
    const titleData = ['deleteTip', 'menuName', 'menuIcon', 'menuVisible', 'createTime', 'action'];
    const titleName = getPageTitleName(titleData, intl.formatMessage);

    const refreshList = () => {
        if (userInfo) {
            dispatch(getMenusDataByID(userInfo._id));
        }
        dispatch(getMenus({ page: pagination.current, pageSize: pagination.pageSize }));
        dispatch(getMenusData());
    };

    const showDrawer = (item?: MenuModel) => {
        const data = item && item._id
            ? {
                title: <FormattedMessage id='updateMenu' />,
                type: 'update',
                initialValues: item,
                isCreate: false,
            }
            : {
                title: <FormattedMessage id='createMenu' />,
                type: 'create',
                isCreate: true,
            };
        setDrawer(data);
        setVisible(true);
    };

    const onClose = () => {
        setDrawer({ ...drawer, initialValues: undefined });
        setVisible(false);
    };

    const onSubmit = async (values: any, isCreate: boolean) => {
        // create menu and menu action resource
        if (!values.parentId) {
            delete values.parentId;
        }

        if (isCreate) {
            await APIServices.createMenu(values);
            notification.success({
                message: 'Create Success',
                placement: 'topRight',
            });
        } else {
            // update
            dispatch(updateMenu(values));
            notification.success({
                message: 'Update Success',
                placement: 'topRight',
            });
        }
        onClose();
        refreshList();
    };

    const deleteList = (id: string) => {
        dispatch(deleteMenu(id));
        notification.success({
            message: 'Delete Success',
            placement: 'topRight',
        });
        refreshList();
    };

    const setPaginationParams = (paginationInfo: any) => {
        setPagination(paginationInfo);
    };

    const columns = [
        {
            title: titleName.menuName,
            dataIndex: 'title',
            key: 'title',
        },
        {
            // title: titleName.menuIcon,
            // dataIndex: 'iconName',
            // key: 'iconName',
        },
        {
            title: titleName.menuVisible,
            dataIndex: 'menuDisplay',
            key: 'menuDisplay',
            // eslint-disable-next-line react/display-name
            render: (text: {}, formValues: MenuModel) =>
                <span>
                    {formValues.visible ? <Tag color='success'>显示</Tag> : <Tag color='error'>隐藏</Tag>}
                </span>
            ,
        },
        {
            title: titleName.createTime,
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: titleName.action,
            key: 'action',
            fixed: 'right',
            // eslint-disable-next-line react/display-name
            render: (text: {}, formValues: MenuModel) =>
                <span>
                    <Button
                        icon={<EditOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.EDIT)}
                        onClick={() => showDrawer(formValues)}
                    ></Button>
                    <Button
                        icon={<DeleteOutlined />}
                        type='text'
                        disabled={!userActions.includes(ACTION_KEY.DELETE)}
                        onClick={() => {
                            confirm({
                                title: `Are you sure you want to delete ${formValues.title}?`,
                                icon: <ExclamationCircleOutlined />,
                                okType: 'danger',
                                onOk() {
                                    deleteList(formValues._id);
                                },
                            });
                        }}
                    >
                    </Button>
                </span>,
        },
    ];

    useEffect(() => {
        refreshList();
    }, [pagination.current, pagination.pageSize]);
    return (
        <div>
            <Title><FormattedMessage id='menuModuleName' /></Title>
            <Button
                shape='circle'
                size='large'
                icon={<PlusOutlined />}
                onClick={() => showDrawer()}
                type='primary'
                disabled={!userActions.includes(ACTION_KEY.CREATE)}
                className='plusIcon'
            >
            </Button>
            <Table
                className='menuTable'
                rowKey={(data: { _id: string }) => data._id}
                columns={columns as Array<object>}
                dataSource={menus as Array<{ _id: string }>}
                pagination={pagination}
                onChange={paginationInfo => setPaginationParams(paginationInfo)}
            />
            {
                visible &&
                <DrawerForm
                    {...drawer}
                    visible
                    onClose={onClose}
                >
                    <MenuForm
                        onClose={onClose}
                        isCreate={drawer.isCreate}
                        initialValues={drawer.initialValues}
                        onSubmit={onSubmit}
                        menuData={menuData}
                    />
                </DrawerForm>
            }
        </div>
    );
};

export default Menu;
