import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, Input, InputNumber, Select } from 'antd';
import UploadFile from '../../../components/basic/uploadFile';
import { AvatarModel } from '../../../models/course';
import '../styles/index.css';

const { Option } = Select;
interface Props {
    initialValues: any | undefined;
    onSubmit: (user: any) => void;
    onClose: () => void;
}
const MediaForm = (props: Props) => {
    const [form] = Form.useForm();
    const { initialValues } = props;
    const [urlFormat, setUrlFormat] = useState('');
    const [format, setFormat] = useState(0);
    const onFinish = (values: any) => {
        if (initialValues._id) {
            values._id = initialValues._id;
        }
        props.onSubmit({ ...values });
    };

    const onFieldsChange = () => {
        if (initialValues && initialValues._id) {
            form.setFieldsValue({
                ...initialValues,
            });
        } else {
            form.resetFields();
        }
    };

    const onFormatChange = (value: number) => {
        setFormat(value);
        const formats = ['', 'MU1-1-Hi.wav', 'WU1-1-Hi.mp4', 'AU1-1-Hi.mp4'];
        setUrlFormat(formats[value]);
    };

    useEffect(() => {
        onFieldsChange();
        onFormatChange(initialValues?.type || 1);
    }, []);
    return (
        <Form
            layout='vertical'
            // hideRequiredMark
            // initialValues={initialValues}
            form={form}
            onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name='title' label='Title' rules={[{ required: true, message: 'Please enter the title of the media' }]}>
                        <Input placeholder='Please enter the title of the media' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='type' label='分类:' rules={[{ required: true, message: 'Please enter type' }]}>
                        <Select placeholder='请选择多媒体类型'>
                            <Option value={0}>朗诵</Option>
                            <Option value={1}>歌曲</Option>
                            <Option value={2}>看一看视频</Option>
                            <Option value={3}>亲一亲视频</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <div className='ant-form-item-label' style={{ width: '100%' }}>
                        <label htmlFor='url_format'>URL Format</label>
                    </div>
                    <Select value={format} style={{ width: '100%' }} id='url_format' onChange={onFormatChange}>
                        <Option value={1}>MU1-1-Hi.wav</Option>
                        <Option value={2}>WU1-1-Hi.mp4</Option>
                        <Option value={3}>AU1-1-Hi.mp4</Option>
                    </Select>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='content'
                        label={`URL <${urlFormat}>`}
                        rules={[{ required: false, message: 'Please enter the URL of the media' }]}
                    >
                        <Input placeholder='Please enter the URL of the media ' />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24} className='btn'>
                    <Form.Item>
                        <Button htmlType='button' onClick={props.onClose}>
                            Cancel
                        </Button>
                        <Button type='primary' htmlType='submit' className='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default MediaForm;
