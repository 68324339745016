import React from 'react';
import { NavLink } from 'react-router-dom';
import { AppstoreOutlined } from '@ant-design/icons';
import { MenuModel } from '../../models/menu';
import { Menu as AntMenu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
const { SubMenu } = AntMenu;
interface DefaultRootState {
    menu: {
        currentUserMenus: any
    }
}
const MenuTab = () => {
    const { menus } = useSelector((state: DefaultRootState) => ({
        menus: state.menu.currentUserMenus,
    }));
    const subMenus = (menuDatas: Array<MenuModel>) => menuDatas.map((data: MenuModel) => {
        if (data.children && data.children.length) {
            return (
                <SubMenu key={`sub${data.routeName}`} title={
                    <>
                        <AppstoreOutlined />
                        {/* <FormattedMessage id={data.title} /> */}
                        {data.title}
                    </>
                }>
                    {subMenus(data.children)}
                </SubMenu>
            );
        }
        return (
            <AntMenu.Item key={data.routeName} style={{ display: data.visible ? 'block' : 'none' }}>
                <NavLink to={data.routeName} />
                <AppstoreOutlined />
                <span className='nav-text'><FormattedMessage id={data.title} /></span>
            </AntMenu.Item>);
    });
    const getList = (menu: MenuModel) => {
        if (menu.children && menu.children.length) {
            return (
                <SubMenu key={menu.routeName}
                    style={{ display: menu.visible ? 'block' : 'none' }}
                    title={
                        <>
                            <AppstoreOutlined />
                            {/* <FormattedMessage id={menu.title} /> */}
                            {menu.title}
                        </>
                    }>
                    {subMenus(menu.children)}
                </SubMenu>
            );
        }
        return (
            <AntMenu.Item key={menu.routeName} style={{ display: menu.visible ? 'block' : 'none' }}>
                <NavLink to={menu.routeName} />
                <AppstoreOutlined />
                <span className='nav-text'>{menu.title}</span>
            </AntMenu.Item>
        );
    };
    const listItem = menus.map((menu: MenuModel) => getList(menu));

    return (
        <>
            {
                menus.length && <AntMenu
                    theme='dark'
                    mode='inline'
                    defaultSelectedKeys={[window.location.pathname]} >
                    {listItem}
                </AntMenu>
            }
        </>
    );
};

export default MenuTab;
