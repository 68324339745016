import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, Button } from 'antd';

import './styles/index.css';
import APIServices from '../../lib/APIServices';

const { TabPane } = Tabs;

interface IProps {
    userId: string
    currentLesson: any
}

const Records = ({ records }: { records: any[] }) => {
    const lowerScore = 90;

    return <Row className='recordList'>
        {
            records.length
                ? records.map((record: any, index: number) => {
                    const { sentenceAttr, wordAttr } = record.score;
                    return <Col span={12} key={index}>
                        <h1>--- 第{index + 1}次录音结果：{sentenceAttr.total_score} ---</h1>
                        <p style={{ color: 'blue' }}>
                            {
                                [
                                    '阅读状态：无语音或音量小',
                                    '阅读状态：乱说',
                                    '阅读状态：信噪比低',
                                    '阅读状态：截幅',
                                    '阅读状态：没有音频输入，请检测音频或录音设备是否正常'][
                                    ['28673', '28676', '28680', '28690', '28689'].indexOf(sentenceAttr.except_info)
                                ]
                            }
                        </p>
                        <p>
                            {
                                sentenceAttr.content.split(' ').map((word: string, index: number) => {
                                    const currentWord = wordAttr.find((wordAttrItem: any) => wordAttrItem.global_index === index.toString());
                                    let color = currentWord.serr_msg ? 'red' : currentWord.color;
                                    if (currentWord.serr_msg || currentWord.dp_message && ['16', '32', '64', '128'].includes(currentWord.dp_message)) {
                                        color = 'red';
                                    } else if (Number(currentWord.total_score) < lowerScore) {
                                        color = 'orange';
                                    } else {
                                        color = 'green';
                                    }

                                    return <span
                                        key={index}
                                        style={{ marginRight: '5px', color }}
                                    >
                                        {word}
                                    </span>;
                                })
                            }
                        </p>
                        {
                            wordAttr.map((word: any, index: number) => <React.Fragment key={index}>
                                {
                                    word.serr_msg && <p>{word.content}:<span style={{ color: 'red' }}>错读</span></p>
                                }
                                {
                                    word.dp_message && ['16', '32', '64', '128'].includes(word.dp_message) &&
                                        <p>
                                            {word.content}:
                                            <span style={{ color: 'red' }}>
                                                {
                                                    ['漏读', '增读', '回读', '替换'][
                                                        ['16', '32', '64', '128'].indexOf(word.dp_message)
                                                    ]
                                                }
                                            </span>
                                        </p>
                                }
                            </React.Fragment>)
                        }
                    </Col>;
                })
                : <div>还没有录音</div>
        }
    </Row>;
};

const SectionList = (props: IProps) => {
    const [sections, setSections] = useState([]);
    const [sentences, setSentences] = useState([]);
    const [records, setRecords] = useState([]);
    const [showRecordIndex, setShowRecordIndex] = useState(-1);

    const getSections = async (lesson: { title: string, _id: string }) => {
        const newSections = await (await APIServices.getLessonSections(lesson._id)).data;
        setSections(newSections);
        if (newSections[0]) {
            getSectionContent(newSections[0]._id);
        }
    };

    const getRecords = async (sectionContentId: string, index: number) => {
        setRecords([]);
        setShowRecordIndex(index);
        const newRecords = await (await APIServices.getStudyRecords(sectionContentId, props.userId)).data;
        setRecords(newRecords);
    };

    const getSectionContent = async (sectionId: string) => {
        setRecords([]);
        setShowRecordIndex(-1);
        const newSectionContent = await (await APIServices.getSectionContent(sectionId)).data;
        setSentences(newSectionContent.sentences);
    };

    useEffect(() => {
        if (props.currentLesson._id) {
            getSections(props.currentLesson);
        }
    }, [props.currentLesson]);

    return (
        <div className='rightCard'>
            <h1>Lesson:《{props.currentLesson.title}》</h1>
            <Tabs
                defaultActiveKey={sections[0]}
                onChange={getSectionContent}
            >
                {
                    sections.map((section: any, index: number) => <TabPane tab={`Section: ${section.title}`} key={section._id}>
                        {
                            sentences.map((sentence: any, index: number) => <React.Fragment key={sentence._id}>
                                <div>{sentence.content}</div>
                                <Button style={{ margin: '10px 0' }} onClick={() => getRecords(sentence.sectionContentId, index)}>获取录音</Button>
                                {
                                    showRecordIndex === index && <Records records={records} />
                                }
                            </React.Fragment>)
                        }
                    </TabPane>)
                }
            </Tabs>
        </div >
    );
};

export default SectionList;
