import React, { Component } from 'react';
import 'antd/dist/antd.min.css'; // or 'antd/dist/antd.less'
import { connect } from 'react-redux';
import { actions } from '../../redux/modules/menu';
import { actions as authActions } from '../../redux/modules/auth';
import { actions as userActions } from '../../redux/modules/user';
import { Layout, Avatar, Dropdown, Menu, ConfigProvider, Select } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import MenuTab from '../menu/menuTab';
import { NavLink, Redirect } from 'react-router-dom';
import './styles/index.css';
import config from '../../config/config';

import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import { IntlProvider, FormattedMessage } from 'react-intl';
import zh from '../../config/locale/zh';
import en from '../../config/locale/en';
import Common from '../../lib/common';
const { Option } = Select;

const { Header, Content, Sider } = Layout;
// const reduxStore = configureStore();
const { getLocalLanguage } = Common;
const localeLanguage = getLocalLanguage();
const { getMenusData, getMenusDataByID } = actions;
const { getUserDetail } = authActions;
const { getUserActions } = userActions;
interface Props {
    history?: any
    children: any
    menus: Array<any>
    loginUser: object
    getMenusData: () => void
    getUserDetail: (id: string) => void
    getUserActions: (id: string) => void
    getMenusDataByID: (id: string) => void
}
interface State {
    nickname: string
    userAvatar: string
    userId: string
    locale: string
    localeMessage: Record<string, string>
    componentLocale: any
}

const logout = () => {
    localStorage.clear();
};
const mapStateToProps = (state: any) => ({
    menus: state.menu.menus,
    loginUser: state.auth.loginUser,
});

const mapDispatchToProps = {
    getMenusData,
    getUserDetail,
    getUserActions,
    getMenusDataByID,
};
const MenuOption = (props: { userId: string }) =>
    <Menu className='userAvatarMenu'>
        {/* <Menu.Item key='/home/setting'>
            <NavLink to={`/home/user/${props.userId}`} />
            <UserOutlined />
            <FormattedMessage id='setting' />

        </Menu.Item> */}
        <Menu.Divider />
        <Menu.Item key='2' onClick={logout}>
            <NavLink to={'/login'} />
            <LogoutOutlined />
            <FormattedMessage id='logout' />
        </Menu.Item>
    </Menu>;

class Home extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            nickname: '',
            userAvatar: '',
            userId: '',
            locale: localeLanguage,
            localeMessage: localeLanguage === 'en' ? en : zh,
            componentLocale: localeLanguage === 'en' ? enUS : zhCN,
        };
    }

    componentDidMount = () => {
        const loginUser: any = localStorage.getItem('loginUser');

        if (!JSON.parse(loginUser)) {
            localStorage.clear();
            return <Redirect to='/login' />;
        } else {
            const { age: isLogin } = JSON.parse(loginUser);
            if (isLogin) {
                this.props.getMenusData();
                const profile = JSON.parse(loginUser as string);
                this.props.getUserDetail(profile._id);
                this.props.getUserActions(profile._id);
                this.props.getMenusDataByID(profile._id);
                if (profile) {
                    this.setState({
                        userId: profile._id,
                        nickname: profile.nickname || profile.email,
                        userAvatar: profile.avatar ? profile.avatar.url : config.avatarUrl,
                    });
                }
            } else {
                return <Redirect push to='/login' />;
            }
        }
    };

    changeLocale = (locale: any) => {
        if (locale === 'en') {
            this.setState({
                locale,
                localeMessage: en,
                componentLocale: enUS,
            });
        } else {
            this.setState({
                locale,
                localeMessage: zh,
                componentLocale: zhCN,
            });
        }
    };

    render() {
        const { locale, localeMessage, componentLocale } = this.state;
        return (
            <IntlProvider locale={locale} messages={localeMessage} >
                <ConfigProvider locale={componentLocale}>
                    <Layout className='site-layout'>
                        <Sider>
                            <div className='siteName'>
                                <FormattedMessage id='webName' />
                            </div>
                            <div style={{ width: 200 }}>
                                <MenuTab />
                            </div>
                        </Sider>
                        <Layout>
                            <Header>
                                <div className='right'>
                                    <Select defaultValue={locale} style={{ width: 120, visibility: 'hidden' }} onChange={this.changeLocale} >
                                        <Option value='en'>English</Option>
                                        <Option value='cn'>中文</Option>
                                    </Select>
                                    <Dropdown overlay={<MenuOption userId={this.state.userId} />} trigger={['click']}>
                                        <div>
                                            <Avatar src={this.state.userAvatar} size={30} />
                                            <a className='userName'>{this.state.nickname}</a>
                                        </div>
                                    </Dropdown>
                                </div>
                            </Header>
                            <Content className='content'>
                                {this.props.children}
                            </Content>
                            {/* <Footer>Ant Design ©2018 Created by Ant UED</Footer> */}
                        </Layout>
                    </Layout>

                </ConfigProvider>
            </IntlProvider>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
