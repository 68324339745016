import Lesson from '../pages/course/lesson';
import Section from '../pages/course';
import SectionDetail from '../pages/course/detail';
import Word from '../pages/word';
import Name from '../pages/name';
import Sentence from '../pages/sentence';
import Cartoon from '../pages/cartoon';
import Mall from '../pages/mall';
import Client from '../pages/client';
import Order from '../pages/order';
import OrderDetail from '../pages/order/detail';
import Role from '../pages/role';
import Menu from '../pages/menu';
import User from '../pages/user';
import UserDetail from '../pages/user/detail';
import NewUser from '../pages/newUser';
import NewUserDetail from '../pages/newUser/detail';
import Action from '../pages/action';
import Resource from '../pages/resource';
import Group from '../pages/group';
import Media from '../pages/media';
import Login from '../pages/auth/views/login';
import Register from '../pages/auth/views/register';
import ResultPage from '../components/basic/result';
import File from '../pages/file';
import Stats from '../pages/stats';

import { Route } from '../models/router';
const routerData: Array<Route> = [
    { path: '/home/lesson', component: Lesson, auth: true },
    { path: '/home/section', component: Section, auth: true },
    { path: '/home/section/:sectionId', component: SectionDetail, secondary: true },
    { path: '/home/word', component: Word, auth: true },
    { path: '/home/name', component: Name, auth: true },
    { path: '/home/sentence', component: Sentence, auth: true },
    { path: '/home/cartoon', component: Cartoon, auth: true },
    { path: '/home/client', component: Client, auth: true },
    { path: '/home/order', component: Order, auth: true },
    { path: '/home/order/:orderId', component: OrderDetail, secondary: true },
    { path: '/home/user', component: User, auth: true },
    { path: '/home/user/:userId', component: UserDetail, secondary: true },
    { path: '/home/mall', component: Mall, auth: true },
    { path: '/home/role', component: Role, auth: true },
    { path: '/home/menu', component: Menu, auth: true },
    { path: '/home/newUser', component: NewUser, auth: true },
    { path: '/home/newUser/:newUserId', component: NewUserDetail, secondary: true },
    { path: '/home/action', component: Action, auth: true },
    { path: '/home/resource', component: Resource, auth: true },
    { path: '/home/group', component: Group, auth: true },
    { path: '/home/media', component: Media, auth: true },
    { path: '/home/files', component: File, auth: true },
    { path: '/home/stats', component: Stats, auth: true },
    { path: '/login', component: Login },
    { path: '/register', component: Register },
    { path: '/home/404', component: ResultPage },
];

export default routerData;
