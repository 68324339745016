import { createAction, handleActions } from 'redux-actions';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/constants';

import { AuthModel } from '../../models/auth';

const { GET_USERDATA, SET_USERDATA, PHONE_SIGNUP, LOGIN, GET_USER_DETAIL } = CONSTANTS;

// initialState
const initialState = {
    userInfo: '',
    loginUser: '',
};

interface Action {
    type: string
    payload?: any
}

type State = typeof initialState

export default handleActions(
    {
        [GET_USERDATA](state: State, action: Action) {
            return {
                ...state,
                userInfo: action.payload,
            };
        },
        [GET_USER_DETAIL](state: State, action: Action) {
            return {
                ...state,
                loginUser: action.payload,
            };
        },
        [SET_USERDATA](state: State, action: Action) {
            return {
                ...state,
                userInfo: action.payload,
            };
        },
        [PHONE_SIGNUP](state: State, action: Action) {
            return {
                ...state,
                userInfo: action.payload,
            };
        },
        [LOGIN](state: State, action: Action) {
            return {
                ...state,
                userInfo: action.payload,
            };
        },
    },
    initialState
);

export const actions = {
    getUserData: createAction(GET_USERDATA, async (userId: string) => {
        const result = await APIServices.getUserProfile(userId);
        const user = result.data;
        if (user.user._id) {
            localStorage.setItem('user', JSON.stringify(user));
        }
        return user;
    }),
    getUserDetail: createAction(GET_USER_DETAIL, async (userId: string) => {
        const loginUser = await APIServices.getNewUserDetail(userId) || {};
        if (loginUser) {
            const { data } = loginUser;
            localStorage.setItem('loginUser', JSON.stringify(data));
            return data;
        }
        return loginUser;
    }),
    setUserInfo: createAction(SET_USERDATA),
    phoneSignUp: createAction(PHONE_SIGNUP, async (data: object) => {
        const result = await APIServices.phoneSignUp(data);
        return result.data;
    }),
    userSignIn: createAction(LOGIN, async (user: AuthModel) => {
        const result = await APIServices.userSignIn(user);

        const { data } = result;

        if (data && data.userId) {
            localStorage.setItem('accessToken', data.accessToken);
            const userInfo = (await APIServices.getNewUserDetail(data.userId)).data;
            localStorage.setItem('loginUser', JSON.stringify(userInfo));
        }
        return data;
    }),
};
