import React from 'react';
import 'antd/dist/antd.min.css'; // or 'antd/dist/antd.less'
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from '../auth/views/login';
import Register from '../auth/views/register';
import Home from './home';
import LoadingView from '../../components/message/loading';
import Routes from '../../config/Routes';
import '../../App.css';

interface DefaultRootState {
    global: {
        loadingState: boolean
    }
}

const Layout = () => {
    const { loadingState } = useSelector((state: DefaultRootState) => ({
        loadingState: state.global.loadingState,
    }));

    return (
        <BrowserRouter>
            <LoadingView loadingState={loadingState} />
            <Route exact path={'/'} render={() => <Redirect to={'/home'} />} />
            <Route path='/home' render={() =>
                <Home>
                    <Routes />
                </Home>
            } />
            <Route key={'/register'} exact path='/register' component={Register} />
            <Route key={'/login'} exact path={'/login'} component={Login} />
        </BrowserRouter>
    );
};

export default Layout;
