import React from 'react';
import { Card, Col, Row, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { WordModel, EditWord } from '../../../models/course';
const { confirm } = Modal;
const { Meta } = Card;

interface Props {
    dataSource: EditWord[]
    deleteList: (id: string) => void
    edit: (item: object) => void
}

const WordList = (props: Props) =>
    <div className='site-card-wrapper'>
        <Row gutter={16}>{
            props.dataSource.map((item: EditWord) =>
                <Col span={5} key={item._id}>
                    <Card
                        hoverable
                        className='cardCover'
                        cover={<img alt='Word Cover' src='http://erealm-mmmy.oss-cn-chengdu.aliyuncs.com/198d1ab3-e90e-4f8c-b0e8-5cd1e79e3d68.jpg' />}
                        actions={[
                            <EditOutlined
                                onClick={() => props.edit(item)}
                            />,
                            <DeleteOutlined
                                onClick={() => confirm({
                                    title: 'Are you sure you want to delete this word?',
                                    icon: <ExclamationCircleOutlined />,
                                    okType: 'danger',
                                    onOk() {
                                        props.deleteList(item._id);
                                    },
                                })}
                            />,
                        ]}
                    >
                        <Meta title={item.value} />
                    </Card>
                </Col>
            )
        }</Row>
    </div >;


export default WordList;
