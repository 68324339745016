import React from 'react';
import { Route, Redirect } from 'react-router-dom';

interface Props {
    routerConfig: any
    location?: any
}

const FrontendAuth = (props: Props) => {
    const { routerConfig, location } = props;
    const { pathname } = location;
    const loginUser: any = localStorage.getItem('loginUser');
    if (loginUser === 'undefined' || loginUser === '‘’' || loginUser === '' || !JSON.parse(loginUser)) {
        localStorage.clear();
        return <Redirect to='/login' />;
    } else {
        const { age: isLogin } = JSON.parse(loginUser);
        // 如果该路由不用进行权限校验，登录状态下登陆页除外
        // 因为登陆后，无法跳转到登陆页
        // 这部分代码，是为了在非登陆状态下，访问不需要权限校验的路由
        const targetRouterConfig = routerConfig.find((item: any) => {
            if (item.path === pathname) {
                return item;
            } else if (item.secondary) {
                // 二级路由
                const newPath = item.path.split(':');
                if (pathname.indexOf(newPath[0]) === 0) {
                    return item;
                }
            }
        });

        // not auth
        if (targetRouterConfig && !targetRouterConfig.auth && !isLogin) {
            const { component, path } = targetRouterConfig;
            return <Route exact path={path} component={component} />;
        }

        if (isLogin) {
            // 如果是登陆状态，想要跳转到登陆，重定向到主页
            if (pathname === '/login') {
                return <Redirect to='/login' />;
            } else {
                // 如果路由合法，就跳转到相应的路由
                if (targetRouterConfig) {
                    return (
                        <Route exact path={targetRouterConfig.path} component={targetRouterConfig.component} />
                    );
                } else {
                    // 如果路由不合法，重定向到 404 页面
                    return <Redirect to='/home/404' />;
                }
            }
        } else {
            // 非登陆状态下，当路由合法时且需要权限校验时，跳转到登陆页面，要求登陆
            if (targetRouterConfig && targetRouterConfig.auth) {
                localStorage.clear();
                return <Redirect to='/login' />;
            } else {
                // 非登陆状态下，路由不合法时，重定向至 404
                return <Redirect to='/home/404' />;
            }
        }
    }
};

export default FrontendAuth;
