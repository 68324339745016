import React from 'react';
import { Tabs } from 'antd';
import StudyReport from './studyReport';
import UerOrder from './userOrder';
import UserAddress from './userAddress';
import { UserStudyTimeModel, ReportMenuModel } from '../../../models/user';

const { TabPane } = Tabs;

interface Props {
    id: string
    userStudyTime: UserStudyTimeModel
}

const UserDetailInfo = (props: Props) => {
    const { userStudyTime, id: userId } = props;
    const reportMenu: Array<ReportMenuModel> = [{
        index: 1,
        title: '加入天数',
        value: userStudyTime.learningDay,
        unit: ' 天',
    }, {
        index: 2,
        title: '已学课程',
        value: userStudyTime.studiedCourse,
        unit: ' 课',
    }, {
        index: 3,
        title: '完成跟读',
        value: userStudyTime.completedRead,
        unit: ' 次',
    }, {
        index: 4,
        title: '学习时长',
        value: userStudyTime.learningTime,
        unit: ' 分钟',
    }];
    return (
        <Tabs className='rightCard' defaultActiveKey='1'>
            <TabPane tab='学习报告' key='1'>
                <StudyReport id={userId} reportMenu={reportMenu} />
            </TabPane>
            <TabPane tab='我的订单' key='2'>
                <UerOrder id={userId} />
            </TabPane>
            <TabPane tab='我的地址' key='3'>
                <UserAddress userId={userId} />
            </TabPane>
        </Tabs>
    );
};

export default UserDetailInfo;
