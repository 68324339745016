import React from 'react';
import { Drawer } from 'antd';

interface Props {
    title?: string
    // eslint-disable-next-line no-undef
    children?: JSX.Element
    onClose: () => void
    visible?: boolean
}

const DrawerForm = (props: Props) =>
    <div style={{ display: 'inline-block' }}>
        <Drawer
            title={props.title}
            width={'50%'}
            onClose={props.onClose}
            visible={props.visible}
            bodyStyle={{ paddingBottom: 80 }}
        >
            {props.children}
        </Drawer>
    </div>;


export default DrawerForm;
